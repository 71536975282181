import React from 'react'
import Silder from './Silder'

const Upi = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards10.png")} alt="" /></span>
                                <span className='Main_Title'>UPI QR Collection</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    Now you can take payment with QR code <br />
                                    Digital Gramin Seva is giving you the facility of UPI QR Code <br />
                                    Payment received through QR code will be credited to your wallet instantly
                                </p>

                                <h2> What is UPI? </h2>
                                <p>
                                    Unified Payments Interface (UPI) is a system that powers multiple bank accounts into a single mobile application (of any participating bank), merging several banking features, seamless fund routing & merchant payments into one hood. It also caters to the “Peer to Peer” collect request which can be scheduled and paid as per requirement and convenience.
                                </p>

                                <h2> Benefits </h2>
                                <ul>
                                    <li> Immediate money transfer through mobile device round the clock 24*7 and 365 days. </li>
                                    <li> Single mobile application for accessing different bank accounts. </li>
                                    <li> Single Click 2 Factor Authentication – Aligned with the Regulatory guidelines, yet provides for a very strong feature of seamless single click payment. </li>
                                    <li> Virtual address of the customer for Pull & Push provides for incremental security with the customer not required to enter the details such as Card no, Account number; IFSC etc. </li>
                                    <li> QR Code </li>
                                    <li> Best answer to Cash on Delivery hassle, running to an ATM or rendering exact amount. </li>
                                    <li> Merchant Payment with Single Application or In-App Payments. </li>
                                    <li> Utility Bill Payments, Over the Counter Payments, QR Code (Scan and Pay) based payments. </li>
                                    <li> Donations, Collections, Disbursements Scalable. </li>
                                    <li> Raising Complaint from Mobile App directly. </li>
                                </ul>

                                <h2> UPI Benefits For Merchant </h2>
                                <ul>
                                    <li> Seamless fund collection from customers - single identifiers </li>
                                    <li> No risk of storing customer’s virtual address like in Cards </li>
                                    <li> Tap customers not having credit/debit cards </li>
                                    <li> Suitable for e-Com & m-Com transaction </li>
                                    <li> Resolves the COD collection problem </li>
                                    <li> Single click 2FA facility to the customer - seamless Pull </li>
                                    <li> In-App Payments (IAP) </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default Upi