import React from 'react'
import "./ApplyForFranchise.css"

const ApplyForFranchise = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 d-none d-sm-block" >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active  " style={{ backgroundImage: `url(${require("../Img/Apply1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', height: "500px" }}>
                            </div>

                            <div className="carousel-item   " style={{ backgroundImage: `url(${require("../Img/ApplyForFraSILDER2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "500px" }}>
                            </div>

                            <div className="carousel-item  " style={{ backgroundImage: `url(${require("../Img/ApplyForFraSILDER3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "500px" }}>
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon  " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                            <span className="carousel-control-next-icon " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>

            <section className="digiGram-sect">
                <div className="container Open-Sans-Serif ">
                    <h1 className="Ftitle">Digital Gramin Seva Franchise</h1>
                    <p>
                        Are you worried or wondering about handling your expenses on a daily
                        basis or want a way to increase your income in the least amount of
                        time? So, if the answer is yes, then come join us, we will tell you
                        what you need to do to make your dreams come true.
                    </p>
                    <p>
                        The best thing about this golden opportunity is that it has no
                        criteria, limits, or restrictions, neither of education, nor of age,
                        nor of caste.
                    </p>
                    <h2 className="Ftitle">So what could be better for all of us?</h2>
                    <p>
                        Do you want to avail such an opportunity with less investment and
                        more profit by applying for Digital Gramin Seva Franchise?
                    </p>
                    <p>
                        Anyone can join us and take Digital Seva Franchise to start their
                        own business and be their own boss.
                    </p>
                    <p>
                        Your family will grow only if you grow and we will make all your
                        dreams come true which you and your family have seen.
                    </p>
                    <p>
                        For that, first of all, we want to tell you how Digital Gramin Seva
                        franchisee, how works.
                    </p>
                    <p>
                        Which services are important, and how you can increase your income
                        by joining them? Digital Gramin Seva Franchise Model, is one such
                        online platform that provides more than 50 services and is one of
                        the very trusted companies in India.
                    </p>
                    <p>
                        We give you this opportunity to join us and provide digital services
                        to people around you and make your dream of Digital India come true
                        along with your business income to help you all to move forward.
                    </p>
                </div>
            </section>

            <section className="youWGet-sect">
                <div className="container Open-Sans-Serif ">
                    <div className="YWG-card">
                        <h1 className="Ftitle">You Will Get</h1>
                        <ul className="YWG-list">
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; DGS ID
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; IRCTC Agent ID
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; Templates
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; Franchise Certificate
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; IRCTC ID
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; Mini ATM
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; Franchise Aggrement
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; ID Card
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; DGS T-Shirt
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; Mantra MFS 100
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; DGS Register
                            </li>
                            <li>
                                <i
                                    className="fa-solid fa-star"
                                    style={{ color: "#ff6700", fontSize: "15px" }}
                                ></i>{" "}
                                &nbsp; DGS Wall Clock
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="services-sect">
                <div className="container Open-Sans-Serif ">
                    <h2>Services:</h2>
                    <p>
                        In Franchise Program, You Will Get All Digital Gramin Seva Services
                        Access
                    </p>

                    <div className="services-row franchise row ">
                        <div
                            className="services-card franchise-card col-lg-3 col-md-6"
                            style={{ backgroundColor: "#ff6700" }}
                        >
                            <img
                                src={require("../Img/2col1.png")}
                                alt={"banking_service"}
                            />
                            <h4>
                                Banking <br /> Services
                            </h4>
                        </div>
                        <div className="services-card franchise-card text-dark col-lg-3 col-md-6">
                            <img
                                src={require("../Img/2col2.png")}
                                alt={"billPay_service"}
                            />
                            <h4>
                                Bill <br />
                                Payment
                                <br /> Services
                            </h4>
                        </div>
                        <div
                            className="services-card franchise-card col-lg-3 col-md-6"
                            style={{ backgroundColor: "#10a5ff" }}
                        >
                            <img
                                src={require("../Img/2col3.png")}
                                alt={"banking_service"}
                            />
                            <h4>
                                Government <br /> Services
                            </h4>
                        </div>
                        <div className="services-card franchise-card text-dark col-lg-3 col-md-6">
                            <img
                                src={require("../Img/2col4.png")}
                                alt={"banking_service"}
                            />
                            <h4>
                                Company <br /> Registration
                            </h4>
                        </div>
                        <div className="services-card franchise-card text-dark col-lg-3 col-md-6">
                            <img
                                src={require("../Img/2col5.png")}
                                alt={"banking_service"}
                            />
                            <h4>
                                Tax Filing <br />
                                Services
                            </h4>
                        </div>
                        <div
                            className="services-card franchise-card col-lg-3 col-md-6"
                            style={{ backgroundColor: "#00a537" }}
                        >
                            <img
                                src={require("../Img/2col6.png")}
                                alt={"banking_service"}
                            />
                            <h4>
                                Travel Booking <br /> Services
                            </h4>
                        </div>
                        <div className="services-card franchise-card text-dark col-lg-3 col-md-6">
                            <img
                                src={require("../Img/2col7.png")}
                                alt={"banking_service"}
                            />
                            <h4>
                                <span>
                                    <img
                                        className="new-tag"
                                        src={require("../Img/new.png")}
                                        alt="new-tag"
                                    />
                                </span>{" "}
                                <br />
                                Loan <br /> Services
                            </h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="best-section">
                <div className="container Open-Sans-Serif ">
                    <h2>Best Digital Gramin Seva Franchisee Services</h2>
                    <p>
                        We have a wide range of digital services including banking services
                        which include:-
                    </p>
                    <p>
                        AEPS, Aadhaar Payments, Cash Deposit, Money Transfer, Loan
                        Application Services, and Account Opening Services. We also
                        specialize in providing B2C services like Mobile Recharge, DTH
                        Recharge, Electricity, Water, Gas Bill Payment, FASTag, etc. Various
                        G2C Government Services like Aadhaar Updates, Passports, New Voter
                        ID Card, Ration Card, Driving License, Flight Booking, etc., bus,
                        train, and also hotel accommodations.
                    </p>
                    <p>
                        <b>AEPS (Aadhaar-Enabled Payment System)-</b>
                        Digital Gramin Seva helps in carrying out banking transactions using
                        Aadhaar numbers for cash withdrawals, balance inquiries, money
                        transfers, etc.
                    </p>
                    <p>
                        <b>Aadhaar Payments-</b>
                        We offer payments by linking Aadhaar to a bank account, with secure
                        transactions through biometric authentication.
                    </p>
                    <p>
                        <b>Money Transfer-</b>
                        We can easily, securely, and instantly transfer funds from one
                        account to another online.
                    </p>
                    <p>
                        <b>Account Opening-</b>
                        Opening new bank accounts is easy and simple with hassle-free
                        procedures.
                    </p>
                    <p>
                        <b>Mini ATM-</b>
                        We provide Aadhaar card-enabled micro-ATM services to offer basic
                        banking transactions of cash withdrawal and balance inquiry.
                    </p>
                    <p>
                        <b>Cash Deposit-</b>
                        It helps in depositing cash in bank accounts through specified
                        channels.
                    </p>
                    <p>
                        <b>Recharge Services-</b>
                        We provide mobile recharge services, allowing users to top up their
                        prepaid mobile phone credit and get instant recharge payment
                        options.
                    </p>
                    <p>
                        We also offer users instant recharge options and subscription plans
                        for DTH (Direct-to-Home) services to watch and enjoy uninterrupted
                        entertainment sitting at home without the need to visit stores
                    </p>
                    <p>
                        <b>LIC Payments & Insurance Services-</b>
                        Allows users to conveniently pay their insurance premiums online.
                        Users can access their policy details, policy number, and policy
                        premium amount, and ensure timely payment with no time trouble or
                        delay.
                    </p>
                    <p>
                        <b>Fastag Services -</b>
                        We provide seamless and hassle-free electronic toll payments.
                    </p>
                    <p>
                        <b>G2C Services -</b>
                        We provide a range of G2C (Government-to-Citizen) services such as -
                        applying for government schemes, obtaining certificates such birth,
                        death, and marriage certificates, and accessing social welfare
                        programs.
                    </p>
                </div>
            </section>

            <section className="Wcu-sect">
                <div className="container Open-Sans-Serif ">
                    <h3 className="Ftitle text-center">Why Choose Us</h3>
                    <div className="row mb-5">
                        <div className="col-lg-4 col-md-4 d-flex ">
                            <div className="wh-cards">
                                <div className="wh-title">
                                    <span>
                                        <i
                                            className="fa-solid fa-bullhorn"
                                            style={{ color: "#fff", fontSize: "30px" }}
                                        ></i>
                                    </span>
                                    <h4>Entrepreneurial opportunity</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 d-flex ">
                            <div className="wh-cards">
                                <div className="wh-title">
                                    <span>
                                        <i
                                            className="fa-solid fa-chart-column"
                                            style={{ color: "#fff", fontSize: "30px" }}
                                        ></i>
                                        <i class=""></i>
                                    </span>
                                    <h4>High Return on Investment</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 d-flex ">
                            <div className="wh-cards">
                                <div className="wh-title">
                                    <span>
                                        <i
                                            className="fa-solid fa-wallet"
                                            style={{ color: "#fff", fontSize: "30px" }}
                                        ></i>
                                    </span>
                                    <h4>Minimal Investment</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 d-flex ">
                            <div className="wh-cards">
                                <div className="wh-title">
                                    <span>
                                        <i
                                            className="fa-sharp fa-solid fa-headset"
                                            style={{ color: "#fff", fontSize: "30px" }}
                                        ></i>
                                    </span>
                                    <h4>Training and Technical Support </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 d-flex ">
                            <div className="wh-cards">
                                <div className="wh-title">
                                    <span>
                                        <i
                                            className="fa-solid fa-user"
                                            style={{ color: "#fff", fontSize: "30px" }}
                                        ></i>
                                    </span>
                                    <h4> Complete Ownership</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 d-flex ">
                            <div className="wh-cards">
                                <div className="wh-title">
                                    <span>
                                        <i
                                            className="fa-solid fa-display"
                                            style={{ color: "#fff", fontSize: "30px" }}
                                        ></i>
                                    </span>
                                    <h4>Flexible business Model </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="frachise-sect">
                <div className="container Open-Sans-Serif ">
                    <div className="title-head">
                        <h3>
                            Anyone can become <span>Franchise Partner</span>
                        </h3>
                        <p>Of Digital Gramin Seva and increase their income.</p>
                        <h5>Choose the plan according to your need</h5>
                    </div>
                    <div className="plan-row row">
                        <div className="plan-cards col-lg-3 col-md-4">
                            <h3>PLAN A</h3>
                            <h4>Rs 4,999/-</h4>
                            <ul>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Franchise ID Password
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Certificate
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    ID Card
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Franchise Agreement
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Services Templates
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    DGS T-Shirt
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    Mini Atm
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    IRCTC Agnet ID
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    Mantra MFS 100
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    DGS Register
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    DGS Wall Clock
                                </li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-success joinbtn">
                                    JOIN NOW
                                </a>
                            </div>
                        </div>
                        <div className="plan-cards col-lg-3 col-md-4">
                            <h3>PLAN B</h3>
                            <h4>Rs 9,999/-</h4>

                            <ul>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Franchise ID Password
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Certificate
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    ID Card
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Franchise Agreement
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Services Templates
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    DGS T-Shirt
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Mini Atm
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    IRCTC Agnet ID
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    Mantra MFS 100
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    DGS Register
                                </li>
                                <li>
                                    <i class="fa-solid fa-x"></i>
                                    DGS Wall Clock
                                </li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-success joinbtn">
                                    JOIN NOW
                                </a>
                            </div>
                        </div>
                        <div className="plan-cards col-lg-3 col-md-4">
                            <h3>PLAN C</h3>
                            <h4>Rs 12,999/- </h4>
                            <ul>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Franchise ID Password
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Certificate
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    ID Card
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Franchise Agreement
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Services Templates
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    DGS T-Shirt
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Mini Atm
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    IRCTC Agnet ID
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    Mantra MFS 100
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    DGS Register
                                </li>
                                <li>
                                    <i className="fa-solid fa-check"></i>
                                    DGS Wall Clock
                                </li>
                            </ul>
                            <div className="d-flex justify-content-center">
                                <a href="#" className="btn btn-success joinbtn">
                                    JOIN NOW
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="benefits_sect">
                <div className="container Open-Sans-Serif ">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3>Benefits</h3>
                            <ul className="bt-list">
                                <li>Create unlimited retailer</li>
                                <li>Create unlimited distributor</li>
                                <li>
                                    Earn for every new retailer and distributor recruited in your
                                    district with the help of Digital Gramin Seva Field Executive.
                                </li>
                                <li>
                                    Earn commission on every transaction made by retailers within
                                    your district.
                                </li>
                            </ul>
                            <p>
                                If you have achieved your franchise target then you will get
                                20,000 salary per month.
                            </p>
                            <p>
                                If you achieve your monthly target, you will receive an
                                attractive incentive and an additional bonus.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="required-docs">
                <div className="container Open-Sans-Serif ">
                    <div className="row">
                        <div className="col-lg-7 col-md-7">
                            <h3>Required Documents</h3>
                            <ul className="doc-list">
                                <li>Aadhar Card</li>
                                <li>Pan Card</li>
                                <li>High School Certificate</li>
                                <li>Electricity Bill Or Rent Agreement</li>
                                <li>GST/MSME</li>
                                <li>Resident Certificate</li>
                                <li>Police Verification</li>
                                <li>Cancel Cheque / Passbook</li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-md-5">
                            <h3>Required Gadgets:</h3>
                            <ul className="doc-list">
                                <li>Computer/Laptop</li>
                                <li>Printer</li>
                                <li>Shop/Office</li>
                            </ul>
                        </div>
                        <div className="col-lg-12">
                            <h3 className="duties-title">Responsiblities and Duties:</h3>
                            <ul className="doc-list duties-list ">
                                <li>
                                    Conduct KYC and physical Verification of newly onborded
                                    customer.
                                </li>
                                <li>
                                    build relationship with nearby customer and takecare of them.
                                </li>
                                <li>Handle new loan files and facilitate loan recovery.</li>
                                <li>Manage sales and support for field executives.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="franchise-sect">
                <div className="container Open-Sans-Serif ">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="Ftitle">Franchise Process:</h3>
                            <h6>WHAT DOES THE PROCESS LOOK LIKE?</h6>
                            <ul className="process-list">
                                <li>
                                    <h5>1. Register:</h5>
                                    <p>
                                        Complete the registration process by providing the required
                                        information
                                    </p>
                                </li>
                                <li>
                                    <h5>2. Pay the Registration Fees:</h5>
                                    <p>
                                        Make the payment for the registration fees as specified.
                                    </p>
                                </li>
                                <li>
                                    <h5>3. Submit Your Documents:</h5>
                                    <p>
                                        Send your necessary documents to our designated address or
                                        through the specified method.
                                    </p>
                                </li>
                                <li>
                                    <h5>4. Document Verification: </h5>
                                    <p>
                                        Your submitted documents will undergo a verification
                                        process.{" "}
                                    </p>
                                </li>
                                <li>
                                    <h5>5. 2-Day Training (Video Call Zoom Meeting):</h5>
                                    <p>
                                        Attend a two-day training session conducted via video call
                                        on Zoom.{" "}
                                    </p>
                                </li>
                                <li>
                                    <h5>6. Clear the Exam and Certification:</h5>
                                    <p>
                                        Once you successfully pass the exam and receive the
                                        certification
                                    </p>
                                </li>
                                <li>
                                    <h5>7. Work Training and Services:</h5>
                                    <p>
                                        Gain access to comprehensive training and guidance regarding
                                        all the services and work involved in the franchise.
                                    </p>
                                </li>
                                <li>
                                    <h5>8. Kit Delivery as per Plan:</h5>
                                    <p>Receive your franchise kit as per the agreed-upon plan.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="getInTouch-sect join-now">
                <div className="container Open-Sans-Serif ">
                    <div className="get-head text-center">
                        <h3 className="join-title">
                            <a href="#">JOIN NOW</a>
                        </h3>
                        <p>We are looking forward to start a business with you!</p>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-10 col-md-12 mt-4">
                            <div className="row d-flex justify-content-center">
                                <div className=" col-lg-3 col-md-3">
                                    <div className="gtouch-cards">
                                        <span>
                                            <i
                                                className="fa-solid fa-phone"
                                                style={{ fontSize: "24px", color: "#ffffff" }}
                                            ></i>
                                        </span>
                                        <h5>
                                            <a href="#">8823923892</a>
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="gtouch-cards">
                                        <span>
                                            <i
                                                className="fa-solid fa-globe"
                                                style={{ fontSize: "24px", color: "#ffffff" }}
                                            ></i>
                                        </span>
                                        <h5>
                                            <a href="#">digitalgraminseva.in</a>
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-5">
                                    <div className="gtouch-cards">
                                        <span>
                                            <i
                                                className="fa-solid fa-envelope"
                                                style={{ fontSize: "24px", color: "#ffffff" }}
                                            ></i>
                                        </span>
                                        <h5>
                                            <a href="#">franchise@digitalgraminseva.in</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ApplyForFranchise