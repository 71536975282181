import React from 'react'
import { NavLink } from "react-router-dom"
import "./Header.css"

const Header = () => {
    return (
        <>

            <section>
                <div className='container-fluid ' style={{ padding: "2px 0px", backgroundColor: "#f4f4f4" }}>
                    <div className="container-lg Open-Sans-Serif">
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6 col-md-7">
                                <div className='d-none d-sm-block'>
                                    <ul className='d-flex align-items-center' style={{ columnGap: "15px" }}>
                                        <li className='' style={{ listStyle: "none", fontSize: "0.875rem" }} > <i class="fa-solid fa-phone"></i> +91-83839-28391</li>
                                        <li className='' style={{ listStyle: "none", fontSize: "0.875rem" }}> <i class="fa-regular fa-envelope"></i>  support@digitalgraminseva.in</li>
                                    </ul>
                                </div>

                                <div className='d-sm-none d-block'>
                                    <div className=' mb-2 mt-2 text-center'>
                                        <h6 className='Mobile_Number' > <i class="fa-solid fa-phone"></i> +91-83839-28391</h6>
                                        <h6 className='Mobile_Email' > <i class="fa-regular fa-envelope"></i>  support@digitalgraminseva.in</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-5">
                                <ul className='d-flex justify-content-lg-end ' style={{ columnGap: "10px" }}>
                                    <li className='mt-2' style={{ listStyle: "none", fontSize: "0.875rem" }} >
                                        Download App
                                        &nbsp;
                                        <img className='' style={{ height: "2rem" }} src={require("../Img/playstore.png")} alt="" />
                                    </li>
                                    <li className='mt-1' style={{ listStyle: "none", height: "35px" }}>
                                        <div id="google_translate_element"></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid d-none d-sm-block">
                    <div className="row d-flex justify-content-lg-between align-items-center Open-Sans-Serif">
                        <div className="col-lg-5 col-md-6">
                            <div className='bottom-header'>
                                <NavLink className='text-decoration-none' to='/'>
                                    <img className='' style={{ height: "3rem" }} src={require("../Img/logo.png")} alt="" />
                                </NavLink>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5  p-lg-0">
                            <div className='login-regs d-flex justify-content-lg-end align-items-center'>
                                <img className='' style={{ height: "3rem" }} src={require("../Img/digital_india.png")} alt="" />
                                <ul className='d-flex  align-items-center text-white Open-Sans-Serif ' style={{ listStyleType: "none", padding: '20px', marginTop: "9px", marginBottom: "9px" }} >
                                    <li className='' style={{ fontSize: '1rem' }} >Login</li>
                                    <li>|</li>
                                    <li className='' style={{ fontSize: '1rem' }}>Register</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid d-sm-none d-block">
                    <div className="row d-flex justify-content-lg-between align-items-center Open-Sans-Serif">
                        <div className="col-lg-5 col-md-6">
                            <div className='bottom-header'>
                                <img className='text-center' style={{ height: "2rem" }} src={require("../Img/logo.png")} alt="" />
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5  p-lg-0">
                            <div className='login-regs d-flex justify-content-lg-end align-items-center'>
                                <img className='' style={{ height: "2.4rem" }} src={require("../Img/digital_india.png")} alt="" />
                                <ul className='d-flex  align-items-center text-white Open-Sans-Serif ' style={{ listStyleType: "none", padding: '20px', marginTop: "9px", marginBottom: "9px" }} >
                                    <li className=''  >Login</li>
                                    <li>|</li>
                                    <li className='' >Register</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <nav className="navbar navbar-expand-lg header-content p-0 " style={{ backgroundColor: "#FF6700" }} >
                <div className="container">
                    <button className="navbar-toggler " type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars text-white pt-1 pb-1"></i>
                    </button>
                    <div className="collapse navbar-collapse   " id="navbarNavDropdown">
                        <ul className="navbar-nav d-flex justify-content-lg-start align-items-lg-center Open-Sans-Serif w-100"   >
                            <li className='nav-item Header-Size' >
                                <NavLink className=" text-decoration-none text-white" style={{ fontSize: "1rem" }} to="/"> Home </NavLink>
                            </li>
                            <li className='nav-item  Header-Size' >
                                <NavLink className=" text-decoration-none  text-white " style={{ fontSize: "1rem" }} to="/services"> Services </NavLink>
                            </li>
                            {/* <li className='nav-item  Header-Size' >
                                <NavLink className=" text-decoration-none  text-white" to="/Product"> Commission Chart </NavLink>
                            </li> */}
                            <li className='nav-item  Header-Size' >
                                <NavLink className=" text-decoration-none  text-white" style={{ fontSize: "1rem" }} to="/about"> About Us </NavLink>
                            </li>
                            <li className='nav-item  Header-Size' >
                                <NavLink className=" text-decoration-none  text-white" style={{ fontSize: "1rem" }} to="/contact"> Contact Us </NavLink>
                            </li>
                            <li className='nav-item  Header-Size' >
                                <NavLink className=" text-decoration-none  text-white" style={{ fontSize: "1rem" }} to="/applyforfranchise"> Apply For Franchise </NavLink>
                            </li>
                            <li className='nav-item  Header-Size' >
                                <NavLink className=" text-decoration-none  text-white" style={{ fontSize: "1rem" }} to="/applyforjob"> Apply For Job </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )
}

export default Header