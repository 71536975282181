import React from 'react'
import Silder from './Silder'

const CspBanking = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards4.png")} alt="" /></span>
                                <span className='Main_Title'>CSP BANKING</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> CSP KIOSK BANKING </h3>
                                <p>
                                    Kiosk Banking BC model aims to supply a true time, user friendly banking services to the buyer in their neighborhood. Digital Gramin Seva has been a National 'Business Correspondent' for the Digital Gramin Seva As per this arrangement Digital Gramin Seva is permitted to supply Kiosk banking service to its present retail network and make new Kiosk Banking agents across the country.
                                </p>
                                <p>
                                    Customers may open bank accounts and do all kinds of bank related transactions at SBI Kiosk Banking outlet or Bank of Baroda Kiosk Banking outlet. These outlets are ready to offer end to finish functionalities with the method of account opening or any online transaction and there are banks who also offer online kiosk banking comfortable . There are variety of benefits of getting kiosk online, but these are mainly for the more educated lot to access all services at just a click. Also, the operations through these outlets are absolutely safe and reliable as they're bio-metrically secured and also, a printed acknowledgement for each transaction is given to the respective customer.
                                </p>
                                <p>
                                    Make your shop Mini bank & provide basic banking services to your customers!!!
                                </p>

                                <h2> Benefits to CSPs (Customer Service Point) </h2>
                                <ul>
                                    <li> Brand Equity of the banks (HDFC’s, and Bank of Baroda’s ) </li>
                                    <li> Opportunity for self-employment at ease at the doorstep itself </li>
                                    <li> Increased Visibility overall </li>
                                    <li> High number of Walk in customers </li>
                                    <li> Good incentives in accordance with the performance </li>
                                    <li> Significant remuneration figures resulting in financial freedom eventually </li>
                                </ul>

                                <h2> Benefits to Customers </h2>
                                <h6> Kiosk banking service brings a host of unique benefits for all customers, as mentioned below. </h6>
                                <ul>
                                    <li> Banking facility nearer to customer location </li>
                                    <li> Avail basic banking services without visiting bank branches </li>
                                    <li> Convenient to use kiosk machines and other facilities </li>
                                    <li> Enhanced and safe banking experience </li>
                                    <li> Hassle-free money transfer, withdrawals and deposits </li>
                                    <li> Long banking hours, outlets accessible as long as they are open. </li>
                                    <li> Open no-frills account without minimum balance or charges levied </li>
                                    <li> Get account converted to regular bank account if balance exceeds above value </li>
                                    <li> Avail facility for savings like term deposit, recurring deposit </li>
                                    <li> Access to money withdrawal through ATM card for customers aged above 18 years </li>
                                </ul>

                                <h2> Requirements for AEPS transactions </h2>
                                <ul>
                                    <li> Bank Name </li>
                                    <li> Aadhaar Number </li>
                                    <li> Fingerprint captured during enrollment. </li>
                                </ul>

                                <h2> How to apply for CSP(Costumer Service Point) </h2>
                                <h6> It is a very easy and simple way to Apply for CSP </h6>
                                <ul>
                                    <li> First you need to connect with Digital Gramin Seva </li>
                                    <li> To join you have to click on the registration button above. </li>
                                    <li> After registration is complete, you will get Retailer ID password </li>
                                    <li> After getting the login ID, you have to login on Digital Gramin Seva Portal. After login, you have to click on the CSP option and apply for CSP & Kiosk Banking. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default CspBanking