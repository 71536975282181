import React from 'react'
import Silder from './Silder'

const OnePersonCompany = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards8.png")} alt="" /></span>
                                <span className='Main_Title'> One Person Company </span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h2> One Person Company (OPC)? </h2>
                                <p>
                                    OPC is appropriate for sole entrepreneurs, seeking business privileges that are missed in a sole- proprietorship model. Basically, it enables single owners to operate a commercial entity with limited liability protection.
                                </p>

                                <h2> What is One Person Company? </h2>
                                <p>
                                    The OPC mode of business was lately brought in force, especially for Indian entrepreneurs through the Companies Act of 2013. The new model is considered to a promising substitute for the conventional sole- proprietorship form. The unique point of OPC model is that it enables a solo- promoter to form a single person economic reality and let completely control all its functions, while limiting the liability to contributions to the business. The solo-person will be the only director and share- proprietor (there is, still, a designee director with no power until the original director is unable of entering into contract). There is, therefore, no scope of increasing equity funding or offering employee stock options. Additionally, if an OPC touches an average three-year turnover of more than INR 2 crore or has a paid-up capital of over INR 50 lac, it’s bound to be transitioned into a private limited company or public limited company within six months of period. It is, therefore, critical for an entrepreneur to closely consider all the features of an OPC prior to incorporation.
                                </p>

                                <h2> Advantages of an OPC </h2>
                                <ul>
                                    <li> Continuous Existence </li>
                                    <li> Borrowing Credibility </li>
                                    <li> Property Ownership </li>
                                    <li> Single Ownership </li>
                                </ul>

                                <h2> Documents required for OPC </h2>
                                <ul>
                                    <li> PAN Card or Passport </li>
                                    <li> Voter’s ID / Passport / Driver’s License </li>
                                    <li> Latest Bank Statement / Telephone or Mobile Bill / Utility Bills (Electricity / Water / Gas) </li>
                                    <li> passport-sized photograph </li>
                                    <li> Specimen signature (blank document with signature) </li>
                                    <li> Notarized Rental Agreement in English </li>
                                    <li> No-Objection Certificate from the property owner </li>
                                    <li> Deed/Property Deed in English (in case of owned property) </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default OnePersonCompany