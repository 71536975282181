import React from 'react'
import Silder from './Silder'

const TanRegistration = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards9.png")} alt="" /></span>
                                <span className='Main_Title'> Tan Registration </span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h2> Digital Gramin Seva Provides TAN Registration Facility </h2>
                                <p>
                                    Tax Account Number is commonly referred as Tax Deduction Account Number or Tax Collection Account Number, or simply TAN. TAN registration leads to a generation of 10 digits alpha-numeric code that is issued by the Income Tax Department of India. As the name suggests, it is obtained by the person responsible for deduction or collection of tax at source (TDS or TCS). Businesses while deducting the salaries of employees must obtain and mention the number. Specified criteria to collect and deduct tax at source are already prescribed by the Government. Also, it is compulsory to quote TAN of the assessee in TDS/ TCS statements and challan for payment of TDS/ TCS.
                                </p>

                                <h2> Documents Requirement for TAN Application </h2>
                                <ul>
                                    <li> Address Proof Copy of Aadhar Card or other address proof </li>
                                    <li> Applicants Information Name, contact details and signature of the applicant </li>
                                    <li> Certificate of Incorporation Certificate of Incorporation in case of company and LLP </li>
                                    <li> Agreement Agreement in case of LLP and Partnership Firm </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default TanRegistration