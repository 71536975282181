import React from 'react'
import Silder from './Silder'

const Passport = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> Passport </span>
                            </div>

                            <article className="article-content">
                                <div className="row">
                                    <h2 className="mt-0">
                                        You can apply Passport of citizens near you through Digital
                                        Gramin Seva
                                    </h2>
                                    <p>
                                        Visiting a foreign country is a dream come true for any
                                        person in India. To make this happen, you need a passport.
                                        Passport is an identity of your nationality that lets the
                                        officials of the other countries know who you are and that
                                        you are permitted to visit a particular country.
                                    </p>
                                    <p>
                                        Applying for a passport is not a rocket science. Firstly,
                                        you need to fill a form having personal details relevant and
                                        make the payment according to the size of passport book you
                                        choose to have. The entire process can take time or you can
                                        also get it quickly if you pay more or get it done through
                                        an agent. Once the payment is done, a meeting for filling
                                        the form with the passport officials will be scheduled at
                                        the center.
                                    </p>
                                    <p>
                                        There, you will need to give your fingerprints, your picture
                                        will be taken by the officials and the relevant documents
                                        will be submitted. Once all of this is done and verification
                                        is complete, within a time period of 15 days, you will
                                        verified by the police. Afterwards, if your records are
                                        clean, a passport will be dispatched at your doorstep.
                                    </p>

                                    <h2>Documents required for a fresh passport:</h2>
                                    <ul className="bc-service-list ">
                                        <li>
                                            Photo passbook of running bank account in any public
                                            sector bank, private sector bank and regional rural banks
                                        </li>
                                        <li>A voter ID card</li>
                                        <li>Aadhaar card</li>
                                        <li>Electricity bill</li>
                                        <li>Rent agreement</li>
                                        <li>Driving license</li>
                                        <li>Landline or postpaid mobile bill</li>
                                        <li>
                                            Proof of gas connection S-pouse’s passport copy (First and
                                            last page of the passport that includes the details of the
                                            family and mentions applicants name as the spouse of the
                                            passport holder)
                                        </li>
                                        <li>
                                            Certificate from employer of reputed companies on
                                            letterhead. Income Tax assessment order
                                        </li>
                                        <li>School leaving certificate</li>
                                        <li>
                                            Birth certificate issued by the Municipal Corporation
                                        </li>
                                        <li>
                                            Policy Bond issued by the Public Life Insurance
                                            Corporations/Companies having the DOB of the holder of the
                                            insurance policy
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default Passport