import React from 'react'

const AboutDigitalGraminSeva = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <h1>
                                    About Digital Gramin Seva
                                </h1>
                                <h5>
                                    Feb 22, 2021 by IMOC Digital Services Private Limited
                                </h5>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/AboutDigitalBlog.png")} alt="" />
                                <p>
                                    Digital Seva or Digital Gramin Seva is a digital platform where people can provide many services through customer service center in their area. Through this they can apply for various banking, bill payment, documents and government schemes to make their life easier. The service is also often referred to as Digital Seva.
                                </p>

                                <b>
                                    About Digital Gramin Seva
                                </b>
                                <p>
                                    The goal of Digital Gramin Seva is to provide Banking, Bill Payment, G to C (Goverment to Citizen) and B to C (Business to Citizen) service to rural citizens. Digital Seva or Digital Gramin Seva Kendra established in 2018 under Digital India model of Government of India. It was created as an attempt to digitize India. Helping citizens to be aware of all essential services and government schemes. These Digital Gramin Seva Kendras provide all kinds of online services, be it registration, application or required authentication. These Digital Gramin Seva Kendras are operated by common people of the area where CSC Digital Seva is located. These people are known as retailers. These people work to help the people of their area by providing them internet.
                                </p>


                                <b>
                                    Digital Gramin Seva promotes the goal of Digital India. What services are provided in Digital Seva (Digital Gramin Seva)?
                                </b>
                                <li>
                                    Bank Account Opening More than 10 bank accounts
                                </li>
                                <li>
                                    AEPS (Aadhaar Enabled Payment System) AEPS stands for Aadhaar Enabled Payment System, a replacement payment service offered by the National Payments Corporation of India to banks and financial institutions using 'Aadhaar'. Aadhaar can be a unique number issued by the Unique Identification Authority of India (UIDAI). AEPS in Banking means Empowerment where Provider a Bank allows a customer to access their respective Aadhaar enabled Checking Account (AEPS Service Provider Bank) and use Aadhaar as their identity for carrying out basic intrabank or interbank transactions.
                                </li>
                                <li>
                                    Money Transfer There are many benefits to starting and growing a money transfer business.
                                </li>
                                <li>
                                    MATM (As Per Debit Card Limit) Micro ATMs can help you grow your business by adding another stream of revenue to your business. With each transaction, you can easily get an attractive commission.
                                </li>
                                <li>
                                    Kisok banking CSP point Customers can open bank accounts at SBI Kiosk Banking Outlet or Bank of Baroda Kiosk Banking Outlet and do all types of bank related transactions. These outlets are ready to offer end-to-end functionality with account opening or any mode of online transaction and there are banks that also facilitate online kiosk banking.
                                </li>
                                <li>
                                    Flight Booking flight booking facility
                                </li>
                                <li>
                                    Hotel Booking hotel booking facility
                                </li>
                                <li>
                                    Bus Booking bus booking facility
                                </li>
                                <li className='mb-3'> 
                                    Mobile & DTH Recharge Mobile and DTH recharge facility
                                </li>

                                <b>
                                    Additionally, the applicant must meet the following criteria to be eligible:
                                </b>
                                <li>
                                    Be at least 18 years of age or older
                                </li>
                                <li>
                                    Have a valid number, e-mail ID and bank account
                                </li>
                                <li>
                                    Carry identity proof like Aadhar card, passport size photograph and PAN card
                                </li>
                                <p className='mt-2 mb-0'>
                                    Digital Seva (Digital Gramin Seva) Portal Login To log in to the CSC Digital Seva Portal, you need to:
                                </p>
                                <li>
                                    Go to https://digitalgraminseva.in
                                </li>
                                <li>
                                    Click on the Login button.
                                </li>
                                <li>
                                    Enter your username and password.
                                </li>
                                <li>
                                    Click on Sign In.
                                </li>
                                <li>
                                    By doing this you will be able to access all your information.
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutDigitalGraminSeva