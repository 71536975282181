import React from 'react'
import Silder from './Silder'

const VoterIdCorrection = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> Voter ID New </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2>
                                        You can apply Voter ID of citizens near you through Digital
                                        Gramin Seva
                                    </h2>
                                    <h2>VOTER ID</h2>
                                    <p>
                                        Voter ID, also known as EPIC (Electors Photo Identity Card)
                                        may be a photo card issued by the committee of India to all
                                        or any Indian citizens eligible to vote. the aim of voter ID
                                        is to function an identity proof for voters, increase
                                        efficiency and stop impersonation and fraud during free and
                                        fair democratic elections. This card is additionally
                                        commonly referred to as an election card, voter’s card, or
                                        Voter ID card.
                                    </p>
                                    <p>
                                        A voter ID card is an accepted form of personal
                                        identification in India as it is issued by a government
                                        body. The voter ID consists of the following details:
                                    </p>

                                    <ul class="bc-service-list m-0">
                                        <li>A unique Serial number</li>
                                        <li>Photograph of the cardholder</li>
                                        <li>
                                            A hologram containing respective state/national symbol
                                        </li>
                                        <li>Name of card holder</li>
                                        <li>Father’s Name of card holder</li>
                                        <li>Gender</li>
                                        <li>Card holder’s</li>
                                        <li>Card holder’s date of Birth</li>
                                    </ul>
                                    <p>
                                        The card holder’s residential address and signature of the
                                        issuing authority (Electoral registration officer) are on
                                        the rear side of the voter ID card.
                                    </p>

                                    <h3>Documents Required For Voter ID Card:</h3>
                                    <ul class="bc-service-list m-0">
                                        <li>
                                            Applying for a Voters ID requires you to submit the
                                            following documents:
                                        </li>
                                        <li>Proof of identity</li>
                                        <li>Proof of address</li>
                                        <li>Photograph</li>
                                    </ul>

                                    <h3>How to Apply for a Voter ID Card?</h3>
                                    <ul class="bc-service-list m-0">
                                        <li>First you need to connect with Digital Gramin Seva</li>
                                        <li>
                                            To join you have to click on the registration button
                                            above.
                                        </li>
                                        <li>
                                            After registration is complete, you will get Retailer ID
                                            password
                                        </li>
                                        <li>
                                            After getting the login ID, you have to login on Digital
                                            Gramin Seva Portal.
                                        </li>
                                        <li>
                                            After login, you have to click on the Voter ID option and
                                            apply for Voter ID Card.
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default VoterIdCorrection