import React from 'react'
import Silder from './Silder'

const PmKisanScheme = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> PM Kisan Scheme </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2>PM Kishan Samman Nidhi</h2>
                                    <p>
                                        In Digital Gramin Seva Portal, you can provide PM-Kisan
                                        Scheme.
                                    </p>
                                    <p>
                                        The Pradhan Mantri Kisan Samman Nidhi Yojana (PM-Kisan
                                        Yojana) is a government scheme through which, all small and
                                        borderline farmers will get up to Rs 6,000 per year as
                                        minimal income support. This 75,000 -crore scheme aims to
                                        cover 125 million growers, irrespective of the size of their
                                        landholding in India.
                                    </p>
                                    <p>When did the PM-Kisan scheme come into effect?</p>
                                    <p>
                                        The PM Kisan Yojana came into effect from December 1, 2018.
                                        It was launched by prime minister Narendra Modi-led
                                        government
                                    </p>
                                    <p>PM Kisan Yojana explained</p>
                                    <p>
                                        Under the PM Kisan Yojana, income support of Rs 6000 per
                                        annum is provided to all eligible farmer families across the
                                        country in three equal installments of Rs 2,000 each every
                                        four months. The scheme defines family as hubby, woman and
                                        minor children. The fund of Rs 2,000 is directly transferred
                                        to the bank accounts of the farmers/ farmer's family.
                                    </p>

                                    <h3>Who is eligible for PM Kisan scheme?</h3>
                                    <ul class="bc-service-list m-0">
                                        <li>
                                            Landholding farmers’ families with cultivable landholding
                                            in their names can apply under this scheme
                                        </li>
                                        <li>Farmers from both the urban and rural areas</li>
                                        <li>Small and marginal farmers families</li>
                                    </ul>

                                    <h3>Documents required</h3>
                                    <ol class="bc-service-list m-0">
                                        <li>Citizenship certificate.</li>
                                        <li>Aadhaar card</li>
                                        <li>Bank account details.</li>
                                    </ol>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default PmKisanScheme