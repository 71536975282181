import React from 'react'

const WhatIsGST = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/gst-banner.png")} alt="" />
                                <p>
                                    इस आर्टिकल में हम जानेंगे GST क्या है , GST के फायदे , <br />
                                    और आप किस तरह GST की सुविधा प्रदान कर सकते हैं
                                </p>

                                <h2>
                                    <b>
                                        What is GST? GST क्या है
                                    </b>
                                </h2>
                                <p>
                                    GST का Full Form (Good And Service Tax) होता है इसका हिंदी में अर्थ (वस्तु एवं सेवा कर) है यह देश भर में वस्तुओं और सेवाओें की बिक्री पर लगने वाले GST का फायदा यह है की किसी भी एक वस्तु पर लगने वाला टैक्स पूरे भारत में एक होगा मतलब भारत में कोई भी व्यापारी उस वस्तु को बेचेगा तो उसको एक बराबर टैक्स चुकाना पड़ेगा
                                </p>

                                <h2>
                                    GST से पहले
                                </h2>
                                <p>
                                    जब कोई सामान फैक्ट्री से निकलता था तो उस पर उत्पाद शुल्क यानी Excise tax लगता था कई बार कई सामानो पर अतिरिक्त उत्पाद यानी Additional tax duty भी लगता था <br />
                                    अगर यही सामान एक राज्ये से दुसरे राज्ये जा रहा है तो एंट्री करते ही Entry Tax लगता था <br />
                                    और जब सामान बिकने की बारी आती थी तो Sales tax लगता था कई मामलो में Purcharse Tax भी लगता था <br />
                                    अगर वह सामान होटल या रेस्टोरेंट में जा रहा है तो उस पर Service Tax अलग से लगता था , मतलब अगर कोई सामान Consumer तक पहुंचना है तो पहुचने से पहले कई Duties and taxes से गुजरना होता था
                                </p>

                                <h2>
                                    जीएसटी की प्रमुख विशेषताएं | Major feature Of GST
                                </h2>
                                <h3>
                                    1.Tax on Consumption
                                </h3>
                                <p>
                                    GST Tax वस्तु और सेवा का इस्तेमाल करने वाले को देना पड़ता है। <br />
                                    मतलब ये है कि दुकानदार अगर कोई समान या वस्तु देता है तो उसमे GST अलग से लिख कर देता है, <br />
                                    जो की खरीदार को GST मिलाकर पूरा पैसा देना होता है, ।
                                </p>
                                <h3>
                                    2.टैक्स पर टैक्स नहीं देंना होगा |
                                </h3>
                                <p>
                                    GST के पहले कई अलग-अलग Tax लगते थे, अक्सर टैक्स के ऊपर Tax भी लग जाते थे। बहुत से ऐसे समान और सेवाएं है जो दो या दो से अधिक तरह की Categories में आती थी । पर अब ऐसा नहीं होगा। <br />
                                    क्योंकि अब जीएसटी अंतिम रूप से Consumer को ही अदा करना है। बीच में अगर किसी ने Deposit करता है तो उसका पैसा टैक्स क्रेडिट सिस्टम से वापस यानी Adjust हो जायेगा।
                                </p>

                                <h3>
                                    3.टैक्स क्रेडिट सिस्टम | Tax Credit System
                                </h3>
                                <p>
                                    किसी सामान के निर्माण से लेकर Consumer के हाथों पहुंचने में पूरी चेन शामिल होती है। सामान कई बार खरीदा बेचा जाता है। GST के नियमों के मुताबिक सप्लाई चेन में हर खरीद बिक्री पर तय टैक्स देना होगा। अगर Tax Credit System नहीं होता तो हर स्तर पर टैक्स लगने से चीजें बहुत महंगी हो जाती। इस सिस्टम में सप्लाई चेन का हर अगला खरीदार अपने से पिछले वाले विक्रेता के द्वारा दिए गए टैक्स वापस मिल जाता है ।
                                </p>
                                <p>
                                    हर महीने GST रिटर्न भरने के दौरान आप Tax Credit System के माध्यम से अपना जीएसटी Adjust करा सकते हैं
                                </p>

                                <h3>
                                    4.टैक्स पर मनमानी नहीं | No Arbitrary Ratess
                                </h3>
                                <p>
                                    पहले राज्य सरकारें अपने यहां बिकने वाले हर एक सामान पर अपनी मनमर्जी से Tax लगाती थी । Rate भी अपना-अपना रखती थीं। पर अब ऐसा नहीं होता । GST के प्रावधानों में या रेट में किसी भी तरह के Changes के लिए GST Council तैयार की गयी है। इसके अध्यक्ष केंद्रीय वित्त मंत्री होंगे और राज्यों के वित्त मंत्री इसके सदस्य होंगे।
                                </p>

                                <h2>
                                    GST से आम आदमियों, कारोबारियों और सरकार के फायदे
                                </h2>
                                <p>
                                    आम आदमियों के लिए| For Common People <br />
                                    तरह-तरह के Tax खत्म होने और टैक्स के उपर Tax खत्म होने से वस्तुओं की लागत में Unnecessary बढोतरी नहीं। सामानो के दाम भी ज्यादा नहीं बढ़ेंगे। <br />
                                    आम जरूरत की चीजों पर कम Tax लगेगा। आम आदमियों के ज्यादा काम आने वाली चीजें सस्ते में मिल सकेंगी।
                                </p>
                                <p>
                                    कारोबारियों के लिए| For Businessmen <br />
                                    आल Document ऑनलाइन होने के कारण दस्तावेजों को तोड-मरोडकर पेश नहीं किया जा सकता। किसी भी तरह की चूक होने पर या खो जाने पर उसे Online ही सुधारने की सुविधा होगी। Offices के चक्कर नहीं लगाने पड़ेंगे। <br />
                                    हर राज्य में Taxes का अलग अलग Structure होने से कारोबारियों को उसे समझना आसान नहीं था। कई अधिकारी-कर्मचारी भी ज्यादा नियमों का गलत फायदा उठाते थे। अब Businessmen को इन झंझटों से नहीं गुजरना पडेगा।
                                </p>

                                <h2>
                                    सरकार के लिए| For Government
                                </h2>
                                <p>
                                    1.हर स्टेज पर होने वाली खरीदारी और बिक्री की रसीदों का मिलान होना जरूरी होगा। तभी पहले के Stages में जमा किया गया Tax Credit का फायदा कारोबारियों को मिल सकेगा। हर किसी को Bill देना और बाद में उनकी रसीद पेश करना जरूरी होगा। ब्लैक मार्केटिंग नहीं हो पायेगी ।
                                </p>
                                <p>
                                    2. Production से लेकर बिक्री तक की Chain में बहुत सी जगहों पर काम दिखाया ही नहीं जाता है। अब GST में ऐसे छूटे लोग भी Tax की इस चेन में जुड़ जाएंगे।
                                </p>

                                <h2>
                                    जीएसटी की दर | Rate Of GST
                                </h2>
                                <p>
                                    अलग अलग प्रकार की वस्तुओं के लिए GST के 5 स्लैब है ।
                                </p>
                                <p>
                                    जीरो, Zero, 5%, 12%, 18%, और 28%.
                                </p>
                                <p>
                                    ज्यादा जरुरतमंद चीज़ो पर कम से कम टैक्स लगता है , और आरामदेह व कम इस्तेमाल चीज़ो पर ज्यादा से ज्यादा टैक्स लगता है ।
                                </p>
                                <p>
                                    जैसे की Air Conditioner, Refrigerator, Makeup आदि पर 28 % GST लगता है । कच्चा माल मसलन अनाज और ताजी सब्जियों आदि पर Zero टैक्स लगता है। Education और Health सुविधाओं को Tax से बहार रखा गया है।
                                </p>

                                <p>
                                    <b>
                                        ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                    </b>
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default WhatIsGST