import React from 'react'
import Silder from './Silder'

const DematAccount = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards4.png")} alt="" /></span>
                                <span className='Main_Title'>Demat Account Opening Services</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    Open a trading and Demat account online and start investing With Digital Gramin Seva
                                </p>

                                <h2> What is Demat Account? </h2>
                                <p>
                                    Demat accounts are used for the dematerialisation of shares. It's primarily used for investing in shares and securities in an electronic form that's more accessible with a Demat account. Specifically, these accounts are meant to convert share certificates from physical to electronic format, thereby providing greater availability for account holders.
                                </p>

                                <h2> List of essential documents needed for opening a Demat account </h2>
                                <h6> For the accessible and hassle-free process of opening a Demat account, you must have some essential documents. Here's a collected list of required document given below. </h6>
                                <ul>
                                    <li> Proof of identity with a photo similar as your Aadhaar card, Pan card, Voter ID card, driving license,etc. </li>
                                    <li> Provide substantiation of your residence, similar as registered parcel agreements, motorist's licenses, passports, landline telephone bills, electricity bills, apartment conservation bills (if applicable), dupe insurance, gas bills,etc. </li>
                                    <li> Your bank's passbook or account statement will serve as your evidence of bank account (must be no further than three months old). </li>
                                    <li> Payslips or taxes ( obligatory for the currency and derivations member). This is your evidence of income. </li>
                                </ul>

                                <h2> How to open a Demat and Trading Account? </h2>
                                <ul>
                                    <li> Choose Trading Account Platform </li>
                                    <li> Fill up your all information in the form </li>
                                    <li> Upload your photo & documents and e-sign your application via Aadhaar OTP </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />


        </>
    )
}

export default DematAccount