import React from 'react'

const Bbps = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/bbps.png")} alt="" />
                                <p>
                                    इस Article में आप जानेंगे BBPS क्या है, और इससे होने वाले फायदे , , और कैसे आप अपनी शॉप पर BBPS की सुविधाएं अपने ग्राहक को देकर अच्छा कमीशन कमा सकते हैं
                                </p>

                                <h2>
                                    BBPS सिस्टम क्या है ?
                                </h2>
                                <p>
                                    BBPS का फुल फॉर्म Bharat Bill Payment System हैं
                                </p>
                                <p>
                                    इसका हिंदी में अर्थ है (भारत बिल भुगतान प्रणाली)
                                </p>
                                <p>
                                    BBPS System को नेशनल कॉरपोरेशन ऑफ इंडिया ने लांच किया था
                                </p>
                                <p>
                                    BBPS भारत में बिल भुगतान प्रणाली है जो बिल भुगतान सेवा प्रदान करती है
                                </p>
                                <p>
                                    इस BBPS System द्वारा ग्राहक अपना बिल भुगतान आसानी से कर पाता है ग्राहक Online और Offline दोनों तरीको से बिल भुगतान कर सकता है
                                </p>

                                <h2>
                                    BBPS द्वारा कौन कौन से बिल भुगतान किये जा सकते हैं ?
                                </h2>
                                <p>
                                    BBPS द्वारा एक ही पोर्टल से बिजली , पानी, गैस, टेलीफोन, डीटीएच , फास्टैग और भी बिल भुगतान किये जा सकते हैं
                                </p>

                                <h2>
                                    BBPS की सुविधा कैसे दें ?
                                </h2>
                                <p>
                                    BBPS की सुविधा प्रदान करने के लिए आपको सबसे पहले हमारी वेबसाइट digitalgraminseva.in पर रजिस्ट्रेशन करना होगा
                                </p>
                                <p>
                                    रजिस्ट्रेशन कैसे करें जानने के लिए इस लिंक पर Click करे
                                </p>
                                <p>
                                    रजिस्ट्रेशन करने बाद आपको एक रिटेलर ID मिलेगी , उस रिटेलर ID को प्राप्त करके आप BBPS की सुविधा अपने ग्राहकों को प्रदान कर सकते हैं
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Bbps