import React from 'react'
import Silder from './Silder'

const AddRemovePartner = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards5.png")} alt="" /></span>
                                <span className='Main_Title'>Add/Remove Partner</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h2> Add Remove Director Partner </h2>
                                <p>
                                    Digital Gramin Seva can help you with Adding/ removing a director from your company and make the whole process easy for you.
                                </p>

                                <h2> What's Add/ Remove Directors or Partners? </h2>
                                <p>
                                    Director of a company is a person delegated by the shareholders to manage the conditioning of the company. Fairly, it’s judicious to make any change in the Board of Directors (B0D) of a company as per the vittles given in the Companies Act of 2013. The need for change in the director of a company must be properly justified and approved by all the shareholders/ being directors of the company. Change in director means either removing a director from the Board of Directors list or appointing a new director to the Board of Directors list.
                                </p>

                                <h2> What's Add/ Remove Directors or Partners? </h2>
                                <h6> What's Add/ Remove Directors or Partners? </h6>
                                <ul>
                                    <li> Photograph Passport size print of the Director. </li>
                                    <li> PAN Card Self- attested PAN card of the Director. </li>
                                    <li> Evidence of Residency Aadhar Card/ Voter ID/ Passport/ Driving License of the Director. </li>
                                    <li> Digital Sign Certificate DSC of the ongoing Director and Director to be excluded/ removed. </li>
                                    <li> Identity evidence as mentioned over- Passport/ Election card/ Driving License/ Aadhar card. </li>
                                    <li> Concurrence letter from Director </li>
                                    <li> Mobile number as well as particular and sanctioned dispatch ID of the Director. </li>
                                    <li> It's obligatory to certify all the documents if the director is anon-resident of India. </li>
                                    <li> Notice of abdication filed with the company. </li>
                                </ul>

                                <h2> Procedure for Add/ Removal of Directors or Partners in a Company </h2>
                                <ul>
                                    <li> Digital Gramin Seva entered your request for adding/ removing Director or Partner. </li>
                                    <li> The procedure for removing a Director from the Board of a company would entirely depend on the being Board of Directors. </li>
                                    <li> Take concurrence from the director going to be appointed and abdication letter in case of removal </li>
                                    <li> Your company is needed to conduct the board meeting for Add/ Remove director and pass the board resolution </li>
                                    <li> After the Board Resolution is passed, we will prepare and file the mandatory documents with the MCA to register the removal or addition of Director </li>
                                    <li> We'll assign a devoted resource who's professionally professed in add or removal of directors. You can get in touch with the assigned resource at any point of time for discussion and backing regarding the change in Directors throughout your trip with Digital Gramin Seva </li>
                                    <li> Our resource will collect all the needed documents/ information and train a change in Director with MCA </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default AddRemovePartner