import React from 'react'
import Silder from './Silder'

const ChangeBusinessName = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards6.png")} alt="" /></span>
                                <span className='Main_Title'>Change Business Name</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h2> Digital Gramin Seva Provides Credit Cards Facility </h2>
                                <h6> Digital Gramin Seva Provides Business name change Facility Enhance Brand value with company name change </h6>
                                <p>
                                A company’s name can be changed at any time after its objectification. It being an reality regulated by law, it has to follow a specific process handed by the Companies Act, 2013. The process includes conducting board meeting and member’s meeting for their separate concurrence, which will be followed by name reservation and blessing from Central Government for said change. Fresh Certificate of Incorporation will be issued by RoC in new name after streamlining the Register of Companies. The company name change doesn't impact the actuality of the company, which leaves all the means and liabilities unaffected.
                                </p>

                                <h2> Documents required to change the limited company name </h2>
                                <ul>
                                    <li> Digital Signature Certificate DSC of one of authorised director to be provided </li>
                                    <li> MoA & AoA A copy of latest amended MoA and AoA of the company </li>
                                    <li> CoI Certificate of Incorporation of company to be provided </li>
                                    <li> PAN Card Copy of PAN card of the company to be provided </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default ChangeBusinessName