import React from 'react'
import "./About.css"

const About = () => {
    return (
        <>

            <section className="abt-sect">
                <div className="container p-lg-4  ">
                    <div className="row">
                        <div className="col abt-heading">
                            <h1>About Us</h1>
                            <div className="row Open-Sans-Serif">
                                <div className="col para-text">
                                    <p>
                                        Digital Gramin Seva is a leading provider of banking,
                                        government, and non-government services in India. The
                                        company was founded in 2019 by two entrepreneurs, Mr.
                                        Narayan Singh and Mrs. Kumari, with the goal of providing
                                        quality banking and financial services to the rural
                                        population of India. Since then, we have expanded our
                                        services to include government and non-government services.
                                        Digital Gramin Seva started its operations with a small team
                                        of 20 employees and has now grown to a team of over 200
                                        employees.
                                    </p>
                                    <p>
                                        We now have a customer base of over 1 million rural
                                        customers and we are constantly expanding our reach to new
                                        areas.{" "}
                                    </p>
                                    <p>
                                        Digital Gramin Seva offers a range of banking services such
                                        as aadhar based payment system, cash deposit, money
                                        transfer, aadhar pay, and account opening. The company also
                                        provides government services such as filing of income tax
                                        returns, PAN card application, and passport application.
                                        Digital Gramin Seva also offers non-government services such
                                        as bill payment, mobile recharge, and utility bill payment.
                                    </p>
                                    <p>
                                        We are a technology-driven organization and we use the
                                        latest technologies to provide our services. We have a
                                        strong focus on security and we use the latest security
                                        technologies to protect our customers' data. We are also
                                        constantly innovating and improving our systems to provide a
                                        better experience for our customers.
                                    </p>
                                    <p>
                                        Digital Gramin Seva has won numerous awards and accolades
                                        over the years. In 2020, the company was ranked as the 'Best
                                        Rural Banking Service Provider'.
                                    </p>
                                    <p>
                                        Digital Gramin Seva is committed to providing quality
                                        banking services to the rural population of India. The
                                        company has a strong focus on technology and innovation.
                                        Digital Gramin Seva has developed a mobile banking
                                        application which allows customers to conduct banking
                                        transactions using their mobile phones. The company has also
                                        developed a Mini ATM which can be used for cash withdrawals
                                        through debit cards.
                                    </p>
                                    <p>Digital Gramin Seva is headquartered in Delhi, India.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                        <div className="row d-flex justify-content-center Open-Sans-Serif g-3 mt-lg-5 mb-5">
                            <div className="col-md-6">
                                <div className="h-100 our-vision1">
                                    <h3>Our Vision</h3>
                                    <p>Providing digital services to every Indian citizen, </p>
                                    <p>
                                        <strong>Digital Gramin Seva wishes:</strong>
                                    </p>
                                    <ul>
                                        <li>Trying to give employment to every citizen</li>
                                        <li>
                                            People living in rural areas do not have to go far to get
                                            banking facility
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="h-100 our-vision1">
                                    <h3>Our Mission</h3>
                                    <ul>
                                        <li>
                                            To make business with us simple, treat all business
                                            stakeholders with dignity, dexterity, and respect.
                                        </li>
                                        <li>
                                            To be self-reliant, to be self-reliant of his advanced
                                            future.
                                        </li>
                                        <li>
                                            Employing all citizens, especially those who are
                                            shopkeepers and who live in rural areas
                                        </li>
                                        <li>
                                            Build an excellent reputation in the digital world and
                                            become one of the top digital services providers in India.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                   
                </div>
            </section>

        </>
    )
}

export default About