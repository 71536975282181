import React from 'react'

const Tds = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/tds-banner.png")} alt="" />

                                <h2>
                                    टीडीएस क्या है?
                                </h2>
                                <p>
                                    जब कोई रुपयो का भुगतान करता हैं जैसे – किराया, दलाली, फीस, वेतन, ब्याज आदि तब उस भुगतान में से कुछ प्रतिशत राशि कर के तौर पर काटनी होती हैं, उस कटी हुई राशि को टीडीएस (TDS) कहा जाता हैं। असल में जो रिसीवर होता हैं उसे अपनी कमाई में से इनकम टैक्स/आयकर भरना होता हैं, और स्त्रोत पर कटौती प्रावधान यह निश्चित करता हैं कि यह कर भुगतान के दौरान ही काट लिया जाए। और जो रिसीवर होता हैं उसको वही राशि मिलती हैं जिसमें से टीडीएस कट चुका हो। इसलिए ‘स्त्रोत पर कर कटौती’ कहा गया हैं, वह कर जो आवक के स्त्रोत से ही कट जाए। कर का श्रेय रिसीवर को ही मिलता हैं।
                                </p>
                                <p>
                                    टीडीएस का फूल फॉर्म हैं – “Tax Deducted At Source” एवं TDS Full Form in Hindi यानि टीडीएस को हिन्दी में “स्त्रोत पर कर कटौती” कहते हैं।
                                </p>

                                <h2>
                                    TDS की पूरी जानकारी
                                </h2>
                                <p>
                                    टीडीएस का अर्थ हैं जब आप हर महीने अपना वेतन पाते हैं तो उसमें से कुछ प्रतिशत रकम पहले से ही कट जाती हैं। वह कटी हुई रकम कर के रूप में सरकार तक पहुँचती हैं और उस रकम को ही TDS कहते है। कर जो आपकी आवक के स्त्रोत से ही कट जाता हैं और उस कर का रेकॉर्ड आपके नाम पर होता हैं।
                                </p>
                                <p>
                                    आयकर अधिनियम के अंतर्गत जिन भुगतानो का उल्लेख हैं उन सब में TDS कटता हैं। आपका नियोजक आयकर विभाग के स्लैब रेट्स के हिसाब से टीडीएस काटता हैं। बैंक 10 प्रतिशत तक टीडीएस काटती हैं और पैन कार्ड डिटेल्स ना होने पर 20 प्रतिशत। आयकर विभाग ने एक सीमा तय की हैं कि अगर किसी व्यक्ति की कर योग्य आय कर, योग्य कर सीमा से कम हैं तो उस व्यक्ति को कर नहीं भरना होगा।
                                </p>
                                <p>
                                    जो कर स्त्रोत पर कटता हैं वो उस महीने के सातवें दिन तक सरकार को जमा हो जाना चाहिए। केन्द्रीय प्रत्यक्ष कर बोर्ड टीडीएस को आयकर अधिनियम 1961 के अंतर्गत नियंत्रित करता हैं।
                                </p>

                                <h2>
                                    टीडीएस के फायदें
                                </h2>
                                <p>
                                    सरकार तक धन पहुंचता हैं। <br />
                                    टीडीएस भरने से कर कि चोरी रुकने में मदद मिलती हैं। <br />
                                    टीडीएस भरने से कर कि चोरी रुकने में मदद मिलती हैं।
                                </p>
                                <p>
                                    टीडीएस तब नहीं कटता जब भुगतान कोई सरकारी विभाग को किया जाता हैं। टीडीएस सर्टिफ़िकेट एक प्रमाण-पत्र हैं यानि जो टीडीएस काटता हैं उसके द्वारा दिया जाता हैं जिसमें कितना टीडीएस काटा गया और जमा किया गया उसका उल्लेख होता हैं।
                                </p>
                                <p>
                                    जैसा की आप जान चुके हैं TDS क्या होता है
                                </p>
                                <p>
                                    अगर आप टीडीएस भरने की सेवा प्रदान करना चाहते हैं तो आप हमारे पोर्टल Digital Gramin Seva से जुड़कर टीडीएस रेतुर्न की सेवाऔर बहुत सी डिजिटल सेवाएं अपने ग्रामीण क्षेत्रो में प्रदान कर सकते हैं
                                </p>
                                <p>
                                    Digital Gramin Seva के portal पर रजिस्ट्रेशन करने के लिए निचे दिए गए लिंक पर क्लिक करें
                                </p>

                                <p>
                                    <b>
                                        ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                    </b>
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Tds