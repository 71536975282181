import React from 'react'
import Silder from './Silder'

const CreditCard = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards9.png")} alt="" /></span>
                                <span className='Main_Title'>Creadit Card Apply</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> Digital Gramin Seva Provides Credit Cards Facility </h3>
                                <p>
                                Apply for India's top bank's credit cards with Digital Gramin Seva. Compare amongst the best credit cards with instigative offers and rewards on Shopping, Movie, Travel, Petrol, Air Miles, etc.
                                </p>

                                <h3> Types of Credit Cards Offered by Digital Gramin Seva </h3>
                                <p>
                                Whether you're an avaricious shopper, a frequent traveller, or a movie buff, we offer credit cards that meet your specific requirements. Browse through this section and take your pick from our expansive range of credit cards.
                                </p>

                                <h2> INSTANT APPROVAL OF CREDIT CARDS </h2>
                                <h6> Benefits of Credit Cards </h6>
                                <ul>
                                    <li> Life Benefits Enjoy reduction on shopping, dining, pictures and further. </li>
                                    <li> Travel Benefits Get complimentary access to field couches, disclaimer of energy cargo and abatements on energy purchases. </li>
                                    <li> Prices & Service Enjoy cash rewards, Vengeance offers, 24x7 concierge services and much further. </li>
                                    <li> Safety and security Security of a chip card to cover you against fraud. </li>
                                </ul>

                                <h2> How it Works </h2>
                                <ul>
                                    <li> Select a Credit Card Choose a Credit Card which best suits your needs and lifestyle. </li>
                                    <li> Check Eligibility Fill the basic information to check your credit card eligibility </li>
                                    <li> Get Approved Instantly Fill in bank related additional details & get your credit card </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default CreditCard