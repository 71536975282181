import React from 'react'
import "./Home.css"
import Silder from './Silder'

const Home = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0 d-none d-sm-block" >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active  SILDERHEIGHT" style={{ backgroundImage: `url(${require("../Img/slider1.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', height: "500px" }}>
                            </div>

                            <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../Img/slider2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "500px" }}>
                            </div>

                            <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../Img/slider3.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "500px" }}>
                            </div>

                            <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../Img/slider4.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "500px" }}>
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon  " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                            <span className="carousel-control-next-icon " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>

                <div className="container-fluid p-0 d-sm-none d-block" >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active  " style={{ backgroundImage: `url(${require("../Img/slider1-mobile.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center ', height: "550px" }}>
                            </div>

                            <div className="carousel-item  position-relative " style={{ backgroundImage: `url(${require("../Img/slider2-mobile.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "550px" }}>
                            </div>

                            <div className="carousel-item  position-relative " style={{ backgroundImage: `url(${require("../Img/slider3-mobile.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "550px" }}>
                            </div>

                            <div className="carousel-item  position-relative " style={{ backgroundImage: `url(${require("../Img/slider4-mobile.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ', height: "550px" }}>
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon  " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                            <span className="carousel-control-next-icon " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container p-lg-3 p-3 ">
                        <div className='text-center'>
                            <h2 className='Open-Sans-SerifBOLD mb-3 mt-lg-3' style={{ fontSize: "2.4rem", color: "orange", fontWeight: '800' }} > Digital Gramin Seva <span className='' style={{ color: "green" }} >Provides 50+ Services</span> </h2>
                            <div className='d-flex justify-content-center align-items-center ' >
                                <div className='' style={{ borderTop: "3px solid orange", width: "3%" }}>
                                </div>
                            </div>
                        </div>
                        <div className="row p-lg-4 mt-3 d-flex justify-content-center align-items-center">
                            <div className="col-lg-4 p-0 col-md-6">
                                <div className='text-white d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: "#FF6700", border: "1px solid #ddd", height: "250px" }} >
                                    <div className='d-flex justify-content-start align-items-center p-2'>
                                        <img className='' style={{ width: "43%" }} src={require("../Img/2col1.png")} alt="" />
                                        <h4 className='ms-2'>Banking <br /> Services</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-0 col-md-6">
                                <div className='text-dark d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: "#fff", border: "1px solid #ddd", height: "250px" }} >
                                    <div className='d-flex justify-content-start align-items-center p-2'>
                                        <img className='' style={{ width: "43%" }} src={require("../Img/2col2.png")} alt="" />
                                        <h4 className='ms-2'>Bill Payment <br /> Services</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-0 col-md-6">
                                <div className='text-white d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: "#00A537", border: "1px solid #ddd", height: "250px" }} >
                                    <div className='d-flex justify-content-start align-items-center p-2'>
                                        <img className='' style={{ width: "43%" }} src={require("../Img/2col3.png")} alt="" />
                                        <h4 className='ms-2'>Government <br /> Services</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-0 col-md-6">
                                <div className='text-white d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: "#FF6700", border: "1px solid #ddd", height: "250px" }} >
                                    <div className='d-flex justify-content-start align-items-center p-2'>
                                        <img className='' style={{ width: "43%" }} src={require("../Img/2col4.png")} alt="" />
                                        <h4 className='ms-2'>Company <br />Registration<br /> Services</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-0 col-md-6">
                                <div className='text-dark d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: "#fff", border: "1px solid #ddd", height: "250px" }} >
                                    <div className='d-flex justify-content-start align-items-center p-2'>
                                        <img className='' style={{ width: "43%" }} src={require("../Img/2col5.png")} alt="" />
                                        <h4 className='ms-2'>Tax Filing <br /> Services</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 p-0 col-md-6">
                                <div className='text-white d-flex justify-content-center align-items-center p-3' style={{ backgroundColor: "#00A537", border: "1px solid #ddd", height: "250px" }} >
                                    <div className='d-flex justify-content-start align-items-center p-2'>
                                        <img className='' style={{ width: "43%" }} src={require("../Img/2col6.png")} alt="" />
                                        <h4 className='ms-2'>Travel Booking <br /> Services</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container-lg p-lg-4 pb-3">
                        <div className="row p-lg-3">
                            <div className="col-lg-4 col-md-4">
                                <div className='mt-3'>
                                    <div className='d-flex align-items-center justify-content-center mb-2'>
                                        <span className='d-flex align-items-center justify-content-center ' style={{ backgroundColor: "#FF6700", height: "57px", width: "57px", padding: "13px", borderRadius: "30px 30px 0px 30px" }} >
                                            <img className='' style={{ width: "28px" }} src={require("../Img/bank.png")} alt="" />
                                        </span>
                                        <h4 className='ms-4'>Banking</h4>
                                    </div>
                                    <ul className='mt-lg-3 pt-3 text-center ' style={{ columnCount: "2", listStyleType: "none" }} >
                                        <li className='p-1'>
                                            <button className='Banking_Section_Common'>Bank Account Opening</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common' >Demat Account Opening</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Credit Card Apply</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common'  >Loan Apply</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Mini ATM</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Money Transfer</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Cash Deposit</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Micro ATM</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Aadhar pay</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className='Banking_Section_Common '  >AEPS</button>
                                        </li>
                                    </ul>
                                </div>

                                <div className='mt-5 pt-lg-5'>
                                    <div className='d-flex align-items-center justify-content-center mb-2'>
                                        <span className='d-flex align-items-center justify-content-center ' style={{ backgroundColor: "#FF6700", height: "57px", width: "57px", padding: "13px", borderRadius: "30px 30px 0px 30px" }} >
                                            <img className='' style={{ width: "28px" }} src={require("../Img/bank.png")} alt="" />
                                        </span>
                                        <h4 className='ms-4'>Government & Non-Government</h4>
                                    </div>
                                    <ul className='mt-lg-3 pt-3 text-center ' style={{ columnCount: "2", listStyleType: "none" }} >
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common'  >Aadhar Update</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common' >Aadhar Udyog</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >State Certificate</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common'  >Voter ID Card</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Driving Licence</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >PMKISAN</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Aadhar Center Apply</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >E-Shram Card Apply</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Labour Card</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Pan Card</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Passport</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className='Banking_Section_Common '  >EPFO</button>
                                        </li>
                                    </ul>
                                </div>

                                <div className='mt-5 pt-lg-5'>
                                    <div className='d-flex align-items-center justify-content-center mb-2 '>
                                        <span className='d-flex align-items-center justify-content-center' style={{ backgroundColor: "#FF6700", height: "57px", width: "57px", padding: "13px", borderRadius: "30px 30px 0px 30px" }} >
                                            <img className='' style={{ width: "28px" }} src={require("../Img/bank.png")} alt="" />
                                        </span>
                                        <h4 className='ms-4'>Company Registration</h4>
                                    </div>
                                    <ul className='mt-lg-3 pt-3 text-center ' style={{ listStyleType: "none" }} >
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Private Limited Registrtation</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common ' >Add/Remove Partner</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Change Busines Name</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Change Office Address</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >One Person Company</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='p-lg-5' >
                                    <img className='serviceIMG' src={require("../Img/service.png")} alt="" />
                                </div>

                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className='mt-3'>
                                    <div className='d-flex align-items-center justify-content-center mb-2'>
                                        <span className='d-flex align-items-center justify-content-center' style={{ backgroundColor: "#FF6700", height: "57px", width: "57px", padding: "13px", borderRadius: "30px 30px 0px 30px" }} >
                                            <img className='' style={{ width: "28px" }} src={require("../Img/bank.png")} alt="" />
                                        </span>
                                        <h4 className='ms-4'>Recharge & Bill Payment</h4>
                                    </div>
                                    <ul className='mt-lg-3 pt-3 text-center ' style={{ listStyleType: "none" }} >
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common'  >Mobile Recharge</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common' >DTH Recharge</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Electricity Bill Payment</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Water Bill Payment</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Insurance Enrollment</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >LIC Bill Payment</button>
                                        </li>
                                    </ul>
                                </div>

                                <div className='mt-5 pt-lg-5'>
                                    <div className='d-flex align-items-center justify-content-center mb-2'>
                                        <span className='d-flex align-items-center justify-content-center' style={{ backgroundColor: "#FF6700", height: "57px", width: "57px", padding: "13px", borderRadius: "30px 30px 0px 30px" }} >
                                            <img className='' style={{ width: "28px" }} src={require("../Img/bank.png")} alt="" />
                                        </span>
                                        <h4 className='ms-4'>Travel Booking</h4>
                                    </div>
                                    <ul className='mt-lg-3 pt-3 text-center ' style={{ listStyleType: "none" }} >
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >IRCTC (Train Booking)</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common ' >Bus Booking</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Hotel Booking</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Flight Booking</button>
                                        </li>
                                    </ul>
                                </div>

                                <div className='mt-5 pt-lg-5'>
                                    <div className='d-flex align-items-center justify-content-center mb-2'>
                                        <span className='d-flex align-items-center justify-content-center' style={{ backgroundColor: "#FF6700", height: "57px", width: "57px", padding: "13px", borderRadius: "30px 30px 0px 30px" }} >
                                            <img className='' style={{ width: "28px" }} src={require("../Img/bank.png")} alt="" />
                                        </span>
                                        <h4 className='ms-4'>Tax Filing</h4>
                                    </div>
                                    <ul className='mt-lg-3 pt-3 text-center ' style={{ listStyleType: "none" }} >
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >GST Registration</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common ' >GST Return</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >GST Correction</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common '  >Tan Registration</button>
                                        </li>
                                        <li className='p-1'>
                                            <button className=' Banking_Section_Common  '  >Income Tax Return</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='row'>
                                <div className=' text-center'>
                                    <div className='Buttonn'>
                                        <button className=' text-white Buttonn2' >Earn commission on every service</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid " style={{ backgroundImage: `url(${require("../Img/AboutSectionBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container  p-3 Open-Sans-Serif">
                        <div className="row text-white">
                            <div className='Open-Sans-Serif p-lg-5'>
                                <h2 className='' style={{ fontSize: "2rem" }} >About Digital Gramin Seva</h2>
                                <div className='d-flex  align-items-center ' >
                                    <div className='' style={{ borderTop: "3px solid orange", width: "3%" }}>
                                    </div>
                                </div>
                                <p className='mt-4' style={{ fontSize: "1.1rem" }} >Digital Gramin Seva is a leading provider of banking, government, and non-government services in India. The company was founded in 2019 by two entrepreneurs, Mr. Narayan Singh and Mrs. Kumari, with the goal of providing quality banking and financial services to the rural population of India. Since then, we have expanded our services to include government and non-government services. Digital Gramin Seva started its operations with a small team of 20 employees and has now grown to a team of over 200 employees.</p>
                                <p className='mt-lg-4' style={{ fontSize: "1.1rem" }} >Digital Gramin Seva offers a range of banking services such as aadhar based payment system, cash deposit, money transfer, aadhar pay, and account opening. The company also provides government services such as filing of income tax returns, PAN card application, and passport application. Digital Gramin Seva also offers non-government services such as bill payment, mobile recharge, and utility bill payment.</p>
                            </div>

                            <div className="col-lg-6">
                                <div className='d-flex ms-lg-3'>
                                    <div className='p-3'>
                                        <div className='Years5'  >
                                            <h2 className='year5India'  >5+ Years <br /> Experience </h2>
                                        </div>
                                    </div>

                                    <div className='p-3'>
                                        <div className='India1'  >
                                            <h2 className='year5India'  >India's No #1 <br /> Digital Service Provider </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-6">
                                <img className='w-100 IndiaIMG' src={require("../Img/india.png")} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid map-height mb-5" style={{ backgroundImage: `url(${require("../Img/AboutSectionBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container p-lg-4 Open-Sans-Serif">
                        <div className="row g-5 p-lg-5 d-flex justify-content-center align-items-center text-white">
                            <div className="col-lg-4 col-md-4">
                                <img className='w-100' style={{ height: '20rem' }} src={require("../Img/india_map.png")} alt="" />
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className=''>
                                    <h3 className='' style={{ fontSize: "2.6rem", lineHeight: "60px", fontWeight: "800" }} > 7 लाख से भी ज्यादा <span className='' style={{ color: "#FF6700" }} > भारतियों </span> का योगदान <span className='' style={{ color: "#00A537" }} > भारत </span> को डिजिटल बनाने में । </h3>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className=''>
                                    <ul className='retdt-list'>
                                        <li className='w-100' style={{ backgroundColor: "#FF6700" }} > <span> 7,00,000+ </span> Retailer </li>
                                        <li className='' style={{ backgroundColor: "#424242" }} > <span> 2,000+ </span> Distributor </li>
                                        <li className='' style={{ backgroundColor: "#01589C" }} > <span> 200+ </span>  Super Distributor </li>
                                        <li className='' style={{ backgroundColor: "#00A537" }} > <span> 10+ </span> Zonal Head </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid mt-lg-5" style={{ backgroundImage: `url(${require("../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container p-lg-4 Open-Sans-Serif">
                        <div className="row g-5 p-lg-5 d-flex justify-content-center align-items-center text-white ">
                            <div className="col-lg-12 p-0">
                                <div className='become_retailer_top' >
                                    <div className=' become_retailer ' >
                                        <h2 className='become_retailerh2' > Become a Retailer </h2>
                                        <p className='' style={{ fontSize: "1rem", color: "#fff", fontWeight: "800" }} >
                                            As a retailer in Digital Gramin Seva, you will have the opportunity to provide banking, bill payment, government and tax filing services to your community, and earn an attractive income in return.
                                        </p>
                                        <p className='' style={{ fontSize: "1rem", color: "#fff" }}>
                                            To become a retailer in Digital Gramin Seva, you will need to meet the eligibility criteria set by the digital gramin seva and complete the registration process. Some of the key requirements include:
                                        </p>

                                        <div className="col-lg-12 p-0">
                                            <div className="row d-flex justify-content-around">
                                                <div className="col-lg-9">
                                                    <ul>
                                                        <li> A computer or laptop </li>
                                                        <li> An android device </li>
                                                        <li> An Aadhaar card </li>
                                                        <li> PAN card </li>
                                                    </ul>
                                                    <h5 className='mt-4' style={{ fontSize: "1.3rem", lineHeight: "30px", color: "#fff", fontWeight: "800", width: "80%" }}> As a retailer in Digital Gramin Seva, you will have access to a range of services that you can offer to your customers. These services include: </h5>
                                                    <ul className='mt-4' style={{ backgroundColor: "#00A537", padding: "1rem 3rem", listStyleType: "none", borderRadius: "80px" }} >
                                                        <li style={{ fontSize: "1rem", lineHeight: "28px", color: "#fff", fontWeight: "800" }}> <i class="fa-solid fa-check me-2" style={{ fontSize: "14px" }} ></i>  Banking Services </li>
                                                        <li style={{ fontSize: "1rem", lineHeight: "28px", color: "#fff", fontWeight: "800" }} > <i class="fa-solid fa-check me-2" style={{ fontSize: "14px" }}></i> Bill payments for electricity, water, and other utilities </li>
                                                        <li style={{ fontSize: "1.1rem", lineHeight: "28px", color: "#fff", fontWeight: "800" }} > <i class="fa-solid fa-check me-2" style={{ fontSize: "14px" }}></i>  Government services such as PAN card application </li>
                                                        <li style={{ fontSize: "1.1rem", lineHeight: "28px", color: "#fff", fontWeight: "800" }} > <i class="fa-solid fa-check me-2" style={{ fontSize: "14px" }}></i>  Tax filing services for individuals and businesses </li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-3">
                                                    <img className='w-100' src={require("../Img/retailer.png")} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="row d-flex justify-content-center Open-Sans-Serif">
                                    <ul className=' text-center mb-5' style={{ listStyle: "none" }} >
                                        <li className='Open-Sans-Serif no_need'  >
                                            <img className='me-3' style={{ width: "40px" }} src={require("../Img/correct_icon.png")} alt="" />
                                            No need any type of Certificate or Exam
                                        </li>
                                        <li className='Open-Sans-Serif no_need' >
                                            <img className='me-3' style={{ width: "40px" }} src={require("../Img/correct_icon.png")} alt="" />
                                            Instant ID Activate
                                        </li>
                                        <li className='Open-Sans-Serif no_need'  >
                                            <img className='me-3' style={{ width: "40px" }} src={require("../Img/correct_icon.png")} alt="" />
                                            Earn More than Rs 25,000 per month
                                        </li>
                                    </ul>

                                    <div className="col-lg-12">
                                        <div className="row g-3 d-flex justify-content-lg-around align-items-center business-card "  >
                                            <div className="col-lg-5 col-md-6">
                                                <div className='row g-5 d-flex justify-content-center align-items-center'  >
                                                    <div className='col-lg-5'>
                                                        <img className='aadaharPanImg' src={require("../Img/aadhar-card.png")} alt="" />
                                                    </div>
                                                    <div className='col-lg-1 text-danger plus' style={{ fontSize: "60px", fontWeight: "200" }} >  + </div>
                                                    <div className='col-lg-5'>
                                                        <img className='aadaharPanImg' src={require("../Img/pan_card.png")} alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-6">
                                                <div className='text-dark'>
                                                    <h4 className='mb-2' style={{ fontSize: "2.3rem", fontWeight: "600", color: "#FF6700" }} >  <span className='text-dark'> An </span> Aadhar Card <span className='text-dark'> + </span> Pan Card </h4>
                                                    <h5 className='' style={{ fontSize: "1.2rem", fontWeight: "800" }}> will open your business </h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='text-center p-2 mt-lg-4 pt-4'>
                                        <h2 className='text-dark InterBoldFontFamily Start_Your_Business'  > <i>Start Your Business With Us</i> </h2>
                                    </div>

                                    <div className='text-center p-2 mt-lg-3 mb-4'>
                                        <button className=' text-white InterBoldFontFamily' style={{ backgroundImage: "linear-gradient(to right, #00a537 , #ff6700)", borderRadius: "0px", border: 'none', fontSize: "2.1rem", padding: "12px 30px" }} >REGISTER NOW</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: "linear-gradient(to right, #01589c , #002046)" }} >
                    <div className="container p-lg-5">
                        <h3 className='text-center text-white mb-3 InterBoldFontFamily district'  > District Franchise </h3>
                        <div className='d-flex justify-content-center align-items-center mt-3' >
                            <div className='' style={{ borderTop: "3px solid orange", width: "10%" }}>
                            </div>
                        </div>

                        <div className="row text-white mt-lg-5 pt-3">
                            <div className="col-lg-7">
                                <div className='pb-2'>
                                    <h4 className='InterBoldFontFamily'> Start Your Business with Digital Gramin Seva </h4>
                                    <p className='mt-3'> By getting district franchise business, you will get a wellestablished business model. </p>
                                    <p> This results in fully measurable results before the investment, as well as the mentorship of an experienced entrepreneur for the duration of the contract. </p>
                                </div>

                                <div className='d-flex mt-3 mb-3' style={{ columnGap: "22px" }}>
                                    <div className='d-flex align-items-center' style={{ backgroundColor: "#FF6700", borderRadius: "40px", padding: "0.813rem 1rem", columnGap: "10px" }} >
                                        <img className='' style={{ width: "30px" }} src={require("../Img/low-investment.png")} alt="" />
                                        <h5 className='' style={{ fontSize: '1.2rem', fontWeight: "800" }} > Minimum Investment </h5>
                                    </div>

                                    <div className='d-flex align-items-center' style={{ backgroundColor: "#FF6700", borderRadius: "40px", padding: "0.813rem 1rem", columnGap: "10px" }} >
                                        <img className='' style={{ width: "30px" }} src={require("../Img/high_icon.png")} alt="" />
                                        <h5 className='' style={{ fontSize: '1.2rem', fontWeight: "800" }} > Minimum Investment </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <img className='' style={{ width: "90%" }} src={require("../Img/franchise.png")} alt="" />
                            </div>
                        </div>

                        <div className="row text-white mt-lg-4 pt-3 pb-4">
                            <div className='get-card'>
                                <h3 className='mb-3 InterBoldFontFamily' style={{ fontSize: "2.4rem" }} > You Will Get: </h3>
                                <ul className='get-list'>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> Agent ID </li>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> Certificate </li>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> IRCTC ID </li>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> Franchise Agreement </li>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> Mini ATM </li>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> ID Card </li>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> Templates </li>
                                    <li className='' style={{ fontSize: "1.3rem" }}> <i class="fa-solid fa-star" style={{ fontSize: "18px" }} ></i> T-Shirt </li>
                                </ul>
                            </div>

                            <div className='unl-creation mt-5'>
                                <ul>
                                    <li> Unlimited Distributor Creation </li>
                                    <li> Unlimited Retailer Creation </li>
                                    <li> Anyone who joins as a retailer or distributor from your area will be added to your team </li>
                                    <li> You will get attractive commission on team's work </li>
                                </ul>
                            </div>

                            <div className='text-center p-2 mt-lg-4 pt-3'>
                                <button className=' text-white InterBoldFontFamily' style={{ backgroundColor: "#FF6700", borderRadius: "16px", border: 'none', fontSize: "2rem", padding: "12px 40px" }} >APPLY NOW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=''>
                <div className="container-fluid digital-seva">
                    <div className="container  p-lg-5">
                        <h3 className='text-center text-white mb-3 InterBoldFontFamily title-4'  > DIGITAL GRAMIN SEVA: </h3>

                        <div className='digitalcontent mt-5'>
                            <h3 className='text-center text-white  InterBoldFontFamily'  > Empowering Rural Communities with Financial Inclusion </h3>
                            <div className='d-flex justify-content-center align-items-center mt-3' >
                                <div className='' style={{ borderTop: "3px solid white", width: "10%" }}>
                                </div>
                            </div>
                            <p className='mt-4 text-center' style={{ color: "#fff", fontSize: "19px" }}>
                                Our mission is to bring financial services to everyone, everywhere and to create a progressive society by building the largest agent banking network in the country. With Digital Gramin Seva, we aim to bridge the gap between rural communities and financial services, providing access to essential financial tools and resources.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default Home