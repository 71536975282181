import React from 'react'
import "./Blogs.css"
import { NavLink } from 'react-router-dom'

const Blogs = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container p-lg-4">
                        <div className="row g-4 p-lg-4">
                            <div className='mb-lg-5 pt-4 mb-2'>
                                <h2> Blogs </h2>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content'>
                                    <h3> Digital India </h3>
                                    <h5> Sep 2, 2023 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        Digital India is a flagship program of the Government of India with a vision to transform India into a digitally empowered society and knowledge
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none' to='/digitalindia'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> Caste Certificate </h3>
                                    <h5> Sep 2, 2023 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        A caste Certificate is an important document issued by the Government of India to individuals belonging to SC, ST, and OBC.
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none' to='/castecertificate'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> OPC Business </h3>
                                    <h5> Sep 2, 2023 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        OPC stands for One Person Company. It is a type of business structure that allows individuals to start and operate a company on their own.
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/opcbusiness'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> About Digital Gramin Seva </h3>
                                    <h5> Mar 12, 2022 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        Digital Seva or Digital Gramin Seva is a digital platform where people can provide many services through customer service center in their area.
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/aboutdigitalgraminseva'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> Digital Bharat </h3>
                                    <h5> Mar 12, 2022 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        How Digital India Works: Digital India greatly facilitated the digitization of data which helped in making things fast and efficient, saving paperwork and time and also human effort
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/digitalbharat'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> What is AEPS ? AEPS या हैं </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        इस आर्टिकल में आप जानेंगे AEPS क्या है, इससे होने वाले फायदे और AEPS में आपको क्या क्या सुविधाएं मिलती हैं, इस AEPS Service को कैसे आप ले सकते हैं, जैसा की आप जानते है हमारे भारत देश में ग्रामीण क्षेत्रों में बैंकों की संख्या: बहुत कम है |
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none' to='/whatisaeps'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> BBPS सिस्टम क्या है ? </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        BBPS का फुल फॉर्म Bharat Bill Payment System हैं? इसका हिंदी में अर्थ है (भारत बिल भुगतान प्रणाली) BBPS System को नेशनल कॉरपोरेशन ऑफ इंडिया ने लांच किया था BBPS भारत में बिल भुगतान प्रणाली है जो बिल भुगतान सेवा प्रदान करती है
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/bbps'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> What is Micro ATM ? </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        इस Article में आप जानेंगे Micro-ATM क्या है, और इससे होने वाले फायदे , इस Micro-ATM को कैसे आप ले सकते हैं, इस Micro-ATM को लेकर कैसे आप अपनी शॉप मिनी बैंक में बदल कर अच्छा कमिशन कमा सकते हैं
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/microatm'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> Money Transfer </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        इस आर्टिकल में आप जानेंगे Money Transfer क्या है, Money Transfer करने के लिए किन System को इस्तेमाल में लिया जाता है , आप कैसे Money Transfer Business स्टार्ट कर सकते हैं
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/moneytransferblog'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> What is Pan Card ? </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        इस आर्टिकल में आप जानेंगे कैसे आप अपना खुद का ग्राहक सेवा केंद्र खोल कर पैन कार्ड की सुविधा अपने आस पास के लोगो को प्रदान कर सकते, पैन कार्ड क्या चीज़ है
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/pancard'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> टीडीएस क्या है? </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        जब कोई रुपयो का भुगतान करता हैं जैसे – किराया, दलाली, फीस, वेतन, ब्याज आदि तब उस भुगतान में से कुछ प्रतिशत राशि कर के तौर पर काटनी होती हैं, उस कटी हुई राशि को टीडीएस (TDS)
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/tds'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> What is GST? GST क्या है? </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        GST का Full Form (Good And Service Tax) होता है इसका हिंदी में अर्थ (वस्तु एवं सेवा कर) है यह देश भर में वस्तुओं और सेवाओें की बिक्री पर लगने वाले GST का फायदा यह है
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/whatisgst'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> Cash Deposit </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        इस आर्टिकल में आप जानेंगे आप कैसे Cash Deposit की सुविधा प्रदान कर सकते हैं और इससे आपको और आपके ग्राहक को क्या फायदे मिलते हैं
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/cashdepositblog'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> What is CSP? </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        अगर आप बेरोज़गार हैं और पैसे कमाने के रास्ते ढूंढ रहे हैं तो आप CSP (कस्टमर सर्विस पॉइंट ) यानी Mini Bank खोलकर पैसा कमा सकते हैं ।
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/csp'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> G2C Services </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        आप अपने कस्टमर्स के आधार कार्ड में करेक्शन कर सकते हैं बड़ी ही आसान तरीके से
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/GCServicesBlog'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className='Blog-Content' >
                                    <h3> What is FSSAI? </h3>
                                    <h5> Feb 22, 2021 by IMOC Digital Services Private Limited </h5>
                                    <p>
                                        एफएसएसएआई (FSSAI) यानि Food Safety and Standards Authority of India. एफएसएसएआई एक भारतीय स्वतंत्र निकाय हैं जो भारत में हर तरह के खाद्य पदार्थ के उत्पादन, बिक्री, संचयन और आयात में सुरक्षा, पौषण और स्वच्छता बनाए रखने के लिए
                                    </p>
                                    <button className='BtnHover'>
                                        <NavLink className='text-white text-decoration-none ' to='/fssairegistrationn'>
                                            Read More
                                        </NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Blogs