import React from 'react'
import Silder from './Silder'

const MobileRecharge = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>Hotel Booking</span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <p>
                                        Digital Gramin Seva provides you with easy access. We can
                                        complete Mobile & DTH Recharge in less than 10 seconds. You
                                        can instantly recharge your mobile and get an attractive
                                        commission on every transaction.
                                    </p>
                                    <p>
                                        Our company will provide you mobile & DTH recharge portal
                                        software for your business.
                                    </p>

                                    <h2>Features:</h2>
                                    <ul className="bc-service-list m-0">
                                        <li>Quick & Easy</li>
                                        <li>One-stop shop solution for online recharge.</li>
                                        <li>Get attractive commission on every recharge</li>
                                    </ul>
                                    <h3>How can you start a business of mobile recharge?</h3>

                                    <ul className="bc-service-list m-0">
                                        <li>First you need to connect with Digital Gramin Seva</li>
                                        <li>
                                            To join you have to click on the registration button
                                            above.
                                        </li>
                                        <li>
                                            After registration is complete, you will get Retailer ID
                                            password
                                        </li>
                                        <li>
                                            After getting the login ID, you have to login on Digital
                                            Gramin Seva Portal.
                                        </li>
                                        <li>
                                            After login, You can recharge your customer's Mobile & DTH
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default MobileRecharge