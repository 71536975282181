import React from 'react'
import Silder from './Silder'

const LabourCard = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                        <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> Labour Card </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <p>
                                        You can apply labour card to anyone in Digital Gramin Seva.
                                    </p>
                                    <p>
                                        Labour card is an identification card of laborers made by
                                        the Labor Department. Through this card, the workers are
                                        given the benefits of numerous schemes of the Labor
                                        Department and the state government.
                                    </p>

                                    <h2>How to Get Labour Card?</h2>
                                    <ul className="bc-service-list m-0 ">
                                        <li>
                                            The age limit to get your labour card is between 18 to 60
                                            years.
                                        </li>
                                        <li>
                                            The registration Officer Labor President at the District
                                            Level & Labor Enforcement Officer at Block Level.
                                        </li>
                                        <li>
                                            The enrollment figure is RS 20, yearly donation at the
                                            rate of 50 paisa per month in lump sum Rs 30 for 5 times
                                            i.e. Rs 50 Bhukteya.
                                        </li>
                                    </ul>

                                    <h3>Important Document to Get Labour Card</h3>
                                    <ul className="bc-service-list m-0">
                                        <li>Age Certificate</li>
                                        <li>Aadhar Card</li>
                                        <li>
                                            Photocopy of Bank Passbook (NPCI link Account Number)
                                        </li>
                                        <li>90 Days of Work Certificate by Employment.</li>
                                        <li>Two Color Passport Size Photographs.</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default LabourCard