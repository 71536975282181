import React from 'react'
import "./LegalDocs.css"
import { NavLink } from 'react-router-dom'

const LegalDocs = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container p-lg-4">
                        <div className="row p-lg-4">
                            <div className='mb-lg-5 mb-3'>
                                <h2 className='fw-bold'> Legal Documents </h2>
                            </div>
                            <div className="col-lg-11">
                                <ul className='list-group'>
                                    <NavLink className='text-decoration-none' to='https://digitalgraminseva.in/Download/Documents/IMOC%20CERTIFICATE%20OF%20INCORPORATION.PDF'>
                                        <li className='list-group-item'>
                                            <i class="fa-solid fa-file-pdf fs-3"></i>
                                            <span className='ms-lg-3 Legal-Docs-Hover' style={{ fontSize: "20px", fontWeight: "400" }}>
                                                Certificate Of Incorporation
                                            </span>
                                        </li>
                                    </NavLink>

                                    <NavLink className='text-decoration-none' to='https://digitalgraminseva.in/Download/Documents/GST%20Certificate%20IMOC.pdf'>
                                        <li className='list-group-item'>
                                            <i class="fa-solid fa-file-pdf fs-3"></i>
                                            <span className='ms-lg-3 Legal-Docs-Hover' style={{ fontSize: "20px", fontWeight: "400" }}>
                                                GST Certificate
                                            </span>
                                        </li>
                                    </NavLink>

                                    <NavLink className='text-decoration-none' to='https://digitalgraminseva.in/Download/Documents/IMOC%20MSME.PDF'>
                                        <li className='list-group-item'>
                                            <i class="fa-solid fa-file-pdf fs-3"></i>
                                            <span className='ms-lg-3 Legal-Docs-Hover' style={{ fontSize: "20px", fontWeight: "400" }}>
                                                MSME
                                            </span>
                                        </li>
                                    </NavLink>

                                    <NavLink className='text-decoration-none' to='https://digitalgraminseva.in/Download/Documents/Pan%20Card.PDF'>
                                        <li className='list-group-item'>
                                            <i class="fa-solid fa-file-pdf fs-3"></i>
                                            <span className='ms-lg-3 Legal-Docs-Hover' style={{ fontSize: "20px", fontWeight: "400" }}>
                                                Pan Card
                                            </span>
                                        </li>
                                    </NavLink>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default LegalDocs