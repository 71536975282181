import React from 'react'
import Silder from './Silder'

const HotelBooking = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> Hotel Booking </span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    Become a travel booking agent and get attractive commission on every booking
                                </p>
                                <p>
                                    Become an Agent & Get Access to Our big selection of Travel Services
                                </p>

                                <h2> Benefist Of Travel Booking Agent </h2>
                                <ul>
                                    <li> Invest less, earn More </li>
                                    <li> Hassle-free Booking </li>
                                    <li> Earn Without any fear of loss </li>
                                    <li> faster ticket Procedure </li>
                                </ul>

                                <h2> Bus Booking </h2>
                                <p>
                                    Being a licensed agent , you'll provide an entire set of travel services by using Travel booker platform. Book bus tickets for Deluxe, AC Luxury, Express, Volvo AC, Sleeper class and earn booking benefits.
                                </p>

                                <h2> Train Booking </h2>
                                <p>
                                    An authorized agent of IRCTC, allows you to book train tickets for all IRCTC operating zones. you'll book IRCTC train tickets and earn huge commissions. do not forget to see the required documents mandatory for train booking.
                                </p>

                                <h2> Hotel Booking </h2>
                                <p>
                                    Travel booker may be a comprehensive platform that permits you to book hotel accommodations. Deliver an entire package of booking services to your customers from booking tickets to designing convenient itineraries to arranging accommodations.
                                </p>

                                <h2> Flight Booking </h2>
                                <p>
                                    Become a home-based agent and book airline tickets for all national and international sectors supported customer requirements. Offer the simplest flight reservation prices to your customers from a dynamic consolidated flights inventory.
                                </p>

                                <h2> Become Travel Booking in 3 Simple Step: </h2>
                                <ul>
                                    <li> Fill In registration Form On Digital Gramin Seva portal </li>
                                    <li> Submit your documents </li>
                                    <li> receive your retailer ID & Start your travel business </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default HotelBooking