import React from 'react'
import Silder from './Silder'

const FssaiRegistration = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>FSSAI Registration</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h2> What is FSSAI? </h2>
                                <p>
                                    FSSAI (FSSAI) means Food Safety and Standards Authority of India. FSSAI is an Indian independent body responsible for maintaining safety, nutrition and hygiene in the production, sale, storage and import of all types of food items in India. 14 figures have a license number. Its rules are implemented from the Ministry of Health and Family Welfare. FSSAI Complete Form - "Food Safety and Standards Authority of India".
                                </p>

                                <h2> Benefits of FSSAI - </h2>
                                <ul>
                                    <li> The public gets pure food. </li>
                                    <li> Harmful and toxic substances do not reach the market. </li>
                                    <li> FSSAI is the main and sole body of norms for food safety and food regulations. </li>
                                    <li> The same license is required for different types of food items in an area. </li>
                                    <li> Having a FSSAI license with any food seller gives its customers confidence in that trust. </li>
                                </ul>

                                <h2> The types of FSSAI registration and licensing are- </h2>
                                <ul>
                                    <li> Basic FSSAI Registration </li>
                                    <li> State FSSAI Registration </li>
                                    <li> Central FSSAI Registration </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default FssaiRegistration