import React from 'react'
import Silder from './Silder'

const AadharUdyog = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                        <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> Aadhar Udyog </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <p>
                                        Udyog Aadhaar comes during a sort of a Certificate and it's
                                        12 digit license number that's circulated by the Ministry of
                                        Micro, Small and Medium Enterprises (MSME) to the prevailing
                                        or new small and medium enterprises who have initiated and
                                        completed the method for registration. the most reason
                                        behind launching the Udyog Aadhar is to maximise the
                                        advantages to the tiny and medium scale industries In India,
                                        who are registered with Ministry of Micro, Small and Medium
                                        Enterprises.
                                    </p>

                                    <h2>Benefits</h2>
                                    <ol className="bc-service-list m-0">
                                        <li>Excise exemption</li>
                                        <li>The exemption under the tax laws</li>
                                        <li>
                                            50% reduction in fee for filing patents and trademarks
                                        </li>
                                        <li>Credit guarantee scheme</li>
                                        <li>Protection from delayed payments </li>
                                        <li>
                                            Loans without guarantee, low-5.interest rates on loan{" "}
                                        </li>
                                        <li>
                                            Financial support for six .participating in foreign
                                            business exposure from the govt of India.
                                        </li>
                                        <li>Subsidies provided to extend the company's revenue</li>
                                        <li>Concession in electricity bills</li>
                                        <li>
                                            The exemption provided when applying for state tenders
                                        </li>
                                        <li> Octroi benefits</li>
                                    </ol>

                                    <h3>Udyog aadhar Benefits to small and medium business</h3>
                                    <p>
                                        Once registered with Msme and receives a Udyog aadhar , the
                                        micro, small or the medium enterprise would be eligible for
                                        all government scheme benefits like without guarantee loan,
                                        easy loan, the loan with the low rate of interest. The Micro
                                        and little Enterprises also are eligible for collateral-free
                                        loans up to Rs. 1 crore under the Credit Guarantee Fund
                                        Trust for Micro and little Enterprises (CGTMSE Scheme). this
                                        is often a collateral free loan and therefore the Central
                                        Government takes guarantee of the borrower and to enhance
                                        the timely funds’ availability to the MSME Sector, the govt
                                        of India has classified loans to Micro and little
                                        Enterprises under priority sector lending. The banks are
                                        required to possess 20% year on year growth in lending to
                                        Micro and little Enterprises.The MSME registered enterprises
                                        get the support from the govt to participate within the
                                        foreign expo.The enterprise is additionally entitled to the
                                        govt subsidies as Central Government and State Governments
                                        announced under different schemes and subsidies for the MSME
                                        sector from time to time.The Government of India, by the way
                                        of Public Procurement Policy, 2012 provides that each one
                                        Central Government Ministries, Departments, and Public
                                        Sector Undertakings shall give Preference to Micro and
                                        little Enterprises in Government tenders and will procure
                                        the minimum of 20 percent of their total annual value of
                                        products or services from Micro and little Enterprises.Micro
                                        and little enterprises also enjoy Other Subsidies from State
                                        Governments like extended credit facilities, Industrial
                                        extension support, and services, Assistance in marketing,
                                        Assistance for construction of industries in underdeveloped
                                        areas, Technical consultancy, assistance within the capital,
                                        Excise exemption and Exemption under tax laws etc.
                                    </p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default AadharUdyog