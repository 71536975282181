import React from 'react'
import Silder from './Silder'

const AadharPay = () => {
    return (
        <>
            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards1.png")} alt="" /></span>
                                <span className='Main_Title'>Aadhaar Pay</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                Aadhaar Pay is the easiest way to receive payments from your customers. To confirm the transaction, you need the Aadhaar number of the customer . To pay, just enter your Aadhaar number, bank name and place your finger on yours to check the quality of your biometric device.You are connected to the system. The application automatically collects bank accounts using the customer's Aadhaar number, deletes the customer's bank account and calculates the balance of the Digital Gramin Seva Wallet
                                </p>

                                <h2> Features of Aadhaar Pay </h2>
                                <ul>
                                    <li> Instant transfer, 24X7 availability </li>
                                    <li> There are no other tools that customers need to make payments, such as cards and smartphones </li>
                                    <li> Safe And Secure as it is based on biometric authentication. </li>
                                </ul>

                                <h2> Benefits to merchants </h2>
                                <ul>
                                    <li> Seamless fund collection from customers </li>
                                    <li> Safe and secure </li>
                                    <li> Instant account to account fund transfer </li>
                                    <li> Saves time </li>
                                    <li> Easy to operate </li>
                                </ul>

                                <h2> Benefits to customers </h2>
                                <ul>
                                    <li> No need for a smart phone to make payments </li>
                                    <li> No need to remember PIN & Passwords </li>
                                    <li> Customer ease, easiest way of cashless payment </li>
                                    <li> No security issue as payments are done through biometric authentication </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>
        </>
    )
}

export default AadharPay