import React from 'react'
import Silder from './Silder'

const RationCard = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> Ration Card </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2>What's Ration Card?</h2>
                                    <p>
                                        A Ration Card is a veritably useful document for Indian
                                        citizens. It helps save plutocrat by abetting in the
                                        procurement of essential goods at a subsidised rate. It has
                                        also come an important tool of identification now-a-days.
                                        You may need to produce a dupe of your Ration Card as
                                        evidence of identification when applying for other documents
                                        like Domicile Certificate, for addition of your name in the
                                        Electoral Rolls,etc.
                                    </p>
                                    <p>
                                        Families living below the poverty line are entitled to Blue
                                        Cards, under which they can mileage special subventions. In
                                        addition to endless Ration Cards, States also issue
                                        temporary Ration Cards, which are valid for a specified
                                        number of months, and are issued for relief purposes.
                                    </p>

                                    <h3>Why is a Ration Card important?</h3>

                                    <p>
                                        Ration card details give an important evidence of Identity
                                        and Residence of citizens, it also used as evidence of
                                        applying for making a Domicile instrument, Birth
                                        Certificate, Voter ID cardetc. you can also check Ration
                                        card details by name.
                                    </p>
                                    <p>
                                        Ration cards offer identification as well entitle the holder
                                        to a Ration of food, energy, or other goods issued by the
                                        Government of India. They're primarily used when copping
                                        subsidized foodstuffs (wheat, rice, sugar) and kerosene.
                                    </p>

                                    <h3>Different Types of Ration Cards</h3>
                                    <p>
                                        Above Poverty Line (APL)-The Government will issue APL Cards
                                        (White Colour) to the families having total income above Rs
                                        per annum.
                                    </p>
                                    <p>
                                        Below Poverty Line (BPL)- Families having a total family
                                        income below Rs per annum have been linked under this order.
                                        Antyodya Anna Yojana (AAY)-Household with a impaired
                                        grown-up and no assured means of subsistence, landless
                                        labourers, borderline growers, crafters, craft men, widows,
                                        terminally ill personsetc. are covered under this scheme.
                                        Annapurna Yojana (AY)-Under this scheme, a destitute person
                                        with further than 65 times of age having no source of income
                                        and not serving the benefit of public Old Age Pension or
                                        State Pension Scheme can mileage 10 Kg of food grains free
                                        of cost every month.
                                    </p>

                                    <h3>Required Documents for New Ration Card</h3>
                                    <ul class="bc-service-list m-0">
                                        <li>Copy of aadhar card</li>
                                        <li>Copy of the first page of bank passbook</li>
                                        <li>Photo of all the family members</li>
                                        <li>Passport Size Photo of the head of family</li>
                                        <li>
                                            Address Proof (attested copy of PAN Card /Driving License
                                            / Bank)
                                        </li>
                                        <li>
                                            Passbook / Post Office Passbook /Municipal Holding receipt
                                            / Electricity Bill / Telephone Bill
                                        </li>
                                    </ul>

                                    <h3>How to avail the facility of making Ration Card</h3>
                                    <p>
                                        Follow the steps below for the Ration Card Online Apply
                                        process.
                                    </p>
                                    <p>Making Ration Card is very easy.</p>
                                    <ul class="bc-service-list m-0">
                                        <li>
                                            First You have to login to Digital Gramin Seva Portal and
                                            click on Ration card option.
                                        </li>
                                        <li>
                                            Secondly, Move forward and enter your further details as
                                            asked on the page.
                                        </li>
                                        <li>Complete all the details and upload documents.</li>
                                        <li>
                                            Finally, Click on the Submit button and wait for
                                            processing of Ration card.
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default RationCard