import React from 'react'
import Silder from './Silder'

const PrivacyPolicy = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/CommanBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='text-center mb-lg-5 mb-3'>
                                <h2 className='Open-Sans-SerifBOLD mb-3 mt-lg-3' style={{ fontWeight: "800", fontSize: "2rem" }}  > Privacy Policy  </h2>
                                <div className='d-flex justify-content-center align-items-center ' >
                                    <div className='' style={{ borderTop: "3px solid orange", width: "5%" }}>
                                    </div>
                                </div>
                            </div>
                            <div className='Footer-SubLinks'>
                                <p>
                                    Digital Gramin Seva is committed to respect privacy and choice of its User(s) and /or business associates while using this website. This statement highlights our privacy practices regarding Personal Information that we hold of our User and business associates.
                                </p>

                                <h2> Definition: </h2>
                                <p>
                                    For the purposes of this privacy policy, ‘Personal Information’ means and includes all information and sensitive personal data that can be linked to a specific individual or to identify any individual, such as name, address, mailing address, telephone number, email ID, credit card details, debit card details, cardholder name, card expiration date, information about User mobile phone, DTH service, data card, electricity connection and any details that may have been voluntarily provided by the User in connection with availing any of the services on this site. Exception: Any information which User provides to an open, public environment or forum including (without limitation) any blog, chat room, social networking site, albums, community, classifieds or discussion board, is not confidential, does not constitute Personal Information, and is not subject to protection under Privacy Policy.
                                </p>

                                <h2> Use of Personal Information: </h2>
                                <p>
                                    Digital Gramin Seva uses Personal Information for providing User with services explicitly requested by the User, to resolve disputes, troubleshoot concerns, for promoting safe services, collect money, assess User’s interest in our services, inform User about offers, services, updates, customize User’s experience, detect & protect Digital Gramin Seva against error, fraud and other criminal activity, enforce our terms and conditions, etc.
                                </p>
                                <p>
                                    Digital Gramin Seva may collect non-personal information about User whenever they use and interact with the services. Non-personal information may include the browser name, the type of computer and technical information about means used by the User to connect to Digital Gramin Seva services, such as the information about the operating system and the internet service providers utilized and other similar information.
                                </p>
                                <p>
                                    This information is aggregated and used to help Digital Gramin Seva provide more useful information to the User and to understand which part of the services, its products and services are of most interest.
                                </p>
                                <p>
                                    If Digital Gramin Seva does combine non-personal information with Personal Information, the combined information will be treated as Personal Information for as long as it remains combined.
                                </p>

                                <h2> How we use cookies? </h2>
                                <p>
                                    A cookie is a small file which asks permission to be placed on the User’s desktop/laptop/mobile device memory drive. Once the User agrees, the file is added and the cookie helps analyze web traffic. Cookies allow services to respond to the User as an individual. The services can tailor its operations to the User’s needs, likes and dislikes by gathering and remembering information about the User’s preferences.
                                </p>
                                <p>
                                    Cookies help us better understand User’s behavior, and tell us which part of the services Users have visited and facilitates and measure the effectiveness of products and services offered by us. We treat information collected by cookies and other technologies as non-personal information. However, to the extent that internet protocol (IP) addresses or similar identifiers are considered Personal Information by applicable law, we also treat these identifiers as Personal Information.
                                </p>
                                <p>
                                    We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our services in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.
                                </p>
                                <p>
                                    Overall, cookies help us provide User with a better experience of Digital Gramin Seva services, by enabling us to monitor which pages Users find useful and which the Users do not. A cookie in no way gives us access to the User’s desktop/laptop/mobile device or any information about the User, other than the data a User chooses to share with us.
                                </p>
                                <p>
                                    A User can choose to accept or decline cookies. Most web browsers automatically accept cookies, but a User can usually modify its browser setting to decline cookies if the User prefer. This may prevent the User from taking full advantage of the services.
                                </p>
                                <p>
                                    Advertisement appearing on the Digital Gramin Seva services may be delivered to the User by advertising partners, who may set cookies. These cookies allow the ad server to recognize the User computer each time they send the User an online advertisement to compile non-personal information about the User or others who use the User computer. This information allows advertisement networks to, among other things, deliver targeted advertisements that they believe will be of most interest to the User. This Policy does not cover the use of cookies by any advertisers.
                                </p>

                                <h2> User acceptance of these terms </h2>
                                <p>
                                    By using the Services, User signifies his/her acceptance of this Policy. If the User does not agree to this Policy, please do not use the Digital Gramin Seva services. User continued use of Digital Gramin Seva services following the posting of changes to this Policy will be deemed User acceptance of those changes.
                                </p>

                                <h2> Disclosure of Data / Information: </h2>
                                <p>
                                    Digital Gramin Seva will not share any personal or sensitive data with any external organization unless the same is necessary to enable it to provide services (to User or business associates) or to enable completion of a legitimate transaction or for reporting which may be necessary or required pursuant to applicable law or pursuant to the terms and conditions applicable to such information as agreed to with Digital Gramin Seva. Digital Gramin Seva may disclose information about User and / or business associates if directed by court, regulatory, statutory or other authorities. Digital Gramin Seva shall immediately take consent of the User and / or business associates giving sufficient details of the court order or regulatory or statutory or similar process before disclosing the information of User and / or business associates.
                                </p>

                                <h2> Digital Gramin Seva shall access, preserve and share your information </h2>
                                <p>
                                    1. With regulators, law enforcement or others in response to a legal request (e.g. a search warrant, court order or subpoena) if we have a good-faith/ belief that the law requires us to do so. This may include responding to legal requests from jurisdictions anywhere in India or abroad when we have a good-faith/ belief that the response is required by law in that jurisdiction, affects users in that jurisdiction and is consistent with Indian recognized standards.
                                </p>
                                <p>
                                    2. When we have a good-faith/belief that it is necessary to: detect, prevent and address fraud, unauthorized use of the products, violation of our Terms or Policies, or other harmful or illegal activity; to protect ourselves (including our rights, property or products and services), the User or others, including as part of investigation or regulatory enquiries; or to prevent death or imminent bodily harm. For example, if relevant, we exchange information with third-party partners about the reliability of your account to prevent fraud, abuse and other harmful activity on and off our products and services.
                                </p>
                                <p>
                                    Information that we receive about you (including financial transaction data) can be accessed and preserved for an extended period when it is the subject of a legal request or obligation, governmental investigation or investigations of possible violations of our Terms or Policies, or otherwise to prevent harm.
                                </p>

                                <h2> Observation of reasonable security practices: </h2>
                                <p>
                                    Digital Gramin Seva shall take all reasonable precautions to maintain the confidentiality of Personal Information / data pertaining to its User and business associates by imparting proper training to its employees and by providing restricted access to persons who may require access to such data strictly on a need to know basis.
                                </p>

                                <h2> Links to Other Websites: </h2>
                                <p>
                                    Website contains links to other websites. Please note that when User clicks on one of these links, User enters into another website for which Digital Gramin Seva takes no responsibility. Digital Gramin Seva encourages User to read the privacy statements/terms and conditions of all such websites as their policies/terms and conditions may be materially different from this Privacy Policy. User is solely responsible for maintaining the secrecy of its passwords and other membership account information.
                                </p>

                                <h2> New owner: </h2>
                                <p>
                                    If the ownership or control of Digital Gramin Seva or all or part of our products and services or their assets changes, we may transfer the User’s information to the new owner.
                                </p>

                                <h2> Updates to this Privacy Policy: </h2>
                                <p>
                                    Digital Gramin Seva may change the data privacy practices and update this privacy statement as and when the need arises, and the same shall be made available on the website. Users and business associates are requested to keep check on the same.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default PrivacyPolicy