import React from 'react'

const MoneyTransferBlog = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/money-transfer-banner.png")} alt="" />
                                <h3>
                                    Money Transfer
                                </h3>
                                <p>
                                    इस आर्टिकल में आप जानेंगे Money Transfer क्या है, Money Transfer करने के लिए किन System को इस्तेमाल में लिया जाता है , <br />
                                    आप कैसे Money Transfer Business स्टार्ट कर सकते हैं <br />
                                    मनी ट्रांसफर एजेंट ID को लेकर कैसे आप अपनी शॉप मिनी बैंक में बदल कर अच्छा कमिशन कमा सकते हैं
                                </p>
                                <p>
                                    अगर हम आज की बात करें तो हमारे लिए मनी ट्रांसफर सर्विस बहुत जरुरी हो चुकी है ,
                                </p>
                                <p>
                                    हमारे भारत का लगभग हर एक व्यक्ति हर एक बिजनेसमैन मनी ट्रांसफर का इस्तेमाल करता है ,
                                </p>
                                <p>
                                    ऐसे में मनी ट्रांसफर की सारी जानकारी न होना हमारे लिए भविष्य में नुकशानदायक हो सकती है , जिस चीज़ को हम उसे कर रहे हैं उस की पूरी जानकारी होना हमारे लिए अच्छा है तो चलिए हम आपको बताते है मनी ट्रांसफर क्या होता और ये कैसे काम करता है
                                </p>
                                <p>
                                    इस पोस्ट का ध्येय है आपको ऑनलाइन मनी ट्रांसफर के सिस्टंम के बारे में बताना जिससे की आप भविष्य में अगर मनी ट्रांसफर बिज़नेस शुरू करना चाहें तो आपको किसी भी तरह की कोई परेशानी न हो,
                                </p>
                                <p>
                                    इस पोस्ट में ये भी बताया गया है की आप अपना खुद का मनी ट्रांसफर बिज़नेस Digital Gramin Seva से जुड़कर कैसे कर सकते हैं
                                </p>

                                <h2>
                                    कौन कौन से वो सिस्टम जिनको ज्यादातर इस्तेमाल किया जाता है मनी ट्रांफसर करने पर ?
                                </h2>
                                <p>
                                    <b>
                                        1. RTGS
                                    </b> <br />
                                    (Real Time Gross Settlement)
                                </p>

                                <p>
                                    <b>
                                        2. NEFT
                                    </b>
                                    <br />
                                    (National Electronic Fund Transfer)
                                </p>
                                <p>
                                    <b>
                                        3. IMPS
                                    </b>
                                    <br />
                                    (Immediate payment service)
                                </p>

                                <h2>
                                    RTGS
                                </h2>
                                <h3>
                                    RTGS क्या है
                                </h3>
                                <p>
                                    RTGS का फुल फॉर्म Real Time Gross Settlement होता है
                                </p>
                                <p>
                                    इस RTGS System को बड़े बड़े Fund Transfer करने के लिए इस्तेमाल किया जाता है
                                </p>
                                <p>
                                    ज्यादा से ज्यादा व्यापारी इस सिस्टम का इस्तेमाल इसलिए करते है क्यूंकि ये एक ऐसा सिस्टम है जो कम समय में बड़ी राशि को एक अकाउंट से दुसरे अकाउंट में ट्रांसफर किया जा सकता है
                                </p>
                                <p>
                                    इस सिस्टम के तहत न्यूनतम(Minimum) 2 लाख Fund Transfer करना होता है
                                </p>

                                <h2>
                                    RTGS System द्वारा Fund transfer करने का समय ?
                                </h2>
                                <p>
                                    RTGS System दवारा Fund Transfer आप Banking Working Days में ही कर सकते है <br />
                                    Sunday को छोड़कर और सभी दिनों किया जा सकता है
                                </p>
                                <p>
                                    Monday To Friday - 9:00 AM To 4:30 PM <br />
                                    Saturday - 9:00 AM To 2:00 PM
                                </p>

                                <h2>
                                    RTGS द्वारा Money Transfer करने पर कितना चार्ज लगता है ?
                                </h2>
                                <p>
                                    अगर आप 2 लाख से 5 लाख के बीच मनी ट्रांसफर करते है तो आपको 30 रूपए चार्ज देना होगा <br />
                                    अगर आप 5 लाख से ज्यादा मनी ट्रांसफर करते है तो आपको 55 रूपए चार्ज देना होगा
                                </p>

                                <h2>
                                    NEFT
                                </h2>
                                <h3>
                                    NEFT क्या है
                                </h3>
                                <p>
                                    NEFT का फुल फॉर्म National Electronic Funds Transfer होता है <br />
                                    और मनी ट्रांसफर सिस्टम के मुताबिक NEFT थोड़ा धीमा होता है, <br />
                                    NEFT का सबसे बड़ा फायदा ये है इसमें न्यूनतम राशि की कोई लिमिट नहीं होती , आप जितना चाहे कम से कम ज्यादा से ज्यादा ट्रांसक्शन कर सकते हैं
                                </p>

                                <h2>
                                    NEFT System द्वारा Fund transfer करने का समय ?
                                </h2>
                                <p>
                                    पैसे के ट्रांसेक्शन के लिए हर घंटे में कुछ बैच का इस्तेमाल होता है. अगर हम बात करें तो ये 12 बैच जो की सुबह 8 बजे से शाम 7 बजे तक चलता रहता है.
                                </p>
                                <p>
                                    यही शनिवार के दिन सुबह 8 बजे से 1 बजे तक 6 बैच चलते हैं. इन समय के अलावा अगर आप फण्ड ट्रांसफर करते है तो आपका फण्ड ट्रांसफर आने वाले अगले सेटलमेंट में होगा.
                                </p>

                                <h2>
                                    NEFT द्वारा Money Transfer करने पर कितना चार्ज लगता है ?
                                </h2>
                                <p>
                                    पैसे के ट्रांसेक्शन के लिए हर घंटे में कुछ बैच का इस्तेमाल होता है. अगर हम बात करें तो ये 12 बैच जो की सुबह 8 बजे से शाम 7 बजे तक चलता रहता है. <br />
                                    यही शनिवार के दिन सुबह 8 बजे से 1 बजे तक 6 बैच चलते हैं.
                                </p>
                                <p>
                                    इन समय के अलावा अगर आप फण्ड ट्रांसफर करते है तो आपका फण्ड ट्रांसफर आने वाले अगले सेटलमेंट में होगा.
                                </p>

                                <h2>
                                    NEFT द्वारा Money Transfer करने पर कितना चार्ज लगता है ?
                                </h2>
                                <p>
                                    10,000 तक ट्रांसफर पर -     Rs 2.50 + Applicable GST <br />
                                    10,000 से 1 लाख तक -     Rs 5 + Applicable GST <br />
                                    1 लाख से 2 लाख तक -     Rs 15 + Applicable GST <br />
                                    2 लाख से 5 लाख तक -     Rs 25 + Applicable GST <br />
                                    5 लाख से 10 लाख तक -     Rs + Applicable GST
                                </p>

                                <h2>
                                    IMPS
                                </h2>
                                <h3>
                                    IMPS क्या है
                                </h3>
                                <p>
                                    IMPS का फुल फॉर्म Immediate Payment Service होता है
                                </p>
                                <p>
                                    IMPS की शुरुआत NPCI (National Payment Corporation Of India) द्वारा की गयी थी , इस सर्विस के माध्यम से आप किसी भी बैंक किसी भी वक़्त Fund Transfer कर सकते हैं IMPS की खाश बात ये है की पैसा भेजना या पैसे प्राप्त करने का काम सबसे कम समय में हो जाता है।
                                </p>

                                <h2>
                                    IMPS द्वारा Fund transfer करने का समय ?
                                </h2>
                                <p>
                                    IMPS द्वारा Fund Transfer करने की बात करें तो , आप २४ घंटे में किसी भी वक़्त फण्ड ट्रांसफर कर सकते है
                                </p>
                                <p>
                                    बैंक हॉलिडे हो या संडे 365 दिन आप फण्ड ट्रांसफर कर सकते है
                                </p>

                                <h2>
                                    IMPS द्वारा Money Transfer करने पर कितना चार्ज लगता है ?
                                </h2>
                                <p>
                                    10,000 तक -     Rs 2.50 + Excluding GST <br />
                                    10,001 से 1 लाख तक -     Rs 5 + Excluding GST <br />
                                    1 लाख से 2 लाख तक -     Rs 15 + Excluding GST
                                </p>
                                <p>
                                    जैसा की आप जान चुके है मनी ट्रांफर करने के लिए किन किन सिस्टम्स का इस्तेमाल किया जाता है,
                                </p>
                                <p>
                                    आइये अब आप को बताते है अगर आप अपना मनी ट्रांसफर बिज़नेस शुरू करना और अच्छा Income Generate करना चाहते है तो किस तरह ये बिज़नेस शुरू कर सकते हैं
                                </p>
                                <p>
                                    सबसे पहले आप को हमारे Digital Gramin Seva पर रजिस्ट्रेशन करना होगा , रजिस्ट्रेशन करने के बाद आपको एक रिटेलर ID मिलती है , <br />
                                    उस रिटेलर ID से आप मनी ट्रांसफर की सुविधा दे सकते हैं,
                                </p>

                                <img className=' mt-2 mb-3' style={{ width: "40%" }} src={require("../../Img/registraion-2.png")} alt="" />

                                <p>
                                    सारी Details भरने के बाद आपको 4 ऑप्शन में से एक Select करना होगा
                                </p>
                                <p>
                                    आपको Retailer को सेलेक्ट कर देना है और Submit Button पर Click कर देना है
                                </p>
                                <p>
                                    और जो 3 ऑप्शन है वो आपके के लिए नहीं है इसके बारे में जान्ने के लिए हमसे Contact कर सकते है या हमारी वेबसाइट में जानकारी ले सकते हैं
                                </p>
                                <p>
                                    Submit करने के बाद आपके सामने पेमेंट पेज खुलेगा
                                </p>

                                <img className=' mt-2 mb-3 w-50' src={require("../../Img/after-sign-up.png")} alt="" />

                                <p>
                                    Payment Mode Select के बाद आपको 1180 रूपए लिखा हुआ दिख रहा होगा <br />
                                    ये 1499 Retailer ID Registration Fees है जो की आपसे एक बार ली जाती है , <br />
                                    इस 1499 के बाद आपसे Future में कोई फीस नहीं ली जाती है किसी भी तरह का
                                </p>
                                <p>
                                    Payment Done होने के बाद आपको 10 मिनट के अंदर Login Username And Password आपके नंबर पर प्राप्त हो जायेगा
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MoneyTransferBlog