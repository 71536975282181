import React from 'react'
import Silder from './Silder'

const IncomeTaxReturn = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards2.png")} alt="" /></span>
                                <span className='Main_Title'>Income Tax Return</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    An Income tax return (ITR) is a form used to file information about your income and tax to the Income Tax Department. The tax liability of a taxpayer is calculated based on his or her income. In case the return shows that excess tax has been paid during a year, then the individual will be eligible to receive a income tax refund from the Income Tax Department.
                                </p>
                                <p>
                                    As per the income tax laws, the return must be filed every year by an individual or business that earns any income during a financial year. The income could be in the form of a salary, business profits, income from house property or earned through dividends, capital gains, interests or other sources.
                                </p>
                                <p>
                                    Tax returns have to be filed by an individual or a business before a specified date. If a taxpayer fails to abide by the deadline, he or she has to pay a penalty.
                                </p>

                                <h2> Documents Required for ITR Filing </h2>
                                <ul>
                                    <li> PAN Card of the taxpayer </li>
                                    <li> Bank Statement/ Cancelled Cheque </li>
                                    <li> Aadhar Card </li>
                                    <li> Investment under section 80 </li>
                                    <li> Form 16 (for Salaried Person), Form 16A (for others) </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default IncomeTaxReturn