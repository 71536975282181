import React from 'react'
import Silder from './Silder'

const AadharCenterApply = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>  Aadhar Center Apply </span>
                            </div>
                            <article className="article-content ">
                                <div className="row">
                                    <h2 mt-0>
                                        APPLY AADHAAR CENTER THROUGH DIGITAL GRAMIN SEVA PORTAL
                                    </h2>
                                    <p>
                                        Aadhar Card has become one of the most important documents
                                        for all the resident Indians as this will help the
                                        individuals to enjoy the benefits of various schemes run by
                                        both the state and the central government in India.
                                    </p>
                                    <p>
                                        The Aadhaar is a 12 – number Unique Identification Number
                                        (UIN) which is handed by the Unique Identification Authority
                                        of India (UIDAI) to the occupant of India. The Aadhaar UID
                                        is issued by taking into account the demographic and
                                        biometric data of the individualities. The Aadhar card acts
                                        as an identification card which can be used as a biometric
                                        document which stores an existent’s particular data in a
                                        government database. One can use this to secure the benefits
                                        of public weal and citizen schemes offered by the
                                        government.
                                    </p>
                                    <p>
                                        The primary ideal of the Aadhar enrolment centre is to enrol
                                        new applicants for Aadhaar and provide assistance to Aadhaar
                                        card holders for streamlining their Aadhaar details and
                                        other installations. All tasks carried out at Aadhar
                                        registration centres are free of cost for the first time
                                        whereas a nominal figure is applicable on some of the tasks
                                        and the rates are set by the UIDAI.These institutions insure
                                        continued empanelment with UIDAI. Same terms and conditions
                                        are applicable for both expelled as well as non-empanelled
                                        agencies.
                                    </p>

                                    <h3>Eligibility</h3>
                                    <ul className="bc-service-list m-0 ps-4">
                                        <li>Uidai Aadhar operator / Supervisor Sify certificate</li>
                                        <li>Latest Soft Copy of e Aadhar</li>
                                        <li> Internet Connectivity</li>
                                    </ul>

                                    <h3>
                                        List of Identity Proofs – Documents Required for New Aadhaar
                                        Card
                                    </h3>
                                    <ul className="bc-service-list m-0 ps-4">
                                        <li>Passport</li>
                                        <li>PAN card</li>
                                        <li> Either Ration or PDS Photo card</li>
                                        <li>Voter Identification card</li>
                                        <li>Driving licence of the applicant </li>
                                        <li>
                                            Photo identification cards issued by the Government of
                                            India
                                        </li>
                                        <li>Service photo ID cards that are issued by a PSU</li>
                                        <li>Job card of MG-NREGS</li>
                                        <li>
                                            Photo identification that is issued by a Recognised
                                            Educational Institution
                                        </li>
                                        <li>Licence of Arms</li>
                                        <li>Photo Bank ATM card</li>
                                        <li>Photo Credit card</li>
                                        <li>Photo card of the Pensioner</li>
                                        <li>Photo card of the Freedom Fighter</li>
                                        <li>Photo Passbook of Kisan</li>
                                        <li>Photo card of CGHS</li>
                                        <li>Certificate of Marriage</li>
                                        <li>
                                            A document containing proof of the marriage of the
                                            applicant issued originally by the marriage Registrar
                                            (which is mandatory to submit after marriage)
                                        </li>
                                        <li>Legally approved name change certificate</li>
                                        <li>ECHS Photocard</li>
                                        <li>
                                            Residing Address card of the applicant that contains both
                                            the name and the photo that was originally issued by the
                                            Department of Posts
                                        </li>
                                        <li>
                                            Identity Certificate containing the photo of the applicant
                                            on a proper letterhead issued by either a Gazetted Officer
                                            or a Tehsildar
                                        </li>
                                        <li>
                                            Either a handicapped medical certificate or a disability
                                            Identification card that is issued by either the State
                                            Government, the UT Government, or any such Administration
                                        </li>
                                    </ul>

                                    <h3>Documents Required for Aadhaar Card for Children</h3>
                                    <p>
                                        The list of documents required for issuing Aadhaar cards for
                                        children is similar to that for a normal applicant. However,
                                        one of the parents has to provide their Aadhaar copy in
                                        addition to the documents required by normal applicants for
                                        obtaining a child’s Aadhaar card.
                                    </p>
                                    <p>
                                        Also, children below 5 years do not have to submit their
                                        biometric data. It is only required when they attain 5 years
                                        of age. It is then when their biometric data is taken. The
                                        following are the documents required for Aadhaar card for
                                        child above 5 years:
                                    </p>

                                    <ul class="bc-service-list m-0 ps-4">
                                        <li>Proof of residence</li>
                                        <li>Proof of identity</li>
                                        <li>Birth certificate</li>
                                        <li>Aadhaar of any one of the parents</li>
                                    </ul>

                                    <h3>
                                        The documents accepted as Identity proof are given below
                                    </h3>
                                    <ul class="bc-service-list m-0 ps-4">
                                        <li>Passport Copy</li>
                                        <li>PAN Card Copy</li>
                                        <li>Voter ID Copy</li>
                                        <li>Driving License</li>
                                    </ul>

                                    <h3>
                                        The documents accepted as Address proof are given below
                                    </h3>
                                    <ul class="bc-service-list m-0 ps-4">
                                        <li>Passport Copy of Spouse</li>
                                        <li>A Copy of Ration Card</li>
                                        <li>Voter ID Card</li>
                                        <li>Driving License</li>
                                        <li>Credit Card Statement (last 3 months)</li>
                                        <li>Bank Statement Or Passbook</li>
                                        <li>Electricity Bill (Last 3 months)</li>
                                        <li>Water bill (Last 3 months)</li>
                                        <li>Gas Connection Bill (Last 3 months)</li>
                                        <li>Landline Phone Bill (Last 3 months)</li>
                                        <li>Insurance Policy</li>
                                    </ul>

                                    <h3>How to Apply Aadhar center</h3>
                                    <p>
                                        Applying for Aadhar center is very easy. You have to login
                                        to Digital Gramin Seva Portal and click on Aadhar Kendra
                                        option.
                                    </p>
                                    <h3>The next process does digital rural service.</h3>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default AadharCenterApply