import React from 'react'
import Silder from './Silder'

const GstReturn = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards1.png")} alt="" /></span>
                                <span className='Main_Title'>GST Return</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h2> You can do GST Return Filing of anyone in Digital Gramin Seva Portal. </h2>
                                <h3> What Is GST Registration? </h3>
                                <p>
                                    A GST return is a document containing details of all income/sales and/or expense/purchase which a taxpayer (every GSTIN) is required to file with the tax administrative authorities. this is often employed by tax authorities to calculate net liabilities.
                                </p>

                                <h6 className='fw-bold'> Under GST, a registered dealer has got to file GST returns that broadly include: </h6>
                                <ul>
                                    <li> Purchases </li>
                                    <li> Sales </li>
                                    <li> Output GST (On sales) </li>
                                    <li> Input decrease (GST paid on purchases) </li>
                                </ul>

                                <h2> How can you do GST Return Filing of a customer? </h2>
                                <ul>
                                    <li> First you need to connect with Digital Gramin Seva </li>
                                    <li> To join you have to click on the registration button above. </li>
                                    <li> After registration is complete, you will get Retailer ID password </li>
                                    <li> After getting the login ID, you have to login on Digital Gramin Seva Portal. </li>
                                    <li> After login, you have to click on the GST Return option and apply for GST Return </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default GstReturn