import React from 'react'
import Silder from './Silder'

const TdsReturn = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards2.png")} alt="" /></span>
                                <span className='Main_Title'>TDS Return</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> You can file TDS Returns in Digital Gramin Seva Portal. </h3>
                                <h2> What Is TDS Return Filing? </h2>
                                <p>
                                    TDS or Tax Deducted at Source is tax reduced from the cash paid at the time of creating specified payments like rent, commission, professional fees, salary, interest etc. by the persons making such payments. Usually, the person receiving income is susceptible to pay tax . But the govt with the assistance of Tax Deducted at Source provisions makes sure that tax is deducted beforehand from the payments being made by you. The recipient of income receives internet amount (after reducing TDS). The recipient will add the gross amount to his income and therefore the amount of TDS is adjusted against his final liabilities . The recipient takes credit of the quantity already deducted and paid on his behalf.
                                </p>
                                <h2> Eligibility Criteria for TDS Return </h2>
                                <p>
                                    TDS return can be filed by employers or organizations who have a valid Tax Collection and Deduction Account Number (TAN). Any person making specified payments mentioned under the I-T Act are required to deduct tax at source and needs to deposit within the stipulated time.
                                </p>

                                <h2> Types of TDS return </h2>
                                <ul>
                                    <li> Form 24Q (TDS on Salary) </li>
                                    <li> Form 27Q (TDS where deductee is a non-resident, foreign company) </li>
                                    <li> Form 26QB (TDS on payment for transfer of certain immovable property) </li>
                                    <li> Form 26Q (TDS in other case) </li>
                                </ul>

                                <h2> Details Required to file TDS return are: </h2>
                                <ul>
                                    <li> PAN of the deductor and the deductee </li>
                                    <li> Amount of tax paid to the government </li>
                                    <li> TDS challan information </li>
                                    <li> Others, if any </li>
                                </ul>

                                <h2> How can you apply for TDS? </h2>
                                <ul>
                                    <li> First you need to connect with Digital Gramin Seva </li>
                                    <li> To join you have to click on the registration button above. </li>
                                    <li> After registration is complete, you will get Retailer ID password </li>
                                    <li> After getting the login ID, you have to login on Digital Gramin Seva Portal. </li>
                                    <li> After login, you have to click on the TDS Return Filing option and apply for TDS Return Filing. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default TdsReturn