import React from 'react'
import Silder from './Silder'

const PrivateLimitedRegistration = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards4.png")} alt="" /></span>
                                <span className='Main_Title'>Private Limited Registration</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    AEPS means Aadhaar Enabled Payment System, a replacement payment service offered by the National Payments Corporation of India to banks and financial institutions using ‘Aadhaar’. Aadhaar may be a unique number issued by the Unique Identification Authority of India (UIDAI). In banking AEPS means empowerment where the provider allows a bank customer to use Aadhaar as his/her identity to access his/ her respective Aadhaar enabled checkivng account (AEPS service provider bank) and perform basic intrabank or interbank transactions.
                                </p>
                                <p>
                                    AEPS means Aadhaar Enabled Payment System, a replacement payment service offered by the National Payments Corporation of India to banks and financial institutions using ‘Aadhaar’. Aadhaar may be a unique number issued by the Unique Identification Authority of India (UIDAI). In banking AEPS means empowerment where the provider allows a bank customer to use Aadhaar as his/her identity to access his/ her respective Aadhaar enabled checkivng account (AEPS service provider bank) and perform basic intrabank or interbank transactions.
                                </p>

                                <h2> Benefits: </h2>
                                <ul>
                                    <li> You can provide mini banking service at your shop </li>
                                    <li> You can attract customers by single solution facility at one place </li>
                                    <li> Easy to use </li>
                                    <li> You can earn commission on each and every AEPS Transactions </li>
                                    <li> Instant Activation </li>
                                    <li> Offer complete banking solution to your customers </li>
                                </ul>

                                <h2> Services: </h2>
                                <ul>
                                    <li> Balance Enquiry </li>
                                    <li> Cash Withdrawal </li>
                                    <li> Mini statement </li>
                                </ul>

                                <h2> Requirements for AEPS transactions: </h2>
                                <ul>
                                    <li> Bank Name </li>
                                    <li> Aadhaar Number </li>
                                    <li> Fingerprint captured during enrollment. </li>
                                </ul>

                                <h2> How can you provide AEPS service ? </h2>
                                <h5> It is a very easy and simple way to Provide Aeps service </h5>
                                <ul>
                                    <li> First you need to connect with Digital Gramin Seva </li>
                                    <li> To join you have to click on the registration button above. </li>
                                    <li> After registration is complete, you will get Retailer ID password </li>
                                    <li> You can provide AEPS service to your customers by logging in as soon as you get the ID password </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default PrivateLimitedRegistration