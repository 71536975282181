import React from 'react'
import Silder from './Silder'

const WaterBillPayment = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container Main_Container">
                        <div className="row">
                        <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>Water Bill Payment</span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2>Features:</h2>
                                    <ul className="bc-service-list m-0">
                                        <li>Easy accessibility & Wider Network</li>
                                        <li>100% Secure Transactions</li>
                                        <li>Interoperability</li>
                                    </ul>
                                    <h3>List of Services We Are Providing</h3>

                                    <ul className="bc-service-list m-0">
                                        <li>Electricity</li>
                                        <li>
                                            Telecom (Mobile Post-paid, Landline Post-paid and
                                            Broadband)
                                        </li>
                                        <li>DTH</li>
                                        <li>Gas-Pipeline</li>
                                        <li>Water</li>
                                        <li>LPG Gas Booking</li>
                                        <li>Insurance (Life, General, Health)</li>
                                        <li>Loan Repayments</li>
                                        <li>FASTag Recharge</li>
                                        <li>Cable</li>
                                        <li>Education Fees</li>
                                        <li>Housing Society Municipal Taxes</li>
                                        <li>Municipal Services</li>
                                        <li>Hospital</li>
                                        <li>Subscription Fees</li>
                                    </ul>

                                    <h3>How can you provide BBPS service in your area?</h3>
                                    <p> First you need to connect with Digital Gramin Seva</p>

                                    <ul className="bc-service-list m-0">
                                        <li>
                                            To join you have to click on the registration button
                                            above.
                                        </li>
                                        <li>
                                            After registration is complete, you will get Retailer ID
                                            password
                                        </li>
                                        <li>
                                            After getting the login ID, you have to login on Digital
                                            Gramin Seva Portal. After login, you have to click on the
                                            BBPS option.
                                        </li>
                                        <li>
                                            After going to the BBPS option, you can pay your
                                            customers' bills.
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default WaterBillPayment