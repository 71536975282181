import React from 'react'
import Silder from './Silder'

const LoanApply = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards6.png")} alt="" /></span>
                                <span className='Main_Title'>Loan Apply</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    From 30+ Banks & Financial Institutions serving all types of costumer segment Instant Funds for all your needs !
                                </p>
                                <p>
                                    Get Personal & Business Loan with Digital Gramin Seva
                                </p>

                                <h2> Loan Offer ranging from </h2>
                                <ul>
                                    <li> Tenor from 3Months to 5 Years </li>
                                    <li> Rs.1,000 to Rs.50Lakhs </li>
                                    <li> Interest rate from 7.99% to 36%p.a reducing basis </li>
                                </ul>

                                <h2> Document Required: </h2>
                                <ul>
                                    <li> Identity Proof </li>
                                    <li> Signature Proof </li>
                                    <li> Bank Statement </li>
                                    <li> Salary Proof </li>
                                    <li> Address Proof </li>
                                    <li> Employment Proof </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default LoanApply