import React from 'react'
import Silder from './Silder'

const EshramCardApply = () => {
    return (

        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>  E-Shram Card Apply </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2>What's E Shram Card Apply?</h2>
                                    <p>
                                        eShram Yojana – As the name suggests, this is a scheme
                                        lately launched by the Ministry of Labor and Employment,
                                        under which the workers of the unorganized sector are given
                                        by the Central Government. It has been started to collect
                                        data at the central position with the ideal of giving direct
                                        benefit to the Under thee-shram scheme,e-shram cards of
                                        about43.7 crore unorganized sector workers of the country
                                        will be prepared, through which they will be given direct
                                        benefits of the schemes launched by the central government
                                        and the state government.
                                    </p>
                                    <p>
                                        The Ministry of Labour & Employment which is one of the
                                        oldest and important Ministries of the Government of India,
                                        is continuously working on perfecting life and quality of
                                        Labour force of country by guarding & securing the interest
                                        of workers, promotion of weal and providing social security
                                        to the Labour force both in Organized and Unorganized
                                        Sectors by enactment and perpetration of colorful Labour
                                        Laws, which regulate the terms and conditions of service and
                                        employment of workers.
                                    </p>

                                    <p>
                                        Accordingly, Ministry of Labour & Employment has developed
                                        eSHRAM portal for creating a National Database of
                                        Unorganized Workers (NDUW), which will be planted with
                                        Aadhaar. It'll have details of name, occupation, address,
                                        educational qualification, skill types and family
                                        detailsetc. for optimum consummation of their employability
                                        and extend the benefits of the social security schemes to
                                        them. It's the first- ever public database of unorganised
                                        workers including migratory workers, construction workers,
                                        gig and platform workers, etc.
                                    </p>
                                    <p>
                                        This E Shram Yojana is started by Government to give you
                                        Social Security in Rainy Days. You can get a pension after
                                        the age of 60 Years if you have a Shramik Card. Also, you'll
                                        also have insurance between doing your work as per benefits
                                        of E Shram Card.
                                    </p>

                                    <h3>Why is a E Shram Card Apply important?</h3>

                                    <p>
                                        E Shram Card Apply details give an important evidence of
                                        Identity and Residence of citizens, it also used as evidence
                                        of applying for making a Domicile instrument, Birth
                                        Certificate, Voter ID cardetc. you can also check E Shram
                                        Card Apply details by name.
                                    </p>
                                    <p>
                                        E Shram Card Applys offer identification as well entitle the
                                        holder to a Ration of food, energy, or other goods issued by
                                        the Government of India. They're primarily used when copping
                                        subsidized foodstuffs (wheat, rice, sugar) and kerosene.
                                    </p>

                                    <h3>Benefits of E-Shram Card</h3>
                                    <p>
                                        There are many benefits of E Shram Card apart from what's
                                        known to you. See the complete benefits in the points given
                                        below and also use all the gratuities after getting Shramik
                                        Card.
                                    </p>

                                    <ul class="bc-service-list m-0">
                                        <li>
                                            First is that you will get a Pension of Rs 3000/-
                                            (Minimum) after attaining age of 60 Years.
                                        </li>
                                        <li>
                                            Secondly, you will have complete insurance for any
                                            mishappening during age up to 60 Years.
                                        </li>
                                        <li>
                                            In case of any accident you can avail Insurance of Rs
                                            50,000/-.
                                        </li>
                                        <li>
                                            If due to mishappening, the beneficiary dies then all the
                                            benefits will be transferred to the Wife.
                                        </li>
                                        <li>
                                            Social Security Schemes based on this database will be
                                            implemented by Ministries/Governments
                                        </li>
                                        <li>
                                            You have to contribute monthly through your E Shram Card
                                            and the same amount will be credited by the Government of
                                            India.
                                        </li>
                                        <li>
                                            NDUW under the registered employee PM Security Bhim plan
                                            since you can take advantage of the registration will be
                                            waived payment of premium for the first year.
                                        </li>
                                        <li>
                                            You can avail all the Government Schemes for Workers if
                                            you have your E Shram Card or Shramik Card.
                                        </li>
                                    </ul>

                                    <h3>Shramik Card Eligibility </h3>
                                    <p>
                                        To apply for NDUW card i.e. to get UAN card , it is
                                        necessary to fulfill the eligibility and criteria mentioned
                                        below
                                    </p>
                                    <ul class="bc-service-list m-0">
                                        <li>Applicant age should be between 15-59 years</li>
                                        <li>The applicant should not be an income tax payer</li>
                                        <li>
                                            The applicant should not be a member of EPFO or ESIC
                                        </li>
                                        <li>
                                            Applying and should be a worker of organized sector.
                                        </li>
                                    </ul>

                                    <h3>Required Documents For UAN Card</h3>

                                    <p>Mandatory Documents</p>
                                    <ul class="bc-service-list m-0">
                                        <li>Mandatory eKYC</li>
                                        <li>- OTP</li>
                                        <li>- Fingerprint using Aadhar number</li>
                                        <li>- Iris</li>
                                        <li>Bank account number</li>
                                        <li>Mobile number</li>
                                    </ul>

                                    <p>Optional document</p>
                                    <ul class="bc-service-list m-0">
                                        <li>Certificate of Education</li>
                                        <li>Income Certificate</li>
                                        <li>Business Certificate</li>
                                        <li>Skill Certificate</li>
                                    </ul>

                                    <h3>How to avail the facility of making E Shram Card</h3>
                                    <p>
                                        Follow the steps below for the E Shram Card Online Apply
                                        process.
                                        <br />
                                        Making E Shram Card is very easy.
                                    </p>
                                    <ul class="bc-service-list m-0">
                                        <li>
                                            First You have to login to Digital Gramin Seva Portal and
                                            click on E-Shram card option.
                                        </li>
                                        <li>
                                            Secondly, Enter your Aadhar Linked Mobile Number on E
                                            Shram.
                                        </li>
                                        <li>
                                            Thirdly, Move forward and enter your further details as
                                            asked on the page.
                                        </li>
                                        <li>
                                            Complete all the details and upload documents like Aadhar
                                            Card and Bank Account Passbook.
                                        </li>
                                        <li>
                                            Finally, Click on the Submit button and wait for
                                            processing of E Shram Card.
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default EshramCardApply