import React from 'react'

const WhatIsAEPS = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <h1>
                                    What is AEPS ? AEPS या हैं
                                </h1>
                                <h5>
                                    Feb 22, 2021 by IMOC Digital Services Private Limited
                                </h5>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/aeps.png")} alt="" />
                                <p>
                                    इस आर्टिकल में आप जानेंगे AEPS क्या है, इससे होने वाले फायदे और AEPS में आपको क्या क्या सुविधाएं मिलती हैं,
                                </p>
                                <p>
                                    इस AEPS Service को कैसे आप ले सकते हैं,
                                </p>
                                <p>
                                    इस Aeps को लेकर कैसे आप अपनी शॉप मिनी बैंक में बदल कर अच्छा कमिशन कमा सकते हैं
                                </p>
                                <p>
                                    जैसा की आप जानते है हमारे भारत देश में ग्रामीण क्षेत्रों में बैंकों की संख्या: बहुत कम है |
                                </p>
                                <p>
                                    जिसकी वजह से ग्रामीण क्षेत्र के निवासी अपने खाता खुलवाने या पैसा निकालने या जमा करने में बहुत दिक्कत का सामना करना पड़ता है
                                </p>
                                <p>
                                    ग्रामीण क्षेत्रो में बैंक की सुविधाएं अगर है भी तो वह कसबे से दूर या शहरी क्षेत्रों में है |
                                </p>
                                <p>
                                    इन्ही सभी दिक्कत का समाधान सिर्फ AEPS है
                                </p>
                                <p>
                                    जिससे घर के पास ही कोई भी व्यक्ति जाकर अपना Balance Enquiry या Cash Withdrawal कर सकता है |
                                </p>
                                <p>
                                    अगर ये AEPS सर्विस आप प्रदान करते है तो आपके ग्रामीण क्षेत्र के व्यक्ति अपने गांव मैं ही अपने घर के आस-पास में बैंकिंग सुविधाएं प्राप्त कर सकते हैं।
                                </p>

                                <h2>
                                    What is AEPS ? AEPS क्या है
                                </h2>
                                <p>
                                    AEPS Service एक नई भुगतान सेवा है जो ‘Aadhaar’ का उपयोग करके बैंकों, वित्तीय संस्थानों को नेशनल पेमेंट्स कॉरपोरेशन ऑफ़ इंडिया (NPCI) द्वारा प्रदान की जाती है।
                                </p>
                                <p>
                                    AEPS का अर्थ ‘Aadhar Enabled Payment System/आधार सक्षम भुगतान प्रणाली’ है
                                </p>
                                <p>
                                    AEPS सेवा का उद्देश्य भारत के हर एक क्षेत्र में डिजिटल जरुरतो को पूरा करना
                                </p>
                                <p>
                                    ग्राहक अपने आधार कार्ड नंबर और फिंगरप्रिंट का उपयोग करके पैसा निकाशी , बैंक बैलेंस चेक , मिनी स्टेटमेंट जैसी सर्विस का उपयोग कर सकता है
                                </p>
                                <p>
                                    ग्राहक का बैंक अकाउंट से आधार लिंक होना अनिवार्य है
                                </p>

                                <h2>
                                    AEPS में क्या क्या सुविधाएं मिलती है ?
                                </h2>
                                <p>
                                    Cash Withdrawal(नकद निकाशी) <br />
                                    Balance Enquiry <br />
                                    Mini statement (मिनी स्टेटमेंट)
                                </p>

                                <h2>
                                    AEPS प्रदाता को मिलने वाले फायदे
                                </h2>
                                <p>
                                    आधार कार्ड से हुई हर एक ट्रांसक्शन पर बैंक्स की तरफ से आकर्षक कमीशन दिया जाता है <br />
                                    रिटेलर की दूकान बदले मिनी बैंक में <br />
                                    कमाई करने का सबसे अच्छा और सबसे आसान जरिया
                                </p>

                                <h2>
                                    AEPS से ग्राहक को मिलने वाले फायदे
                                </h2>
                                <p>
                                    AEPS से लेन देन करना बहुत ही आसान <br />
                                    लेन देन करने के लिए सिर्फ आधार कार्ड नंबर और फिंगरप्रिंट की आवश्यकता <br />
                                    बिना बैंक या एटीएम के कर सकें पैसा निकाशी <br />
                                    मिले घर के नज़दीक बैंकिंग सुविधा <br />
                                    लेन देन में किसी भी तरह का कोई चार्ज नहीं (Charge Free) <br />
                                    किसी भी तरह की कोई पर्ची भरने या हस्ताक्षर करने की जरुरत नहीं
                                </p>

                                <h2>
                                    AEPS प्रदाता को लेन देन करने के लिए आवशयक चीज़े
                                </h2>
                                <p>
                                    आधार संख्या <br />
                                    बैंक नाम <br />
                                    मोबाइल फ़ोन के साथ मोरफो(Morpho) या मंत्रा(Mantra) डिवाइस <br />
                                    खाताधारक का फिंगरप्रिंट
                                </p>

                                <p>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default WhatIsAEPS