import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Header from './Header'
import Footer from './Footer'
import Silder from './Silder'
import About from './About'
import ApplyForJob from './ApplyForJob'
import ApplyForFranchise from './ApplyForFranchise'
import Contact from './Contact'
import Services from './Services'
import AepsService from './AepsService'
import MoneyTransfer from './MoneyTransfer'
import CashDeposite from './CashDeposite'
import AadharPay from './AadharPay'
import CspBanking from './CspBanking'
import BankAccountOpening from './BankAccountOpening'
import LoanApply from './LoanApply'
import DematAccount from './DematAccount'
import MiniAtm from './MiniAtm'
import HitachiAtm from './HitachiAtm'
import CreditCard from './CreditCard'
import Upi from './Upi'
import GstRegistration from './GstRegistration'
import GstReturn from './GstReturn'
import GstCorrection from './GstCorrection'
import TdsReturn from './TdsReturn'
import FssaiRegistration from './FssaiRegistration'
import PrivateLimitedRegistration from './PrivateLimitedRegistration'
import AddRemovePartner from './AddRemovePartner'
import ChangeBusinessName from './ChangeBusinessName'
import ChangeOfficeAddress from './ChangeOfficeAddress'
import IncomeTaxReturn from './IncomeTaxReturn'
import OnePersonCompany from './OnePersonCompany'
import TanRegistration from './TanRegistration'
import BusBooking from './BusBooking'
import FlightBooking from './FlightBooking'
import HotelBooking from './HotelBooking'
import TrainBooking from './TrainBooking'
import MobileRecharge from './MobileRecharge'
import DthRecharge from './DthRecharge'
import ElectricityBillPayment from './ElectricityBillPayment'
import WaterBillPayment from './WaterBillPayment'
import GasBillPayment from './GasBillPayment'
import Fastag from './Fastag'
import MunicipalityBillPayment from './MunicipalityBillPayment'
import InsuranceBillPayment from './InsuranceBillPayment'
import LicBillPayment from './LicBillPayment'
import AadharUpdate from './AadharUpdate'
import AadharUdyog from './AadharUdyog'
import StateCertificate from './StateCertificate'
import LabourCard from './LabourCard'
import DrivingLicence from './DrivingLicence'
import Passport from './Passport'
import AadharCenterApply from './AadharCenterApply'
import EshramCardApply from './EshramCardApply'
import RationCard from './RationCard'
import PmKisanScheme from './PmKisanScheme'
import VoterIdNew from './VoterIdNew'
import VoterIdCorrection from './VoterIdCorrection'
import PanCardUti from './PanCardUti'
import PanCardNsdl from './PanCardNsdl'
import TearmCondition from './TearmCondition'
import LegalDocs from './LegalDocs'
import PrivacyPolicy from './PrivacyPolicy'
import Blogs from './Blogs'
import DigitalIndia from './Blog/DigitalIndia'
import CasteCertificate from './Blog/CasteCertificate'
import OpcBusiness from './Blog/OpcBusiness'
import AboutDigitalGraminSeva from './Blog/AboutDigitalGraminSeva'
import DigitalBharat from './Blog/DigitalBharat'
import WhatIsAEPS from './Blog/WhatIsAEPS'
import Bbps from './Blog/Bbps'
import MicroATM from './Blog/MicroATM'
import MoneyTransferBlog from './Blog/MoneyTransferBlog'
import PanCard from './Blog/PanCard'
import Tds from './Blog/Tds'
import WhatIsGST from './Blog/WhatIsGST'
import CashDepositBlog from './Blog/CashDepositBlog'
import Csp from './Blog/Csp'
import G2CServicesBlog from './Blog/G2CServicesBlog'
import FSSAIRegistration from './Blog/FSSAIRegistration'

const Routing = () => {
    return (

        <>

            <React.Fragment>
                <header>
                    <Header />
                </header>

                <main>
                    <Routes>
                        <Route to path="/" element={<Home />} />
                        <Route to path="/services" element={<Services />} />
                        <Route to path="/about" element={<About />} />
                        <Route to path="/contact" element={<Contact />} />
                        <Route to path="/applyforfranchise" element={<ApplyForFranchise />} />
                        <Route to path="/applyforjob" element={<ApplyForJob />} />
                        <Route to path="/silder" element={<Silder />} />
                        <Route to path="/aepservice" element={<AepsService />} />
                        <Route to path="/moneytransfer" element={<MoneyTransfer />} />
                        <Route to path="/cashdeposite" element={<CashDeposite />} />
                        <Route to path="/aadharpay" element={<AadharPay />} />
                        <Route to path="/cspbanking" element={<CspBanking />} />
                        <Route to path="/bankaccountopening" element={<BankAccountOpening />} />
                        <Route to path="/loanapply" element={<LoanApply />} />
                        <Route to path="/demataccount" element={<DematAccount />} />
                        <Route to path="/miniatm" element={<MiniAtm />} />
                        <Route to path="/hitachiatm" element={<HitachiAtm />} />
                        <Route to path="/creditcard" element={<CreditCard />} />
                        <Route to path="/upi" element={<Upi />} />
                        <Route to path="/gstregistration" element={<GstRegistration />} />
                        <Route to path="/gstreturn" element={<GstReturn />} />
                        <Route to path="/gstcorrection" element={<GstCorrection />} />
                        <Route to path="/tdsreturn" element={<TdsReturn />} />
                        <Route to path="/fssairegistration" element={<FssaiRegistration />} />
                        <Route to path="/privatelimitedregistration" element={<PrivateLimitedRegistration />} />
                        <Route to path="/addremovepartner" element={<AddRemovePartner />} />
                        <Route to path="/changebusinessname" element={<ChangeBusinessName />} />
                        <Route to path="/changeofficeaddress" element={<ChangeOfficeAddress />} />
                        <Route to path="/incometaxreturn" element={<IncomeTaxReturn />} />
                        <Route to path="/onepersoncompany" element={<OnePersonCompany />} />
                        <Route to path="/tanregistration" element={<TanRegistration />} />
                        <Route to path="/busbooking" element={<BusBooking />} />
                        <Route to path="/flightbooking" element={<FlightBooking />} />
                        <Route to path="/hotelbooking" element={<HotelBooking />} />
                        <Route to path="/trainbooking" element={<TrainBooking />} />
                        <Route to path="/mobilerecharge" element={<MobileRecharge />} />
                        <Route to path="/dthrecharge" element={<DthRecharge />} />
                        <Route to path="/electricitybillpayment" element={<ElectricityBillPayment />} />
                        <Route to path="/waterbillpayment" element={<WaterBillPayment />} />
                        <Route to path="/gasbillpayment" element={<GasBillPayment />} />
                        <Route to path="/fastag" element={<Fastag />} />
                        <Route to path="/municipalitybillpayment" element={<MunicipalityBillPayment />} />
                        <Route to path="/insurancebillpayment" element={<InsuranceBillPayment />} />
                        <Route to path="/licbillpayment" element={<LicBillPayment />} />
                        <Route to path="/aadharupdate" element={<AadharUpdate />} />
                        <Route to path="/aadharudyog" element={<AadharUdyog />} />
                        <Route to path="/statecertificate" element={<StateCertificate />} />
                        <Route to path="/labourcard" element={<LabourCard />} />
                        <Route to path="/drivinglicence" element={<DrivingLicence />} />
                        <Route to path="/passport" element={<Passport />} />
                        <Route to path="/aadharcenterapply" element={<AadharCenterApply />} />
                        <Route to path="/eshramcardapply" element={<EshramCardApply />} />
                        <Route to path="/rationcard" element={<RationCard />} />
                        <Route to path="/pmkisanscheme" element={<PmKisanScheme />} />
                        <Route to path="/voteridnew" element={<VoterIdNew />} />
                        <Route to path="/voteridcorrection" element={<VoterIdCorrection />} />
                        <Route to path="/pancarduti" element={<PanCardUti />} />
                        <Route to path="/pancardnsdl" element={<PanCardNsdl />} />
                        <Route to path="/tearmcondition" element={<TearmCondition />} />
                        <Route to path="/legaldocs" element={<LegalDocs />} />
                        <Route to path="/privacypolicy" element={<PrivacyPolicy />} />
                        <Route to path="/blogs" element={<Blogs />} />
                        <Route to path="/digitalindia" element={<DigitalIndia />} />
                        <Route to path="/castecertificate" element={<CasteCertificate />} />
                        <Route to path="/opcbusiness" element={<OpcBusiness />} />
                        <Route to path="/aboutdigitalgraminseva" element={<AboutDigitalGraminSeva />} />
                        <Route to path="/digitalbharat" element={<DigitalBharat />} />
                        <Route to path="/whatisaeps" element={<WhatIsAEPS />} />
                        <Route to path="/bbps" element={<Bbps />} />
                        <Route to path="/microatm" element={<MicroATM />} />
                        <Route to path="/moneytransfer" element={<MoneyTransfer />} />
                        <Route to path="/moneytransferblog" element={<MoneyTransferBlog />} />
                        <Route to path="/pancard" element={<PanCard />} />
                        <Route to path="/tds" element={<Tds />} />
                        <Route to path="/whatisgst" element={<WhatIsGST />} />
                        <Route to path="/cashdepositblog" element={<CashDepositBlog />} />
                        <Route to path="/csp" element={<Csp />} />
                        <Route to path="/GCServicesBlog" element={<G2CServicesBlog />} />
                        <Route to path="/fssairegistrationn" element={<FSSAIRegistration />} />
                    </Routes>
                </main>

                <footer>
                    <Footer />
                </footer>
            </React.Fragment>
        </>
    )
}

export default Routing