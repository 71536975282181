import React from 'react'
import Silder from './Silder'

const GstRegistration = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards1.png")} alt="" /></span>
                                <span className='Main_Title'>GST Registration</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> What Is GST Registration? </h3>
                                <p>
                                    A GST return is a document containing details of all income/sales and/or expense/purchase which a taxpayer (every GSTIN) is required to file with the tax administrative authorities. this is often employed by tax authorities to calculate net liabilities.
                                </p>

                                <h2> Benefits: </h2>
                                <ul>
                                    <li> You can provide mini banking service at your shop </li>
                                    <li> You can attract customers by single solution facility at one place </li>
                                    <li> Easy to use </li>
                                </ul>

                                <h2> Services: </h2>
                                <ul>
                                    <li> Instant Settlement </li>
                                    <li> Instant Settlement </li>
                                    <li> No Monthly Charges </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default GstRegistration