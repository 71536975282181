import React from 'react'
import Silder from './Silder'

const GstCorrection = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards1.png")} alt="" /></span>
                                <span className='Main_Title'>GST Correction</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    GST Modification and Amendment of GST Registration
                                </p>
                                <p>
                                    Any changes made in the GST Registration or the details entered in the GST certificate are referred to as GST modification or update.
                                </p>
                                <p>
                                    One may go for change in GST Registration if he/she wishes from composite to normal scheme, or if there are mistakes in GST registration.
                                </p>
                                <p>
                                    To get GST modification, you need to file form GST REG 14. <br />
                                    What are details that can be changed or updated? <br />
                                    A business owner can apply for change(s) of the following items:
                                </p>

                                <ul>
                                    <li> Name of business </li>
                                    <li> Address of the principal place of business </li>
                                    <li> An additional place of business </li>
                                    <li> Addition, deletion or retirement of partners or directors, Managing Committee, CEO i.e., people who are responsible for day to day affairs of the business </li>
                                    <li> Mobile number or e-mail address of the authorized signatory </li>
                                </ul>

                                <h2> Documents Required:- </h2>
                                <ul>
                                    <li> Documentary Proof of Changes </li>
                                    <li> GST Certificate </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default GstCorrection