import React from 'react'
import Silder from './Silder'

const ChangeOfficeAddress = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/Section4service-cards7.png")} alt="" /></span>
                                <span className='Main_Title'>Change Office Address</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    Digital Gramin Seva Provides Registered Office Address Change Facility
                                </p>
                                <h2> Registered office of Company </h2>
                                <p>
                                    Every company incorporated in India must have a registered office address. It's a top place of business. All sanctioned correspondence from the Registrar is transferred to this office. For any reason, you may have to shift your top place of business be it within the city, within a state, or in a different city or in a different state altogether an inauguration to ROC is a must-have. The listed office of a company is that to which sanctioned correspondence and all formal legal notices addressed to the company will be transferred.
                                </p>

                                <h2> Rules to follow for changing the Address of the Registered Office of Company </h2>
                                <ul>
                                    <li> Same State, Same Jurisdiction </li>
                                    <li> Same State, Different Jurisdiction </li>
                                    <li> Different Jurisdiction, Different State </li>
                                </ul>

                                <h2> Documents required to change the Registered Office of Company </h2>
                                <h6> Within the same city/ state </h6>
                                <ul>
                                    <li> Evidence of listed office address of the company </li>
                                    <li> NOC from the proprietor of new demesne or dupe of property power documents. </li>
                                    <li> Different Jurisdiction, Different State </li>
                                    <li> Copy of Special Resolution ( Needed when registered office changed whithin same state but different metropolises) </li>
                                </ul>

                                <h2> From one state to another </h2>
                                <ul>
                                    <li> Copy of Special Resolution passed to amend the MOA. </li>
                                    <li> Copy of MOA & AOA of the company. </li>
                                    <li> Copy of Notice of general meeting with the statement of explanation. </li>
                                    <li> Copy of Minutes of Meeting of the General Meeting. </li>
                                    <li> Details of debenture holders and creditors of the company. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default ChangeOfficeAddress