import React from 'react'

const FSSAIRegistration = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/fssai-banner.png")} alt="" />
                                <h1>
                                    FSSAI Registration
                                </h1>
                                <h2>
                                    What is FSSAI?
                                </h2>
                                <p>
                                    एफएसएसएआई (FSSAI) यानि Food Safety and Standards Authority of India. एफएसएसएआई एक भारतीय स्वतंत्र निकाय हैं जो भारत में हर तरह के खाद्य पदार्थ के उत्पादन, बिक्री, संचयन और आयात में सुरक्षा, पौषण और स्वच्छता बनाए रखने के लिए ज़िम्मेदार हैं। इसमें 14 आंकड़ों का लाइसेंस नंबर होता हैं। इसके नियमों का अमल स्वास्थ्य और परिवार कल्याण मंत्रालय से किया जाता हैं।
                                </p>
                                <p>
                                    FSSAI Full Form – “Food Safety and Standards Authority of India“. एफएसएसएआई का फूल फॉर्म हिन्दी – “भारतीय खाद्य संरक्षा और मानक प्राधिकरण“।
                                </p>
                                <p>
                                    जनता को शुद्ध खान-पान मिलता हैं। <br />
                                    हानिकारक और जहरीले पदार्थ बाज़ार तक नहीं पहुँचते। <br />
                                    एफएसएसएआई खान-पान सुरक्षा और खाद्य नियमों के मापदंड की मुख्य और एकमात्र संस्था हैं। <br />
                                    एक क्षेत्र में अलग-अलग प्रकार की खाद्य सामग्री के लिए एक ही लाइसेंस की ज़रूरत होती हैं। <br />
                                    किसी खाद्य पदार्थ के विक्रेता के पास एफएसएसएआई लाइसेंस होने से उसके ग्राहकों को उसपे विश्वास रहता हैं।
                                </p>

                                <h2>
                                    FSSAI रजिस्ट्रेशन और लाइसेंसिंग के प्रकार हैं:
                                </h2>
                                <p>
                                    बेसिक FSSAI रजिस्ट्रेशन <br />
                                    स्टेट FSSAI रजिस्ट्रेशन <br />
                                    सेंट्रल FSSAI रजिस्ट्रेशन
                                </p>

                                <h3>
                                    Documents Required for FSSAI registration
                                </h3>
                                <p>
                                    पासपोर्ट साइज फोटो <br />
                                    पहचान पत्र जैसे पैन कार्ड, ड्राइविंग लाइसेंस, वोटर आईडी कार्ड <br />
                                    पते का प्रमाण पत्र <br />
                                    घोषणा पत्र <br />
                                    व्यक्ति के नाम और पते के साथ अथारिटी लेटर
                                </p>
                                <p>
                                    जैसा की आप जान चुके है FSSAI क्या है
                                </p>
                                <p>
                                    अगर आप चाहते है FSSAI Registration और भी बहुत 50+ सेवाएं अपने क्षेत्र में प्रदान करना और अच्छा पैसा बनाना <br />
                                    तो ही आज ही जुड़े डिजिटल ग्रामीण सेवा से
                                </p>
                                <p>
                                    Digital Gramin Seva में रजिस्ट्रेशन करने के लिए नीचे दिए गए लिंक पर क्लिक करें
                                </p>

                                <p>
                                    <b>
                                        ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                    </b>
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FSSAIRegistration