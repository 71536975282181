import React from 'react'
import Silder from './Silder'

const HitachiAtm = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards8.png")} alt="" /></span>
                                <span className='Main_Title'>Hitachi ATM</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> Digital Gramin Seva Provide facility of cash deposit. </h3>
                                <p>
                                Hitachi Money Spot ATM" is the brand of our White label ATM deployment in India. Hitachi ATM one of the select non-Bank entities in India which has been licensed by the Reserve Bank of India (RBI) to deploy White label ATM's across the country with a strong focus in Tier 3 to Tier 6 towns for deployments. Deployment of White label ATM's will help in:
                                </p>

                                <h2> Features </h2>
                                <ul>
                                    <li> Increasing the spread of ATM's across all population centres in India </li>
                                    <li> Delivering a wide variety of banking services to customers across the banking industry. </li>
                                    <li> Expanding the scope of banking to anytime, anywhere banking through interoperable platforms provided by authorised, shared ATM Network Operators / Card Payment Network Operators. </li>
                                </ul>

                                <h6 className='fw-bold'> Hitachi Money Spot ATM's can help in bridging the banking divide amongst India's population, by providing the following services </h6>
                                <ul>
                                    <li> Cash withdrawal facilities for all bank debit card holders </li>
                                    <li> Pin change facilities </li>
                                    <li> Balance enquiry </li>
                                    <li> Mini Statement </li>
                                    <li> Perform card-card money transfers across banks </li>
                                    <li> Cheque book request </li>
                                    <li> Statement request </li>
                                </ul>

                                <h2> Benefits of Hitachi ATM </h2>
                                <ul>
                                    <li> Cash Safety </li>
                                    <li> Higher Store Reputation </li>
                                    <li> Increased Store FootFall </li>
                                    <li> Additional Assured Income </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default HitachiAtm