import React from 'react'
import "./ApplyForJob.css"

const ApplyForJob = () => {
    return (

        <>

            <section>
                <div className="container-fluid " style={{ backgroundImage: `url(${require("../Img/ApplyForJobBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >

                </div>
            </section>

            <section>
                <div className="container-fluid " style={{ backgroundImage: `url(${require("../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container p-lg-4">
                        <div className="row justify-content-center">
                            <div className='col-lg-11 Apply_For_Job'>
                                <h2> Apply For Job at Digital Gramin Seva </h2>
                                <h2> Golden opportunity of a lifetime for everyone! </h2>
                                <p className='mt-5'>
                                    Digital Gramin Seva is currently hiring a field executive for their Know Your Customer (KYC) team. As a field executive, the candidate will be responsible for visiting customers in rural areas to collect and verify their personal and financial information. This information will then be used to activate retailer account and provide other financial services to customers. The candidate should have good communication skills, be able to work independently. Knowledge of local language and customs is a plus. This is a full-time, fieldbased job with opportunities for growth within the company.
                                </p>
                                <p>
                                    As you all know that by joining Digital Gramin Seva as a franchisee partner, you can set up your business with less investment in less time and increase your income.
                                </p>
                                <p>
                                    Moreover, Digital Gramin Seva gives unlimited benefits to all those and increases job opportunities who want to join or want to join us.
                                </p>
                                <p>
                                    Out of that, there is another way one can work with us, in which you don't need any higher degree or more marks, or even a regular college degree.
                                </p>
                                <p>
                                    Yes, you heard it right, Digital Gramin Seva brings you an amazing job opportunity.
                                </p>
                                <p>
                                    The dream of a better monthly income can be fulfilled on the basis of a 12th pass.
                                </p>
                                <p>
                                    And not only this, apart from the fixed salary or income from this job, you can also get many opportunities to lead in higher positions as a team leader or with time. Now you must be thinking how is this possible?
                                </p>
                                <p>
                                    So we want to tell you that any fresher candidate or job seeker can apply and join us and earn money that too not on fixed office timings but also on flexible timings.
                                </p>
                                <p>
                                    You can get a Field Officer position in our KYC (Know Your Customer) department team, good monthly income as well as lots of career growth opportunities to be a part of our team.
                                </p>
                                <p>
                                    To get a full-time job opportunity, here you don't have to report to anyone and get a chance to work independently with flexible hours.
                                </p>
                                <p>
                                    As far as job prospects and responsibilities are concerned, the Field Officer has to visit all our customers in rural areas and conduct background checks of customers by verifying their personal information and financial information.
                                </p>
                                <p>
                                    And when all the details are cross-checked and verified through you the retailer account will be activated.
                                </p>
                                <p>
                                    So, exploring new places, a chance to communicate with new people, generating more ideas, etc.
                                </p>

                                <div>
                                    <h2> Responsibilities: </h2>
                                    <ul>
                                        <li>Visiting customers in urban & rural areas to collect and verify their personal and financial information.</li>
                                        <li>Ensure that all collected information is accurate and up-to-date</li>
                                        <li>Maintain records of all customer interactions</li>
                                        <li>Communicate effectively with customers to establish trust and build rapport</li>
                                        <li>Meet established targets for number of visits and information collected</li>
                                        <li>Any other tasks as assigned by the management</li>
                                    </ul>
                                </div>

                                <div>
                                    <h2> Benefits of Work With Us: </h2>
                                    <ul>
                                        <li>Competitive salary and benefits package</li>
                                        <li>Employee development programs and training opportunities</li>
                                        <li>Attractive Incentive</li>
                                        <li>Provident Fund (PF) and Employee State Insurance (ESI) benefits</li>
                                        <li>Comprehensive health and insurance coverage.</li>
                                        <li>Work with a growing organization making a positive impact in rural communities</li>
                                        <li>Work-life balance with flexible working hours</li>
                                    </ul>
                                </div>

                                <div>
                                    <h2> Requirements: </h2>
                                    <ul>
                                        <li>Excellent communication skills, both written and verbal</li>
                                        <li>Ability to work independently and in a team-oriented environment</li>
                                        <li>Strong organizational and time management skills</li>
                                        <li>Ability to work in rural areas and travel as needed</li>
                                        <li>12th Pass Preferred</li>
                                        <li>Must have a valid driving license</li>
                                    </ul>
                                </div>

                                <p>
                                    Will be an opportunity for those who are looking for job opportunities. All on-the-job training and customer details will be provided by us.
                                </p>
                                <p>
                                    If the officer visits the location and knows the local language very well, here you can easily understand the languages and explain the process to them and provide all the relevant information to the customers, it will be an added advantage.
                                </p>
                                <p>
                                    Moreover, along with the monthly salary, you can also earn huge incentives as per your performance at the workplace.
                                </p>
                                <p>
                                    You will get opportunities for growth and a chance to participate in training programs, you will get paid to improve, upgrade your skills and develop your personality.
                                </p>
                                <p>
                                    Apart from this, you will also avail of PF (Provident Fund), and ESI benefits (Employees' State Insurance) and manage your personal and professional life smoothly.
                                </p>
                                <p>
                                    So, what are you waiting for, if any of you are looking for such a once-in-a-lifetime career opportunity then don't wait any longer.
                                </p>
                                <p>
                                    Hurry up! Contact us via call, or visit our official website, careers section jobs and apply by sharing your updated CV.
                                </p>
                                <p>
                                    A representative from our recruitment team will get in touch with you shortly.
                                </p>
                                <p>
                                    Feel free to contact us.
                                </p>
                                <button className='applybtn'>
                                    Apply Now
                                </button>
                                <h3>
                                    +91-83838-86969
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ApplyForJob