import React from 'react'
import Silder from './Silder'

const MoneyTransfer = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards2.png")} alt="" /></span>
                                <span className='Main_Title'>Money Transfer</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> Starting and growing a money transfer business can have many benefits </h3>
                                <p>
                                    Everyone can earn through his/her income or one may earn good money from his/her full-time job but most of the earnings can help in utilizing daily requirement thus there's no saving or comfort in life. A Money Agent can earn a high amount of commission without much diligence .this may increase your Money transfer business.                                </p>
                                <p>
                                    To become a money Agent you don’t need a high amount of investment. It’s easy to start out your own money transfer business without low cost which may be earned easily.                                </p>

                                <h2> Benefits: </h2>
                                <ul>
                                    <li> High commission earnings. </li>
                                    <li> Simple process to become an agent </li>
                                    <li> Available 24/7 </li>
                                    <li> Send Money Instanly </li>
                                </ul>

                                <h2> Process to become an Money Transfer agent </h2>
                                <h6> It is a very easy and simple way to become a money transfer agent. </h6>
                                <ul>
                                    <li> First you need to connect with Digital Gramin Seva </li>
                                    <li> To join you have to click on the registration button above. </li>
                                    <li> After registration is complete, you will get Retailer ID password </li>
                                    <li> You can provide AEPS service to your customers by logging in as soon as you get the ID password </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default MoneyTransfer