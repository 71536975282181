import React from 'react'

const CashDepositBlog = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <h1>
                                    Cash Deposit
                                </h1>
                                <h5>
                                    Feb 22, 2021 by IMOC Digital Services Private Limited
                                </h5>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/cash-deposit-banner.png")} alt="" />
                                <p>
                                    इस आर्टिकल में आप जानेंगे आप कैसे Cash Deposit की सुविधा प्रदान कर सकते हैं और इससे आपको और आपके ग्राहक को क्या फायदे मिलते हैं इस Cash Deposit की सुविधा को लेकर कैसे आप अपनी Shop मिनी बैंक में बदल कर अच्छा कमिशन कमा सकते हैं |जैसा की आप जानते है हमारे भारत देश में ग्रामीण क्षेत्रों में बैंकों की संख्या: बहुत कम है जिसकी वजह से ग्रामीण क्षेत्र के निवासियों को अपना पैसा अपने बैंक में जमा करने में बहुत दिक्कत का सामना करना पड़ता है
                                    ग्रामीण क्षेत्रो में बैंक की सुविधाएं अगर है भी तो वह कसबे से दूर या शहरी क्षेत्रों में है | इस पैसा जमा करने की दिक्कत का समाधान आप अपने दूकान से दे सकते है अगर आप Cash Deposit की सुविधा प्रदान करते है तो आपके ग्रामीण क्षेत्र के व्यक्ति अपने गांव मैं ही अपने घर के आस-पास में बैंकिंग सुविधा प्राप्त कर सकते हैं।
                                </p>

                                <h2>
                                    Cash Deposit प्रदाता को मिलने वाले फायदे
                                </h2>
                                <p>
                                    कॅश डिपाजिट द्वारा की गयी हर एक ट्रांसक्शन पर बैंक्स की तरफ से आकर्षक कमीशन दिया जाता है <br />
                                    रिटेलर की दूकान बदले मिनी बैंक में <br />
                                    कमाई करने का सबसे अच्छा और सबसे आसान जरिया
                                </p>

                                <h2>
                                    Cash Deposit से ग्राहक को मिलने वाले फायदे
                                </h2>
                                <p>
                                    कॅश डिपाजिट करना बहुत ही आसान <br />
                                    बिना बैंक या एटीएम के कर सकें पैसा जमा <br />
                                    मिले घर के नज़दीक बैंकिंग सुविधा <br />
                                    किसी भी तरह की कोई पर्ची भरने या हस्ताक्षर करने की जरुरत नहीं
                                </p>

                                <h2>
                                    CASH डिपाजिट प्रदाता को लेन देन करने के लिए आवशयक चीज़े
                                </h2>
                                <p>
                                    बैंक का नाम <br />
                                    बैंक का खाता नंबर <br />
                                    खाताधारक का मोबाइल नंबर
                                </p>

                                <h2>
                                    Digital Gramin Seva में कॅश डिपाजिट पर कितना कमीशन मिलता है?
                                </h2>
                                <p>
                                    जैसा की आप जान चुके हैं Cash Deposit से मिलने वाले फायदे और Cash Deposit सर्विस का इस्तेमाल करने के लिए किन किन चीज़ो की आवश्यकता होती है
                                </p>
                                <p>
                                    अगर आप Cash Deposit सेवा प्रदान करना चाहते हैं तो आप हमारे पोर्टल Digital Gramin Seva से जुड़कर Cash Deposit सेवा और बहुत सी डिजिटल सेवाएं अपने ग्रामीण क्षेत्रो में प्रदान कर सकते हैं
                                </p>

                                <p>
                                    <b>
                                        ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                    </b>
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CashDepositBlog