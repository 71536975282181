import React from 'react'
import Silder from './Silder'

const PanCardNsdl = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>  Pan Card UTI </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <p>
                                        Become an authorized Pancard agent for providing basic
                                        services like making a new Pancard to updating an old one to
                                        your customers.
                                    </p>
                                    <h1>What is a Permanent Account Number?</h1>

                                    <p>
                                        PAN or Permanent Account Number is a 10 digit alpha-numeric
                                        identity issued by the Income Tax Department of India. It is
                                        a unique account number allocated by the Income Tax
                                        Department, which is used to track and manage all the Income
                                        Tax assessee in India.
                                    </p>
                                    <p>
                                        Although the PAN card application is voluntary, it must be
                                        obtained before engaging in certain transactions. For
                                        entering into a financial transaction exceeding a specified
                                        amount, quoting PAN is compulsory. Also, every business
                                        entity must apply for PAN card application within the 30
                                        days of its formation or incorporation.
                                    </p>

                                    <h2>Documents Required for PAN application</h2>

                                    <ul class="bc-service-list m-0">
                                        <li>Address Proof (Aadhar/Bank Statement)</li>
                                        <li>Photograph</li>
                                        <li> Birth Certificate (in case of Minor) </li>
                                        <li>
                                            Certificate of Incorporation (in case of Company, LLP)
                                        </li>
                                        <li>Agreement (in case of LLP and Partnership Firm)</li>
                                    </ul>

                                    <h3>
                                        Benefits of PAN agency registration with Digital Gramin
                                        Seva:
                                    </h3>
                                    <ul class="bc-service-list m-0">
                                        <li>Earn commission on each PAN card</li>
                                        <li>Constant support from our team</li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default PanCardNsdl