import React from 'react'
import "./TermCond.css"
import Silder from './Silder'

const TearmCondition = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container-lg p-lg-4">
                        <div className='text-center mt-3 mb-lg-5 mb-2'>
                            <h2> Tearms And Conditions </h2>
                            <div className='d-flex justify-content-center align-items-center ' >
                                <div className='' style={{ borderTop: "3px solid orange", width: "3%" }}>
                                </div>
                            </div>
                        </div>
                        <div className="row p-lg-4">
                            <div className="d-none d-sm-block">
                                <div className="embed-responsive embed-responsive-16by9 ">
                                    <iframe className="embed-responsive-item Pdf-size" src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf" allowfullscreen></iframe>
                                </div>
                            </div>

                            <div className="d-sm-none d-block">
                                <div className="embed-responsive embed-responsive-21by9 ">
                                    <iframe className="embed-responsive-item Pdf-size" src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default TearmCondition