import React from 'react'
import "./Footer.css";

const Footer = () => {
  return (
    <>

      <footer className="main-footer">
        <div className="container-fluid p-0">
          <div className="container Open-Sans-Serif">
            <div className="top-footer">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-8">
                  <div className="ftLogo d-flex align-items-center">
                    <div className="footer-logo">
                      <a href="#">
                        <img className='footer-logo_img' src={require("../Img/logo.png")} alt="fb" />
                      </a>
                    </div>
                    <h6>IMOC Digital Services Pvt Ltd.</h6>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <div className="social-sites">
                    <h5>Follow Us on:</h5>
                    <ul className="d-flex">
                      <li>
                        <a href="https://www.facebook.com/icloudsofttechnology">
                          <img src={require("../Img/facebookIcon.png")} alt="fb" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/icloud_soft/">
                          <img src={require("../Img/instagram.png")} alt="fb" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/icloudsofttechnology">
                          <img src={require("../Img/twiteer.png")} alt="fb" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com">
                          <img src={require("../Img/youtube.png")} alt="fb" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-footer">
              <div className="row p-2">
                <div className="col-xl-4 col-lg-4 col-md-4">
                  <p>
                    Digital Gramin Seva offers a range of banking services such as
                    aadhar based payment system, cash deposit, money transfer,
                    aadhar pay, and account opening. The company also provides
                    government services such as filing of income tax returns, PAN
                    card application, and passport application.
                  </p>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <h4>Address:</h4>
                  <p>
                    Vardhman South Plaza, 3rd Floor, Local Shopping Centre, Pocket
                    H, Sarita Vihar, New Delhi, Delhi 110076
                  </p>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2">
                  <h4>Call:</h4>
                  <p>
                    <a href="tel:8383928391">
                      <i class="fa-solid fa-phone me-2"></i>
                      +91-83839-28391
                    </a>
                  </p>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3">
                  <h4>Email:</h4>
                  <p>
                    <a href='mailto:support@digitalgraminseva.in'>
                      <i class="fa-solid fa-envelope me-2"></i>
                      support@digitalgraminseva.in
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="footer-linkes-cards">
              <div className="row p-2">
                <div className="col-xl-5 col-lg-5 col-md-6">
                  <h3>Useful Links:</h3>
                  <div className="links-row">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/blogs">Blogs</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="/legaldocs">Legal Docs</a>
                      </li>
                      <li>
                        <a href="/applyforjob">Career</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/contact">Contact Us</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="/privacypolicy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/tearmcondition">Terms & Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-7 col-lg-7 col-md-6">
                  <h3>Services:</h3>
                  <div className="links-row">
                    <ul>
                      <li>
                        <a href="/services">Banking Services</a>
                      </li>
                      <li>
                        <a href="/services">B2C Services</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="/services"> G2C Services</a>
                      </li>
                      <li>
                        <a href="/services">GST Services</a>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <a href="/services">Travel Services</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="container-fluid p-0">
        <div className='text-lg-center p-3 text-white ' style={{ backgroundColor: "#424242" }}>
          <h5 className='' style={{ fontSize: "0.835rem", color: "white", fontWeight: "400" }} >Copyright © Digital Gramin Seva 2019. All Right Reserved</h5>
        </div>
      </div>

    </>
  )
}

export default Footer