import React from 'react'
import Silder from './Silder'

const StateCertificate = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                        <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> State Certificate </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2>
                                        You can apply Uttar Pradesh & Bihar State certificates to
                                        anyone in Digital Gramin Seva
                                    </h2>
                                    <p>
                                        Apply online for various certificates such as the death
                                        certificate, birth certificate, income certificate,
                                        Disability certificate, caste certificate insolvency
                                        certificate, etc. issued by Government. To apply for these
                                        documents, you need to select the district you reside in and
                                        then proceed with filling up an online form on Digital
                                        Gramin Seva.
                                    </p>
                                    <p>
                                        Make the necessary certificates for the people around you
                                        and earn good income.
                                    </p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default StateCertificate