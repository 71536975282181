import React from 'react'
import Silder from './Silder'

const CashDeposite = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>Cash Deposit</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> Digital Gramin Seva Provide facility of cash deposit. </h3>
                                <p>
                                    With this cash deposit facility, you can convert your shop into a mini bank and earn a good commission. As you know, the number of banks in rural areas in our India country is very less, due to which the residents of rural areas face a lot of difficulty in depositing their money in their bank.
                                </p>
                                <p>
                                    Even if the bank has facilities in rural areas, it is far away from the town or in urban areas.
                                </p>
                                <p>
                                    You can give a solution to the problem of depositing money from your shop.
                                </p>
                                <p className='mt-5'>
                                    You will get the commission in every successful transaction.
                                </p>

                                <h2> Features </h2>
                                <ul>
                                    <li> Deposit Money instantly </li>
                                    <li> Available 24/7 </li>
                                    <li> Deposits may occur on Sundays and public holidays also </li>
                                    <li> Instant confirmation to sender by SMS </li>
                                    <li> Safe and Secure transaction </li>
                                </ul>

                                <h2> Benefits </h2>
                                <h6> It is a very easy and simple way to become a money transfer agent. </h6>
                                <ul>
                                    <li> Deposit money to all PSUs and private banks </li>
                                    <li> Easy to use cash deposit service </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default CashDeposite