import React from 'react'
import "./Services.css"
import { NavLink } from 'react-router-dom'

const Services = () => {
    return (
        <>

            <section>
                <div className="container-fluid">
                    <div className="container p-lg-4 main_services1">
                        <div className='p-2 mb-3'>
                            <h6 className='' style={{ fontSize: "1.3rem", color: "#FF6700", fontWeight: "700" }} > Services </h6>
                            <h3 className='mt-lg-3 pt-3' style={{ fontSize: "2.1rem", color: "black", fontWeight: "700" }} > Banking Services </h3>
                        </div>
                        <div className="row g-4 p-lg-2 main_services_cards_box">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/aepservice'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Aeps </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/moneytransfer'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards2.png")} alt="" />
                                        </div>
                                        <h4> Money Transfer </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/cashdeposite'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards3.png")} alt="" />
                                        </div>
                                        <h4> Cash Deposit </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/aadharpay'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Aadhar Pay </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/cspbanking'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards4.png")} alt="" />
                                        </div>
                                        <h4> CSP Banking </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/bankaccountopening'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards5.png")} alt="" />
                                        </div>
                                        <h4> Bank Account Opening </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/loanapply'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Loan Apply </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/demataccount'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards2.png")} alt="" />
                                        </div>
                                        <h4> Demat Account </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/miniatm'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards3.png")} alt="" />
                                        </div>
                                        <h4> Mini ATM </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/hitachiatm'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Hitachi ATM </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/creditcard'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards4.png")} alt="" />
                                        </div>
                                        <h4> Creadit Card Apply </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/upi'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards5.png")} alt="" />
                                        </div>
                                        <h4> UPI Qr Collection </h4>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-lg-4 main_services2">
                        <div className='p-2 mb-3'>
                            <h3 className='' style={{ fontSize: "2.1rem", color: "black", fontWeight: "700" }} > B2C Services </h3>
                        </div>
                        <div className="row g-4 p-lg-2 main_services_cards_box">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/mobilerecharge'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Mobile Recharge </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/dthrecharge'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards2.png")} alt="" />
                                        </div>
                                        <h4> DTH Recharge </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/electricitybillpayment'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards3.png")} alt="" />
                                        </div>
                                        <h4> Electricity Bill Payment </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/waterbillpayment'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards4.png")} alt="" />
                                        </div>
                                        <h4> Water Bill Payment </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/gasbillpayment'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards5.png")} alt="" />
                                        </div>
                                        <h4> Gas Bill Payment </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/fastag'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards6.png")} alt="" />
                                        </div>
                                        <h4> FASTAG </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/municipalitybillpayment'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards7.png")} alt="" />
                                        </div>
                                        <h4> Municipality Bill Payment </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/insurancebillpayment'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards8.png")} alt="" />
                                        </div>
                                        <h4> Insurance Bill Payment </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/licbillpayment'>
                                        <div>
                                            <img className='' src={require("../Img/Section2service-cards9.png")} alt="" />
                                        </div>
                                        <h4> LIC Bill Payment </h4>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-lg-4 main_services3">
                        <div className='p-2 mb-3'>
                            <h3 className='' style={{ fontSize: "2.1rem", color: "black", fontWeight: "700" }} > G2C Services </h3>
                        </div>
                        <div className="row g-4 p-lg-2 main_services_cards_box">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/aadharupdate'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Aadhar Update </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/aadharudyog'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Aadhar Udyog </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/statecertificate'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards1.png")} alt="" />
                                        </div>
                                        <h4> State Certificate </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/labourcard'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards2.png")} alt="" />
                                        </div>
                                        <h4> Labour Card </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/drivinglicence'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards3.png")} alt="" />
                                        </div>
                                        <h4> Driving Licence </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/passport'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards4.png")} alt="" />
                                        </div>
                                        <h4> Passport </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/aadharcenterapply'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Aadhar Center Apply </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/eshramcardapply'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards5.png")} alt="" />
                                        </div>
                                        <h4> E-Shram Card Apply </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/rationcard'>
                                        <div>
                                            <img className='' src={require("../Img/service-cards1.png")} alt="" />
                                        </div>
                                        <h4> Ration Card </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/pmkisanscheme'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards6.png")} alt="" />
                                        </div>
                                        <h4> PM Kisan Scheme </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/voteridnew'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards7.png")} alt="" />
                                        </div>
                                        <h4> Voter ID New </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/voteridcorrection'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards7.png")} alt="" />
                                        </div>
                                        <h4> Voter ID Correction </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/pancarduti'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards8.png")} alt="" />
                                        </div>
                                        <h4> Pan Card UTI </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/pancardnsdl'>
                                        <div>
                                            <img className='' src={require("../Img/Section3service-cards8.png")} alt="" />
                                        </div>
                                        <h4> Pan Card NSDL </h4>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-lg-4 main_services4">
                        <div className='p-2 mb-3'>
                            <h3 className='' style={{ fontSize: "2.1rem", color: "black", fontWeight: "700" }} > G2C Suvidha Kendra </h3>
                        </div>
                        <div className="row  g-4 p-lg-2 main_services_cards_box">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/gstregistration'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/Section4service-cards1.png")} alt="" />
                                        </div>
                                        <h4> GST Registration </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/gstreturn'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards1.png")} alt="" />
                                        </div>
                                        <h4> GST Return </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/gstcorrection'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards1.png")} alt="" />
                                        </div>
                                        <h4> GST Correction </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/tdsreturn'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards2.png")} alt="" />
                                        </div>
                                        <h4> TDS Return </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/fssairegistration'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards3.png")} alt="" />
                                        </div>
                                        <h4> FSSAI Registration </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/privatelimitedregistration'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards4.png")} alt="" />
                                        </div>
                                        <h4> Private Limited Registration </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/addremovepartner'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards5.png")} alt="" />
                                        </div>
                                        <h4> Add/Remove Partner </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/changebusinessname'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards6.png")} alt="" />
                                        </div>
                                        <h4> Change Business Name </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/changeofficeaddress'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards7.png")} alt="" />
                                        </div>
                                        <h4> Change Office Address </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/incometaxreturn'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards2.png")} alt="" />
                                        </div>
                                        <h4> Income Tax Return </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/onepersoncompany'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards4.png")} alt="" />
                                        </div>
                                        <h4> One Person Company </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/tanregistration'>
                                        <div>
                                            <img className='' src={require("../Img/Section4service-cards8.png")} alt="" />
                                        </div>
                                        <h4> Tan Registration </h4>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container p-lg-4 main_services5">
                        <div className='p-2 mb-3'>
                            <h3 className='' style={{ fontSize: "2.1rem", color: "black", fontWeight: "700" }} > Travel Services </h3>
                        </div>
                        <div className="row  g-4 p-lg-2 main_services_cards_box">
                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards'>
                                    <NavLink className='text-decoration-none' to='/busbooking'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <img className='' src={require("../Img/LastSectionservice-cards1.png")} alt="" />
                                        </div>
                                        <h4> Bus Booking </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/flightbooking'>
                                        <div>
                                            <img className='' src={require("../Img/LastSectionservice-cards2.png")} alt="" />
                                        </div>
                                        <h4> Flight Booking </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/hotelbooking'>
                                        <div>
                                            <img className='' src={require("../Img/LastSectionservice-cards3.png")} alt="" />
                                        </div>
                                        <h4> Hotel Booking </h4>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-4 col-sm-4 col-6">
                                <div className='services_cards '>
                                    <NavLink className='text-decoration-none' to='/trainbooking'>
                                        <div>
                                            <img className='' src={require("../Img/LastSectionservice-cards4.png")} alt="" />
                                        </div>
                                        <h4> Train Booking </h4>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Services