import React from 'react'

const G2CServicesBlog = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/g2c-banner.png")} alt="" />
                                <h2>
                                    G2C Services
                                </h2>
                                <p>
                                    इस आर्टिकल में हम आपको बताएँगे G2C में आपको कौन कौन सी सर्विस मिलती है
                                </p>

                                <h2>
                                    1. Aadhar Update
                                </h2>
                                <p>
                                    आप अपने कस्टमर्स के आधार कार्ड में करेक्शन कर सकते हैं बड़ी ही आसान तरीके से
                                </p>

                                <h3>
                                    आधार कार्ड अपडेट करते समय ध्यान रखने योग्य महत्वपूर्ण बातें
                                </h3>
                                <p>
                                    व्यक्तिगत रूप से किए जाने वाले परिवर्तन सही होने चाहिए व उपयुक्त दस्तावेज़ होने चाहिए, जो कि किसी व्यक्ति के फॉर्म के साथ संलग्न है <br />
                                    आवश्यक जानकारी अंग्रेजी या स्थानीय भाषा में दर्ज की जानी चाहिए <br />
                                    आधार कार्ड जानकारी को सही करते समय, यह सुनिश्चित किया जाना चाहिए कि URN को सुरक्षित रखा गया है क्योंकि यह कार्ड को अपडेट की स्थिति की जांच करने में मदद करेगा <br />
                                    यदि कार्ड धारक के पास अब रजिस्टर्ड फोन नंबर नहीं है, तो आधार कार्ड को सही करने की ऑफ़लाइन विधि अपनाई जानी चाहिए <br />
                                    सुनिश्चित करें कि सुधार फ़ॉर्म में दर्ज सभी जानकारी बड़े अक्षरों में भरी गई हो <br />
                                    सभी आवश्यक जानकारी भरी जानी चाहिए और किसी भी विकल्प को खाली नहीं रखा जाना चाहिए <br />
                                    धारक का नाम लिखते समय किसी भी पदनाम का उपयोग न करें <br />
                                    केवल उन दस्तावेजों को जो प्रमाण के रूप में आवश्यक हैं, उन्हें ही फॉर्म के साथ भेजा जाना चाहिए <br />
                                    कार्ड धारक द्वारा भेजे गए दस्तावेजों की फोटोकॉपी को प्रेषक द्वारा सेल्फ अटेस्ट किया जाना चाहिए
                                </p>

                                <h2>
                                    Udyog Aadhar
                                </h2>
                                <p>
                                    इस उद्योग आधार सर्विस के जरिये आप अपने कस्टमर्स का उद्योग आधार रजिस्ट्रेशन कर सकते है डिजिटल ग्रामीण सेवा पोर्टल से
                                </p>

                                <h2>
                                    Udyog Aadhar क्या है?
                                </h2>
                                <p>
                                    पहले किसी भी बिजनेस को शुरू करने के लिए Registration Process बहुत ही मुश्किल हुआ करता था। पहले बिजनेस को शुरू करने के लिए दो फॉर्म भरे जाता थे- EM-1(Entrepreneurs Memorandum 1) और EM-2(Entrepreneurs Memorandum 2) इन दोनों तरीके के फॉर्म्स के साथ 11-12 प्रकार के अन्य forms भी भरे जाते थे।
                                </p>
                                <p>
                                    लेकिन अब सरकार ने MSME (Ministry of Micro, Small & Medium Enterprises) की मदद से Udyog Aadhaar, । इसकी मदद से आप अपने छोटे से लेकर मध्यम लागत के व्यापार को ऑनालाइन कुछ ही मिनटों में udyog aadhar registration करके अपना Registration Acknowledgement पा सकते हैं।
                                </p>

                                <p>
                                    <b>
                                        उद्योग आधार के फायदें निम्न हैं:
                                    </b>
                                    <p>
                                        योजना का रजिस्ट्रेशन ऑफलाइन के साथ ऑनलाइन भी होता है। <br />
                                        किसी भी सरकारी योजना का लाभ सबसे पहले मितला है। <br />
                                        एक व्यक्ति/एक कारोबार को एक ही बार उद्योग आधार नंबर मिलता है। <br />
                                        प्रक्रिया काफी लचीली है। <br />
                                        रजिस्ट्रेशन बिना कोई शुल्क दिए होता है।
                                    </p>
                                </p>

                                <h2>
                                    3. State Certificate
                                </h2>
                                <p>
                                    आप अपने ग्राहकों के जरुरी स्टेट सर्टिफिकेट में आने वाले सर्टिफिकेट के लिए अप्लाई कर सकते हैं
                                </p>
                                <p>
                                    <b>
                                        Certtificate:-
                                    </b>
                                    Income Certificate <br />
                                    Caste Certificate <br />
                                    Death & Birth certificate <br />
                                    Domicile Certificate <br />
                                    Disability Certificate
                                </p>

                                <h2>
                                    4. Voter ID
                                </h2>
                                <p>
                                    आप आप कस्टमर का Voter ID Card Apply कर सकते हैं
                                </p>

                                <h2>
                                    What is Voter ID?
                                </h2>
                                <p>
                                    भारतिया नागरिक के लिए वोटर आईडी कार्ड एक आइडेंटिटी प्रूफ या फिर अड्रेस प्रूफ के तौर पर काम आती है. ये कार्ड सिर्फ़ वोट देने के ही काम नही आती, बहुत सारी सरकारी योजनाए भी होती हैं जहाँ इस की ज़रूरत पड़ती है.
                                </p>

                                <h2>
                                    वोटर आईडी अप्लाई करने के लिए जरुरी डाक्यूमेंट्स
                                </h2>
                                <p>
                                    <b>
                                        Age and Identity Proof
                                    </b>
                                    Mark Sheet of 10th, 8th class. <br />
                                    Birth certificate <br />
                                    PAN Card <br />
                                    Driving License <br />
                                    Aadhar Card Issued by UIDAI

                                    <p>
                                        <b>
                                            Address proof
                                        </b>
                                        Aadhar card <br />
                                        Passport <br />
                                        Ration Card <br />
                                        Driving License <br />
                                        Rent Agreement <br />
                                        Income Tax <br />
                                        Water Bill <br />
                                        Telephone Bill <br />
                                        Bank Passbook <br />
                                        Income Tax Assesment Order <br />
                                        Post/ letter/ mail delivered through Indian Postal department.
                                    </p>
                                </p>

                                <p>
                                    <b>
                                        Recent Passport Size Photo
                                    </b>
                                </p>

                                <h2>
                                    5. Driving Licence
                                </h2>
                                <p>
                                    आप अपने ग्राहकों का ड्राइविंग लाइसेंस अप्लाई कर सकते हैं
                                </p>

                                <h2>
                                    What is Driving Licence?
                                </h2>
                                <p>
                                    Driving license एक ऐसा document हैं जिसे की Government of India द्वारा issue किया जाता है. इसमें लोगों को ये permit मिलती है की वो अपने यान car, motorbike, truck, bus या दूसरा कोई यान भी हो उनको रास्ते या public road में officially चला सकें. भारत में driving license को issue उसी राज्य के Regional Transport Authority (RTA) or Regional Transport Office (RTO) के द्वारा किया जाता है. सन 1988 में जारी की गयी Motor Vechiles Acts के तहत कोई भी नागरिक बिना Driving License के public road पर गाड़ी नहीं चला सकता. यदि ऐसा करते वक़्त पकड़ा गया तो उसे उचित fine भी भरना पड़ सकता है.
                                </p>
                                <p>
                                    Driving License के लिए क्या Documents की जरुरत होती है
                                </p>
                                <p>
                                    Age Proof (इनमें से कोई एक डॉक्यूमेंट चाहिए)
                                </p>
                                <p>
                                    1. Birth Certificate <br />
                                    2. PAN Card <br />
                                    3. Passport <br />
                                    4. 10th Classmark sheet
                                </p>

                                <h2>
                                    Proof of Address required for DL:
                                </h2>
                                <p>
                                    Permanent Proof of address (इनमें से कोई एक डॉक्यूमेंट चाहिए): <br />
                                    Passport <br />
                                    Aadhaar Card <br />
                                    Self-owned house agreement <br />
                                    Electricity bill (issued in applicants name) <br />
                                    LIC bond <br />
                                    Voter ID Card <br />
                                    Ration card <br />
                                    <b> Current </b> Proof of address (anyone from the following): <br />
                                    Rental agreement and electricity bill <br />
                                    Rental agreement and LPG bill
                                </p>

                                <h2>
                                    Driving License के दुसरे requirements:
                                </h2>
                                <p>
                                    पुरे अच्छे तरीके से application form को भरा जाना चाहिए (application form को आप पास के RTO से ला सकते हैं या online portal से भी download कर सकते हैं) <br />
                                    6 passport sized photographs (यदि आप Learners License के लिए apply कर रहे हैं) <br />
                                    1 passport sized photograph (यदि आप driving license के लिए apply कर रहे हैं) <br />
                                    Application Fees जरूत अनुसार <br />
                                    यदि आप किसी दुसरे शहर में वास कर रहे हैं तब आप अपने current address proof के तोर पे आप अपने rental agreement with one recent utility bill copy जो की gas bill या electric bill भी हो सकता है को प्रदान कर सकते हैं. <br />
                                    Medical Certificate – Form 1 A and 1 जिसे की किसी certified Government doctor के द्वारा approve किया गया हो <br />
                                    सारे applicants जिनकी आयु 40 years से अधिक है उन्हें Medical certificate को प्रदान करना अत्यंत जरुरी है.
                                </p>

                                <h2>
                                    6. Passport
                                </h2>
                                <p>
                                    आप अपने ग्राहकों का Passport अप्लाई कर सकते हैं
                                </p>

                                <h2>
                                    What is Passport?
                                </h2>
                                <p>
                                    एक Indian passport एक officially-issued document है जो की इसे धारण करने वालों को ये permit देता हैं जिसकी मदद से ये दुसरे देशों को आ जा सकता है. इसके साथ ये एक बहुत बड़ा identity और address proof है किसी भी व्यक्ति का.
                                </p>

                                <h2>
                                    भारत में पासपोर्ट के प्रकार
                                </h2>
                                <p>
                                    1. Type P / Ordinary passport– ‘P’ denotes करता है ‘Personal’. ये Ordinary passports होते हैं जिन्हें की आम लोगों को issue किया जाता है. ये मुख्यतः general कार्य जैसे की travelling करना अपने holiday में या फिर business के लिए में इस्तमाल किया जाता है.
                                </p>
                                <p>
                                    2. Type S / Official passport– ‘S’ denotes करता है ‘Service’. ये Official passports होते हैं और जिन्हें issue किया जाता है उन लोगों के लिए जो की किसी official government/state काम के लिए abroad (विदेश) travel करते हैं.
                                </p>
                                <p>
                                    3. Type D / Diplomatic passport – ‘D’ denotes करता है ‘Diplomat’. ये Diplomatic passports होते हैं जिन्हें की ऐसे लोगों को issue किया जाता है जो की हमेशा official government work में विदेश की यात्रा करते रहते हैं.
                                </p>

                                <h2>
                                    Documents required:
                                </h2>
                                <p>
                                    Photo passbook of running bank account in any public sector bank, private sector bank and regional rural banks <br />
                                    A voter ID card <br />
                                    Aadhaar card <br />
                                    Electricity bill <br />
                                    Rent agreement <br />
                                    Driving license <br />
                                    Landline or postpaid mobile bill <br />
                                    Proof of gas connection <br />
                                    S-pouse’s passport copy (First and last page of the passport that includes the details of the <br />
                                    family and mentions applicants name as the spouse of the passport holder) <br />
                                    Certificate from employer of reputed companies on letterhead. <br />
                                    Income Tax assessment order <br />
                                    School leaving certificate <br />
                                    Birth certificate issued by the Municipal Corporation <br />
                                    Policy Bond issued by the Public Life Insurance Corporations/Companies having the DOB of the holder of the insurance policy
                                </p>

                                <h3>
                                    7. PAN Card
                                </h3>
                                <h2>
                                    What is Pan Card?
                                </h2>
                                <p>
                                    पैन कार्ड का पूरा नाम Permanent Account Number होता है. ये एक identification कार्ड होता है जिसे हर तरह के फाइनेंसियल लेन देन (transaction) के लिए इस्तेमाल किया जाता है. इसे Central Board of Direct Taxes (CBDT) की देख रेख में जारी किया जाता है.
                                </p>

                                <h2>
                                    Pan Card के फायदे
                                </h2>
                                <p>
                                    1इनकम टैक्स में होने वाली प्रॉब्लम्स और गड़बड़ी से ये कार्ड बचाता है. <br />
                                    इँडियन गवर्नमेंट द्वारा इशू किया हुआ कार्ड हर जगह वैलिड है. हर सरकारी ऑफिस और बस ट्रैन हर जगह इसकी मान्यता है. <br />
                                    इसीलिए आप किसी भी सरकारी या प्राइवेट संस्था में इसे ID प्रूफ की तोर पर दे सकते है <br />
                                    Full time के अलावा पार्ट टाइम जॉब में भी इसको दिखाने से आपको भुगतान आसानी से हो जाता है. <br />
                                    अगर आप कहीं पर टेम्पररी जॉब या फिर पार्ट टाइम वर्क करते हैं तो इससे आप साल के लास्ट में TDS claim कर सकते है. <br />
                                    50,000 से ज्यादा की लेन देन करने के लिये. <br />
                                    इससे शेयर मार्किट में 50,000 से ज्यादा की अमाउंट के ट्रांसक्शन करने मे. <br />
                                    किसी होटल में 25,000 से ज्यादा अमाउंट को Pay करने के लिए ये काम करता है.
                                </p>

                                <h2>
                                    नए पैन कार्ड बनाने के लिए डॉक्यूमेंट
                                </h2>
                                <p>
                                    Passport <br />
                                    Voter ID card <br />
                                    Aadhar Card UIDAI <br />
                                    Ration Card <br />
                                    Driving License <br />
                                    Photo Identity Card issued by Central or State Government
                                </p>

                                <p>
                                    जैसा की आप जान चुके है आपको ग्२क में कौन कौन सी सुविधाएं मिलती है प्रदान के लिए अगर आप चाहते है इन सब सेवाओं को अपने क्षेत्र में प्रदान करना और अच्छा पैसा बनाना तो ही आज ही जुड़े डिजिटल ग्रामीण सेवा से
                                </p>

                                <p>
                                    <b>
                                        ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                    </b>
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default G2CServicesBlog