import React from 'react'
import Silder from './Silder'

const AadharUpdate = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                        <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>Aadhar Update</span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <p>
                                        You can update your costumer's Address, Gender, DoB, Father
                                        Name online in Digital Gramin Seva Portal. For other details
                                        updates such as Demographic details (Name, Address, DoB,
                                        Gender, Mobile Number, Email) as well as Biometrics (Finger
                                        Prints, Iris & Photograph) in Aadhaar customer's will have
                                        to visit Enrolment Center.
                                    </p>
                                    <h2 className="mt-0">
                                        What are the documents UIDAI accepts:
                                    </h2>
                                    <p>
                                        As per the updates from the UIDAI, over 5 documents are
                                        being considered valid for proof of identity in Aadhaar
                                        card. The UIDAI accepts 5 documents for date of birth and 9
                                        documents for Proof of Address (PoA). Full list is here:
                                    </p>

                                    <h2>Date of Birth</h2>
                                    <ol className="bc-service-list m-0">
                                        <li>Birth Certificate</li>
                                        <li>Passport</li>
                                        <li>PAN Card</li>
                                        <li>Mark Sheets</li>
                                        <li>SSLC Book/Certificate </li>
                                    </ol>
                                    <h3>Proof Of Identity (PoI)</h3>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default AadharUpdate