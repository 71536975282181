import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import "./Contact.css"

const Contact = () => {

    const form = useRef();

    const [name, setName] = useState("");

    const [number, setNumber] = useState("");

    const [phone, setPhone] = useState("");

    const [email, setEmail] = useState("");

    const [massage, setMassage] = useState("");

    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.number = ""; errors.phone = ""; errors.email = ""; errors.massage = "";

            var url = "https://wa.me/8383928391?text="
                + "NAME:" + name + "NUMBER:" + number + "Phone:" + phone + "EMAIL:" + email + "MASSAGE:" + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }

        if (!number) {
            errors.number = "Number Is Requried";
        }
        else if (number.length !== 10) {
            errors.number = "Number Must Have 10 Digit";
        }

        if (!phone) {
            errors.phone = "Number Is Requried";
        }
        else if (phone.length !== 10) {
            errors.phone = "Number Must Have 10 Digit";
        }

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;
    }

    return (
        <>

            <section>
                <div className="container-fluid ContacBG_Size" style={{ backgroundImage: `url(${require("../Img/contactBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                </div>
            </section>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/contact2BG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'top center' }} >
                    <div className="container p-lg-5 ">
                        <div className='text-center p-lg-4  pt-4'>
                            <h5 className='' style={{ fontSize: "34px", fontWeight: "400" }} >Contact Us</h5>
                            <div className='d-flex justify-content-center align-items-center mt-3 mb-3' >
                                <div className='' style={{ borderTop: "3px solid #FF6700", width: "6%" }}>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-lg-5 ">
                            <div className="col-lg-12">
                                <div className="row d-flex justify-content-around ">
                                    <div className="col-lg-5 col-md-5">
                                        <div className=' mt-4'>
                                            <h5 className='' style={{ fontSize: "24px", fontWeight: "700" }} > Get in touch </h5>
                                            <h5 className='mt-3' style={{ fontSize: "15px", fontWeight: "600" }}> IMOC Digital Services </h5>
                                            <p className='pt-2' style={{ fontSize: "0.911rem" }}> Vadhman South Plaza, 3rd floor, Local Shopping Center, Pocket-H, Srita Vihar, New Delhi - 110076 </p>
                                        </div>
                                        <div className='pt-lg-2'>
                                            <div>
                                                <h5 className='' style={{ fontSize: "15px", fontWeight: "600" }}> Helpline Number: </h5>
                                                <i class="fa-solid fa-phone p-1" style={{ fontSize: "15px", color: "#333", fontWeight: "400" }}  ></i><NavLink className="text-decoration-none  p-1" style={{ fontSize: "15px", color: "#333" }} exact to="tel:8383928391" ><b className='' style={{ fontWeight: "400" }} >+91 8383928391</b></NavLink>
                                            </div>
                                            <div className='pt-3 fs-5 '>
                                                <h5 className='mt-2' style={{ fontSize: "15px", fontWeight: "600" }}> Mail Id: </h5>
                                                <i class="fa-solid fa-envelope" style={{ fontSize: "15px", color: "#333", fontWeight: "400" }}></i><NavLink className='emailHover1 ' to="mailto:support@digitalgraminseva.in"> <span className='text-center ms-2' style={{ fontSize: "15px", color: "#333" }} >support@digitalgraminseva.in</span></NavLink>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-7">
                                        <div className='row mt-2 mb-3' >
                                            <div className='mb-3 pb-2'>
                                                <h2>Send us a message</h2>
                                            </div>
                                            <div className="col text-dark">
                                                <div className=' '  >
                                                    <form ref={form} onSubmit={loginHandle} className="row g-4  " >
                                                        <div class="col-lg-12 ">
                                                            <div className='row g-4'>
                                                                <div className="col-lg-6 col-md-6">
                                                                    <input type="text" className="form-control   text-dark   " style={{ borderRadius: "0.375rem", color: "#777", backgroundColor: "transparent", border: "1px solid #999", height: "45px", fontSize: "0.948rem" }} id="inputName" placeholder="Name:" name="name"
                                                                        onChange={e => setName(e.target.value)} value={name}
                                                                    />
                                                                    {errors.name && <div className='text-danger'>{errors.name}</div>}
                                                                </div>

                                                                <div className="col-lg-6 col-md-6">
                                                                    <input type="text" className="form-control   text-dark " minLength='10' maxLength='10' style={{ borderRadius: "0.375rem", color: "#777", backgroundColor: "transparent", border: "1px solid #999", height: "45px", fontSize: "0.948rem" }} id="inputPhone" placeholder="Phone:" name="phone"
                                                                        onChange={e => setPhone(e.target.value)} value={phone}
                                                                    />
                                                                    {errors.phone && <div className='text-danger'>{errors.phone}</div>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='row g-4'>
                                                                <div className="col-lg-6 col-md-6">
                                                                    <input type="number" className="form-control   text-dark   " minLength='10' maxLength='10' style={{ borderRadius: "0.375rem", color: "#777", backgroundColor: "transparent", border: "1px solid #999", height: "45px", fontSize: "0.948rem" }} id="inputNumber" placeholder="Number:" name="number"
                                                                        onChange={e => setNumber(e.target.value)} value={number}
                                                                    />
                                                                    {errors.number && <div className='text-danger'>{errors.number}</div>}
                                                                </div>

                                                                <div className="col-lg-6 col-md-6">
                                                                    <input type="email" className="form-control boderRadius  text-dark " style={{ borderRadius: "0.375rem", color: "#777", backgroundColor: "transparent", border: "1px solid #999", height: "45px", fontSize: "0.948rem" }} id="inputEmail4" placeholder="Email:" name="email"
                                                                        onChange={e => setEmail(e.target.value)} value={email}
                                                                    />
                                                                    {errors.email && <div className='text-danger'>{errors.email}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <textarea type="text" className="form-control   text-dark " style={{ borderRadius: "0.375rem", height: "135px", backgroundColor: "transparent", border: "1px solid #999", fontSize: "0.948rem" }} id="inputText3" placeholder="Message" name="message"
                                                                onChange={e => setMassage(e.target.value)} value={massage}
                                                            />
                                                            {errors.massage && <div className='text-danger'>{errors.massage}</div>}
                                                        </div>

                                                        <div className="col-lg-12 ">
                                                            <button type="submit" className=" p-2 SendButton "  ><b className='' style={{ fontWeight: "400", fontSize: "15px", padding: "8px 20px" }}>Submit</b></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='mt-3'>
                                <iframe className='w-100 m-0' title="gmap" style={{ height: "450px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.1593402526278!2d77.28961701492109!3d28.5349299824564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce6abdc000001%3A0x4f80386a6a213f36!2sDigital%20Gramin%20Seva!5e0!3m2!1sen!2sin!4v1617765635920!5m2!1sen!2sin">
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Contact