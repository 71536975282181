import React from 'react'
import Silder from './Silder'

const MiniAtm = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards7.png")} alt="" /></span>
                                <span className='Main_Title'>Mini ATM</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <h3> Turn your shop into a Mini ATM </h3>
                                <p>
                                Micro ATM is a mini version of an ATM. Micro ATMs are like modified point of sales terminals this terminal can hook up with banking network via Bluetooth to perform banking transactions. This machine contains card swipe facility.
                                </p>
                                <p>
                                Micro ATMs can help you to increase your business by adding one more stream of revenue in your business. With every transaction, you can easily gain an attractive commission.
                                </p>
                                <p>
                                Micro ATMs can help you to increase your business by adding one more stream of revenue in your business. With every transaction, you can easily gain an attractive commission.
                                </p>
                                <p>
                                Digital Gramin Seva's micro ATM price is affordable with promising returns and it brings more customers to your shop who want to avail facilities like Cash Withdrawal, Balance Enquiry and Mini Statement. this may provide a lift to your business.
                                </p>

                                <h2> Micro ATMs can perform following transaction </h2>
                                <ul>
                                    <li> Cash Withdrawal </li>
                                    <li> Balance Enquiry </li>
                                    <li> Mini Statement </li>
                                </ul>

                                <h2> Benefits </h2>
                                <ul>
                                    <li> Instant Settlement </li>
                                    <li> Instant Settlement </li>
                                    <li> No Monthly Charges </li>
                                </ul>

                                <h2> How can you get a mini atm ? </h2>
                                <h6> It is a very easy and simple way to get Mini ATM. </h6>
                                <ul>
                                    <li> First you need to connect with Digital Gramin Seva </li>
                                    <li> To join you have to click on the registration button above. </li>
                                    <li> After registration is complete, you will get Retailer ID password </li>
                                    <li> After getting the login ID, you have to login on Digital Gramin Seva Portal. After login, you have to click on the Mini ATM option and apply for Mini ATM. </li>
                                </ul>

                                <h2> How to open a Demat and Trading Account? </h2>
                                <ul>
                                    <li> Choose Trading Account Platform </li>
                                    <li> Fill up your all information in the form </li>
                                    <li> Upload your photo & documents and e-sign your application via Aadhaar OTP </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default MiniAtm