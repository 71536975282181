import React from 'react'

const MicroATM = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/microatm.png")} alt="" />
                                <p>
                                    इस Article में आप जानेंगे Micro-ATM क्या है, और इससे होने वाले फायदे , इस Micro-ATM को कैसे आप ले सकते हैं, इस Micro-ATM को लेकर कैसे आप अपनी शॉप मिनी बैंक में बदल कर अच्छा कमिशन कमा सकते हैं
                                </p>

                                <h2>
                                    What is Micro ATM ? Micro-ATM क्या है
                                </h2>
                                <p>
                                    Micro-ATM मतलब एक छोटा एटीएम मशीन जिससे आप पैसा निकाशी कर सकते हैं लगभग बैंक एटीएम मशीन जैसी सारी सुविधाएं उपलब्ध होती है
                                </p>
                                <p>
                                    आपको अगर पैसा निकाशी करना होता है तो आपको अपने बैंक शाखा या एटीएम जाने की जरुरत होती है ,
                                </p>
                                <p>
                                    बैंक जाना हो या एटीएम आपके घर से ये दोनों चीज़े दूर होती है साथ ही एक कतार में खड़े होकर इंतज़ार करना होता है,
                                </p>
                                <p>
                                    माइक्रो एटीएम की मदत से आप अपने नज़दीकी दुकानदार के वहां से पैसा निकाशी और बहुत सी बैंकिंग सुविधाओं का इस्तेमाल कर सकते हैं
                                </p>
                                <p>
                                    माइक्रो-एटीएम से पैसा निकाशी कैसा होता है ?
                                </p>
                                Micro-ATM से पैसे निकालने के लिए आपको अपने कार्ड को स्वाइप करना होता है, जिस दुकानदार के पास <br />
                                आप गए हैं उस दूकानदार(retailer) के फ़ोन से वो माइक्रो एटीएम कनेक्ट होता है ब्लूटूथ के जरिये , <br />
                                जब आप अपना कार्ड स्वाइप करेंगे तो माइक्रो एटीएम आपसे आपका पिन मांगता है <br />
                                पिन डालने के बाद वो दुकानदार अपने कनेक्टेड फ़ोन में Withdrawal Amount डालता है , <br />
                                फिर वो Amount आपके बैंक से कट होकर उस दूकानदार के खाते में चला जाता है और वो दुकानदार आपको अपने पास से वो Cash दे देता है जो आपने withdraw किया है जैसा की आप जान चुके है माइक्रो एटीएम क्या होता है और ये कैसे काम करता है अगर आप चाहते हैं Micro-ATM सुविधा देना और कमाना चाहते हैं अच्छा ख़ासा कमीशन तो Digital Gramin Seva से

                                <h2>
                                    Micro-ATM की सुविधाएँ ?
                                </h2>
                                <p>
                                    Money Withdrawal (पैसा निकाशी ) <br />
                                    Balance Check/Enquiry <br />
                                    Mini Statement
                                </p>

                                <h2>
                                    Micro-ATM के लाभ
                                </h2>
                                <p>
                                    बदले नार्मल दूकान मिनी बैंक में <br />
                                    इस्तेमाल करना बहुत ही आसान <br />
                                    Retailer को मिले अच्छा कमीशन <br />
                                    ग्रामीण क्षेत्रो के निवासियों को मिले पैसा निकाशी की सुविधा
                                </p>

                                <h2>
                                    कैसे लें Micro-ATM ?
                                </h2>
                                <p>
                                    Micro-ATM लेने के लिए आपको सबसे पहले Digital Gramin Seva पोर्टल पर रजिस्ट्रेशन करना होगा रजिस्ट्रेशन करने के बाद आपको Retailer ID प्राप्त होगी जिससे आप बहुत सारी डिजिटल सुविधाएं प्रदान कर सकते है
                                </p>
                                <p>
                                    रजिस्ट्रेशन कैसे करें जानने के लिए इस लिंक पर Click करे
                                </p>
                                <p>
                                    रजिस्ट्रेशन कम्पलीट होने के बाद आपको Digital Gramin Seva का पोर्टल लॉगिन करना है ओर Micro-ATM के ऑप्शन पे क्लिक करके आपको माइक्रो एटीएम के लिए Apply कर देना है
                                </p>
                                <p>
                                    Apply करने के बाद आपको Digital Gramin Seva का एक अधिकारी आपको कॉल करेगा ओर आगे की प्रोसेस कम्पलीट करवाने में मदत करेगा
                                </p>

                                <b>
                                    ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                </b>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default MicroATM