import React from 'react'
import Silder from './Silder'

const BankAccountOpening = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundImage: `url(${require("../Img/FooterBG.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }} >
                    <div className="container Main_Container">
                        <div className="row p-lg-2">
                            <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/service-cards5.png")} alt="" /></span>
                                <span className='Main_Title'>Bank Account Opening</span>
                            </div>
                            <div className='Main_Title_Bottom'>
                                <p>
                                    Now a days every one need a bank account for the savings fund transfer and online fund transfer and for business activates. Bank account opening is not a easy task because if you want to open a bank account you need to take leave 1-2 days for open bank account because for the bank account opening you need to go bank with the necessary documents and face there a long queue for bank account opening and do lot of formalities for this process, some this process take 2-3 days.
                                </p>
                                <p>
                                    But now there is no need to face these problems because Digital Gramin Seva provides you online bank account opening facility. Now there is no need to go bank for bank account opening you visit Digital Gramin Seva portal and open your bank account anywhere any time online and bank will send your bank passbook and ATM card at your home address. We provide you Canara Bank, Axis Bank, Indus Bank, Kotak Mahindra Bank, ICICI Bank, Bank of Baroda, RBL Bank, IDFC Bank, Punjab National Bank, Bank account opening.
                                </p>
                                <p>
                                    This all of the famous bank in private sector banks and provide you lot of facilities in banking sector. If you want to open bank account in then there is no need to go in bank branch physically now you can open online account with the Digital Gramin Seva. We provide you the online account All account you need to Aadhaar card Pan Card mobile number for Kyc.
                                </p>

                                <h2> Saving Account Opening Facilities </h2>
                                <p>
                                    Average Monthly Balance of Rs. 1000 To 10000/- for Metro, Urban & Semi-urban branches and Rs. 500 to 5000/- for Rural branches.
                                </p>
                                <p>
                                    ATM-cum-Debit Card, Pass, Nomination, Cheque Collection, Internet & Mobile Banking etc.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Silder />

        </>
    )
}

export default BankAccountOpening