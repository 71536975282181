import React from 'react'
import Carousel from "react-multi-carousel";
import "./Silder.css"
import "react-multi-carousel/lib/styles.css";

const Silder = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1428 },
            items: 6,
        },
        desktop: {
            breakpoint: { max: 1428, min: 997 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 997, min: 767 },
            items: 3,
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
        },

    };

    return (
        <>

            <div className="container-fluid p-0">
                <div className="container"  >
                    <div className="row  d-flex justify-content-center align-items-center text-center">
                        <div className='text-center'>
                            <h2 className='Open-Sans-SerifBOLD Title_Silder'  > Our Partners  </h2>
                            <div className='d-flex justify-content-center align-items-center ' >
                                <div className='' style={{ borderTop: "3px solid orange", width: "3%" }}>
                                </div>
                            </div>
                        </div>
                        <Carousel className='p-0' responsive={responsive}
                            autoPlay={true}
                            autoPlaySpeed={2000}>



                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid mx-auto d-block"
                                        src={require("../Img/silderBottom2.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom12.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom11.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom8.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom3.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid mx-auto d-block"
                                        src={require("../Img/silderBottom5.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom9.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid mx-auto d-block"
                                        src={require("../Img/silderBottom4.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom1.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom6.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom7.png")}
                                        alt=""
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='main_size'>
                                    <img
                                        className="ImgSize img-fluid  text-center"
                                        src={require("../Img/silderBottom10.png")}
                                        alt=""
                                    />
                                </div>
                            </div>


                        </Carousel>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Silder