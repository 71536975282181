import React from 'react'
import Silder from './Silder'

const DrivingLicence = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container-lg Main_Container">
                        <div className="row">
                        <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'> Driving Licence </span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2 className="mt-0">
                                        You can apply Driving Licence of citizens near you through
                                        Digital Gramin Seva
                                    </h2>
                                    <p>
                                        Very simply, a driver's license , or DL for brief , is an
                                        official document, in the form of a card, which is issued by
                                        your respective government . It signifies that the licence
                                        holder is permitted to drive a automobile on public roads in
                                        India without the necessity for any supervision. motor
                                        vehicles during this respect include a car, bike, bus, etc.
                                    </p>
                                    <p>
                                        The licence is a legal requirement under the automobiles Act
                                        of 1988. consistent with the Act, nobody can drive during a
                                        public place without a driver's license issued by either the
                                        Regional Transport Authority (RTA) or the Regional Transport
                                        Office (RTO).
                                    </p>
                                    <p>
                                        The process of acquiring a licence is straightforward . you
                                        initially acquire a learner’s licence by passing preliminary
                                        tests supported your knowledge of road safety and traffic
                                        rules. Then you begin off by practicing driving a vehicle.
                                        However, people with a learner’s licence will need to drive
                                        a vehicle only within the presence of another individual
                                        with a permanent driver's license.
                                    </p>
                                    <p>
                                        This learner’s licence is sort of a provisional licence.
                                        How? It allows you to drive on the roads in order that
                                        you'll have sufficient practice and may then qualify for the
                                        ultimate test to urge a final driver's license . it's now
                                        possible to use for a licence online.
                                    </p>
                                    <p>
                                        To apply for a person's Driving Licence, you must first
                                        become a retailer in Digital Gramin Seva
                                    </p>

                                    <p>
                                        <strong>
                                            Apply for Driving Licence via online form submission:
                                        </strong>
                                    </p>
                                    <ul className="bc-service-list ml-2">
                                        <li>Login to the Digital Gramin Seva</li>
                                        <li>Click on Apply for Driving Licence link</li>
                                        <li>
                                            Fill in the required details in the form and submit{" "}
                                        </li>
                                        <li>
                                            After submit, our executive will call you about Driving
                                            Licence.
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default DrivingLicence