import React from 'react'
import Silder from './Silder'

const LicBillPayment = () => {
    return (
        <>

            <section className="Bc-services">
                <div className="container-fluid">
                    <div className="container Main_Container">
                        <div className="row">
                        <div className='d-flex align-items-center'>
                                <span><img className='Comman_Img ' src={require("../Img/LastSectionservice-cards3.png")} alt="" /></span>
                                <span className='Main_Title'>LIC Bill Payment</span>
                            </div>
                            <article className="article-content">
                                <div className="row">
                                    <h2 className="mt-0">Life Insurance Corporation (LIC)</h2>
                                    <p>
                                        Life Insurance Corporation (LIC), Established in 1956 is one
                                        of India’s oldest, reliable, prominent and a trusted
                                        insurance company. LIC has been providing insurance to
                                        millions of Indians. Currently, LIC has expanded its
                                        products and to cater to the insurance and financial needs
                                        of its customers.
                                    </p>
                                    <p>
                                        With Digital Gramin Seva, you can pay your insurance bill,
                                        by phone using our smart application. All you need to do is
                                        fill up the data fields such as, policy number, payment plan
                                        account number, etc. Through our application you can also
                                        view your due bills, check the status of a claim, etc.
                                        Everything regarding insurance bill payment can be accessed
                                        through your mobile device.
                                    </p>
                                    <p>
                                        Paying for insurance was a bit of tricky task before, as you
                                        had to go to the center to pay the bill in person.
                                    </p>
                                    <p>
                                        But now with us, you can pay paperless bills without any
                                        difficulty and that too from the comfort of your home. If
                                        you have any query or do not know the procedure, you can
                                        always contact us. To pay your bill on time, you can even
                                        set reminders or select a particular payment plan in order
                                        to save some money.
                                    </p>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>

            <Silder/>

        </>
    )
}

export default LicBillPayment