import React from 'react'

const PanCard = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/pancard-banner.png")} alt="" />
                                <p>
                                    इस आर्टिकल में आप जानेंगे कैसे आप अपना खुद का ग्राहक सेवा केंद्र खोल कर पैन कार्ड की सुविधा अपने आस पास के लोगो को प्रदान कर सकते, पैन कार्ड क्या चीज़ है और PAN Card Kya Kaam Aata Hai इसके बारे में बहुत से लोग, विशेष कर जो पिछड़े लोग है, ज़्यादा नहीं जानते, उनको इस आर्टिकल में सब समझ में आ जायेगा।
                                </p>
                                <p>
                                    हर देश में रहने वाले हर व्यक्ति के पास उसका पहचान पत्र होना ज़रूरी है, जिससे वो सरकार को उस देश का नागरिक होने का प्रमाण दे सके। हर नागरिक के पास होना PAN कार्ड होना चाहिए।
                                </p>

                                <h2>
                                    What is Pan Card ? Pan Card क्या है
                                </h2>
                                <p>
                                    PAN Card का पूरा नाम Permanent Account Number होता है. इसे किसी भी तरह का financial transaction में बहुत जरुरी माना जाता है.
                                </p>
                                <p>
                                    PAN Card में 10 digit का alphanumeric number मौजूद रहता है जो income tax department से मिलता है. जो किसी भी व्यक्ति/कंपनी के लिए, भारतीय आयकर विभाग (Income Tax Department) द्वारा जारी किया जाता है। जिसे income tax department Central Board for Direct Taxes(CBDT) की देख रेख में जारी करता है.
                                </p>
                                <p>
                                    किसी भी व्यक्ति या कंपनी की संबंधी सभी जानकारी उसके पैन नंबर पर दर्ज होती है। एक बार बनाया गया पैन कार्ड, पूरी ज़िंदगी के लिए मान्य होता है।
                                </p>

                                <h2>
                                    PAN Card क्यों जरुरी है?
                                </h2>
                                <p>
                                    PAN Card के unique number की मदद से income tax department एक व्यक्ति के द्वारा किये गए सभी transactions को link करता है और उनपर नजर रखता है ताकि tax की चोरी को रोका जा सकता है <br />
                                    आज कल सभी बैंकों में खाता खोलने के लिए PAN Card की आवश्यकता होती है. <br />
                                    PAN Card में photo, नाम और signature होता है इसलिए इसे पहचान पत्र के रूप में इस्तेमाल किया जा सकता है. <br />
                                    इसका प्रमुख उपयोग tax भरने के लिए होता है
                                </p>

                                <h2>
                                    PAN Card कैसे बनाये?
                                </h2>
                                <p>
                                    Apply करना बहुत ही आसान है इसे आप दो तरीके से बनवा सकते हैं पहले या तो आप खुद ही income tax department के website incometaxindia.gov.in या tin-nsdl.com या utiitsl.com में जाकर PAN Card बनाने के लिए form भर सकते हैं.
                                </p>

                                <p>
                                    और दूसरा आप चाहे तो आपके सहर में मौजूद सेवा केंद्र पर जा सकते हैं जहाँ PAN Card बनाये जाते हैं।
                                </p>
                                <p>
                                    PAN Card apply करने के बाद आपको एक नम्बर दिया जाता है जिससे आप पता कर सकते हैं की आपका PAN Card बनने के प्रक्रिया का Status क्या है और वो आपके पास कितने दिनों में पहुँच जायेगा ।
                                </p>

                                <h2>
                                    PAN Card बनाने के लिए जरुरी दस्तावेज
                                </h2>
                                <p>
                                    दो पासपोर्ट साइज फोटो <br />
                                    जन्म का प्रमाण. <br />
                                    Identity proof. <br />
                                    Address proof
                                </p>
                                <p>
                                    जैसा की आप जान चुके हैं पैन कार्ड क्या होता है, पैन कार्ड क्यों जरुरी है
                                </p>
                                <p>
                                    अगर आप पैन कार्ड की सेवा प्रदान करना चाहते हैं तो आप हमारे पोर्टल Digital Gramin Seva से जुड़कर पैन कार्ड सेवा और बहुत सी डिजिटल सेवाएं अपने ग्रामीण क्षेत्रो में प्रदान कर सकते हैं।
                                </p>

                                <p>
                                    <b>
                                        ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                    </b>
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default PanCard