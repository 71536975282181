import React from 'react'

const Csp = () => {
    return (
        <>

            <section>
                <div className="container-fluid p-0" style={{ backgroundImage: `url(${require("../../Img/Home2bg.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center' }}>
                    <div className="container Main-Container">
                        <div className='row '>
                            <div className='blog-comman '>
                                <h5>
                                    Feb 22, 2021 by IMOC Digital Services Private Limited
                                </h5>
                                <img className='w-100 mt-2 mb-3' src={require("../../Img/csp-banner.png")} alt="" />
                                <p>
                                    अगर आप बेरोज़गार हैं और पैसे कमाने के रास्ते ढूंढ रहे हैं तो आप CSP (कस्टमर सर्विस पॉइंट ) यानी Mini Bank खोलकर पैसा कमा सकते हैं । इसमें आप ज्यादा शिक्षित हो जरूरी नहीं, बस आपको Computer का Knowledge होना चाहिए।
                                </p>
                                <p>
                                    इस आर्टिकल में जानेंगे आप CSP Kya Hai। इसमें CSP की प्रक्रिया, लाभ, योग्यता और आने वाले खर्च जैसी कई महत्वपूर्ण बातों के बारे
                                </p>

                                <h2>
                                    What is CSP? CSP क्या है
                                </h2>
                                <p>
                                    CSP का फुल फॉर्म – Customer Service Point (ग्राहक सेवा केंद्र) है।
                                </p>
                                <p>
                                    CSP का मतलब है ग्राहक सेवा केंद्र (Customer Service Point). ये केंद्र Mini Bank होते हैं। जहाँ पर लगभग सभी Banking सुविधाएँ उपलब्ध करवाई जाती हैं। मतलब जो सुविधाएँ Bank में मिलती हैं वे ग्राहक सेवा केंद्र/Customer Service Point (CSP) के माध्यम से भी आसानी से मिल जाती है।
                                </p>
                                <p>
                                    हमारे भारत देश के कई गाँवों में Banking सुविधाएँ उपलब्ध नहीं हैं जिस कारण ग्रामीणों को कई किलोमीटर दूर तक शहर जाना पड़ता है। इसलिए ग्रामीणों की सुविधा के लिए Bank दूर-दराज के क्षेत्रों में Mini Bank या ग्राहक सेवा केंद्र (CSP) खुलवाकर उन्हें Banking की सारी सुविधाएँ उपलब्ध करवाई जाती हैं।
                                </p>
                                <p>
                                    ग्राहक सेवा केंद्र के माध्यम से आप लेनदेन, खाता खोलना जैसी सुविधाएँ प्रदान कर सकते हैं और इसकी सुविधा ले सकते हैं
                                </p>

                                <h2>
                                    CSP में मिलने वाली सुविधाएं – Facility Available In CSP
                                </h2>
                                <p>
                                    Saving Bank अकाउंट खोलना, <br />
                                    बकाया राशी की जांच (Balance Inquiry), <br />
                                    RD और FD अकाउंट खोलना, <br />
                                    पैसे Deposit (जमा) व Withdrawal (निकासी) की सुविधा, <br />
                                    ATM कार्ड जारी करना, <br />
                                    बैंक खाते में आधार और पैन कार्ड जोड़ने की सुविधा, <br />
                                    एक Bank से दूसरे Bank में पैसे भेजने की सुविधा, <br />
                                    आधार इनेबल्ड पेमेंट सर्विस (AEPS) की सुविधा, <br />
                                    ओवरड्राफ्ट सर्विस की सुविधा, <br />
                                    लोन लेने में सहायता, <br />
                                    किसान क्रेडिट कार्ड (KCC) देने की सुविधा, <br />
                                    अटल पेंशन योजना की सुविधा, <br />
                                    प्रधानमंत्री जीवन ज्योति बीमा योजना या सुरक्षा बीमा योजना की सुविधा,।
                                </p>

                                <h2>
                                    ग्राहक सेवा केंद्र खोलने के लिए योग्यता – Eligibility For Opening CSP (Mini Bank)
                                </h2>
                                <p>
                                    CSP लेने के लिए भी आपमें कुछ योग्यताएं होनी चाहिए।
                                </p>
                                <p>
                                    आवेदक की उम्र 18 वर्ष से कम नहीं होनी चाहिए । <br />
                                    आवेदनकर्ता के पास कंप्यूटर सर्टिफिकेट होना चाहिए। <br />
                                    आवेदन करने वाला व्यक्ति उसी क्षेत्र का निवासी होना चाहिए जहाँ वो ग्राहक सेवा केंद्र खोलना चाहता है।
                                </p>

                                <h2>
                                    CSP खोलने के लिए क्या क्या होना चाहिए
                                </h2>
                                <p>
                                    आवेदक के पास कम से कम अपनी या किराये की 100 वर्गफुट का एक कमरा या दुकान होनी चाहिए। <br />
                                    बिजली की सुविधा। <br />
                                    ग्राहकों को बैठने की सुविधा।
                                </p>

                                <h2>
                                    ग्राहक सेवा केंद्र खोलने के लिए आवश्यक दस्तावेज/ Required Document for open CSP
                                </h2>
                                <p>
                                    आवेदक का 10 रंगीन पासपोर्ट साइज फोटो( 10 Passport size colour recent फोटो)। <br />
                                    आवेदक की शैक्षणिक योग्यता 10वीं या उससे अधिक होनी चाहिए। <br />
                                    पैन कार्ड (आवश्यक)/ PAN card (mandatory)। <br />
                                    आधार कार्ड (आवश्यक)/Aadhaar Card (mandatory)। <br />
                                    वोटर आईडी कार्ड / Voter ID Card। <br />
                                    ड्राइविंग लाइसेंस/ Driving licence। <br />
                                    जहाँ पर ग्राहक सेवा केंद्र खोलना चाहता है उसका शॉप एग्रीमेंट पेपर / Shop agreement paper <br />
                                    चरित्र प्रमाण पत्र/पुलिस सत्यापन पत्र/ Police verification certificate/ character certificate
                                </p>

                                <h2>
                                    (CSP) प्रदाता से आपको क्या मिलता है?
                                </h2>
                                <p>
                                    एक कंप्यूटर जिसमे 4 GB Ram और 512 GB की Hard Disk होती है <br />
                                    प्रिंटर/स्कैनर Printer/Scanner <br />
                                    Bank software, Portal ID, KO ID ,Branch code / बैंक सॉफ्टवेयर, पोर्टल आईडी, KO कोड। <br />
                                    Biometric reader and software / बायोमेट्रिक रीडर और सॉफ्टवेयर <br />
                                    Authorised certificate for the bank correspondent / एक मान्यता प्राप्त सर्टिफिकेट BC के लिए । <br />
                                    Bank banner, logo, stickers, templates, contact matrix/ ग्राहक सेवा केंद्र की सुंदरता को बढ़ाने के लिए और इसकी सजावट के लिए आपको बैनर,स्टीकर , आदि जैसे सामान दिए जाते हैं । <br />
                                    Training for CSP to operate the system , ग्राहक सेवा केंद्र को चलाने के लिए आप को ट्रेनिंग दी जाती है । <br />
                                    किसी प्रकार की तकनीकी समस्या आने पर आपको इसका सपोर्ट भी कंपनी के द्वारा दिया जाता है ।
                                </p>

                                <h2>
                                    ग्राहक सेवा केंद्र (CSP) कैसे खोलें?
                                </h2>
                                <p>
                                    ग्राहक सेवा केंद्र (CSP) आप डिजिटल ग्रामीण सेवा द्वारा खोल सकते हैं। CSP के लिए आवेदन करने के लिए आपको सबसे पहले digitalgraminseva.in पर रजिस्ट्रेशन करना होगा,
                                </p>
                                <p>
                                    रजिस्ट्रेशन करने के बाद डिजिटल ग्रामीण सेवा पोर्टल के जरिये आप आसानी से CSP(ग्राहक सेवा केंद्र) खोल सकते हैं
                                </p>

                                <p>
                                    <b>
                                        ओर भी किसी तरह की जानकरी के लिए आप हमें संपर्क कर सकते हैं
                                    </b>
                                </p>

                                <p className='mt-3'>
                                    <b>
                                        Call: 8447744774, 8383928391
                                    </b>
                                </p>

                                <p>
                                    <b>
                                        Email: Support@digitalgraminseva.in
                                    </b>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Csp